import { combineReducers } from "redux";
import adminDeliveryV2 from "./adminDeliveryV2";
import bookmarks from "./bookmarks";
import canceledOrders from "./canceledOrders";
import cart from "./cart";
import carts from "./carts";
import checkouts from "./checkouts";
import config from "./config";
import couponCodes from "./couponCodes";
import credit from "./credit";
import cuisines from "./cuisines";
import currentUser from "./currentUser";
import dayOrders from "./dayOrders";
import deliveryCity from "./deliveryCity";
import deliveryZoneByCity from "./deliveryZoneByCity";
import deliveryZones from "./deliveryZones";
import dishes from "./dishes";
import drivers from "./drivers";
import favorites from "./favorites";
import giftCard from "./giftCard";
import giftCardTemplates from "./giftCardTemplates";
import highlight from "./highlight";
import holiday from "./holiday";
import isLunch from "./isLunch";
import { mealMenu } from "./mealMenu";
import meals from "./meals";
import menus from "./menus";
import mileage from "./mileage";
import nomination from "./nomination";
import orderHistoryByRestaurant from "./orderHistoryByRestaurant";
import orders from "./orders";
import pickUpRestaurants from "./pickUpRestaurants";
import pixel from "./pixel";
import posts from "./posts";
import referralCode from "./referralCode";
import restaurantDetails from "./restaurantDetails";
import restaurants from "./restaurants";
import route from "./route";
import routeList from "./routeList";
import routeV2 from "./routeV2";
import start from "./start";
import stats from "./stats";
import statsType from "./statsType";
import supportLunchEarlyDelivery from "./supportLunchEarlyDelivery";
import tasks from "./tasks";
import topBlocks from "./topBlocks";
import topRestaurants from "./topRestaurants";
import tracking from "./tracking";
import transientMessage from "./transientMessage";
import viewByWeek from "./viewByWeek";
import week from "./week";
import weeklyMenu from "./weeklyMenu";

// The keys in redux state are defined in the
// following object-shorthand as input to combineReducers
const rootReducer = combineReducers({
  adminDeliveryV2,
  bookmarks,
  canceledOrders,
  cart,
  carts,
  checkouts,
  config,
  couponCodes,
  credit,
  cuisines,
  currentUser,
  dayOrders,
  deliveryZoneByCity,
  deliveryZones,
  deliveryCity,
  dishes,
  drivers,
  favorites,
  giftCard,
  giftCardTemplates,
  highlight,
  holiday,
  isLunch,
  mealMenu,
  meals,
  menus,
  mileage,
  nomination,
  orderHistoryByRestaurant,
  orders,
  pickUpRestaurants,
  pixel,
  posts,
  referralCode,
  restaurantDetails,
  restaurants,
  route,
  routeList,
  routeV2,
  start,
  stats,
  statsType,
  supportLunchEarlyDelivery,
  tasks,
  topBlocks,
  topRestaurants,
  tracking,
  transientMessage,
  viewByWeek,
  week,
  weeklyMenu,
});

export default rootReducer;
