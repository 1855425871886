import { LOAD_TOP_RESTAURANTS, RESET_TOP_RESTAURANTS } from "../actionTypes";

const topRestaurants = (state = [], action) => {
  switch (action.type) {
    case LOAD_TOP_RESTAURANTS:
      return [...action.topRestaurants];
    case RESET_TOP_RESTAURANTS:
      return [];
    default:
      return state;
  }
};

export default topRestaurants;
