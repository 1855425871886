import React, { ReactElement } from "react";
import { faThumbsUp as faTrendy } from "@fortawesome/pro-light-svg-icons";
import { faMinus, faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce } from "lodash";
import { DishType } from "src/api/dish";
import { TrackAdsEvent } from "src/api/tracker";
import { BREAK_POINTS } from "src/constants";
import { useDishCountInCart } from "src/hooks/useDishCountInCart";
import { Dish } from "src/models";
import LoadingCircle from "src/screens/shared/LoadingCircle";
import { getDishCountInCart } from "src/util/cart";
import styled from "styled-components";
import { GetMemberShip, Auth } from "./DishV2";
import { Prices } from "./Prices";
import { LOGGING, DedupClickMiliSeconds } from "../..";
import {
  Text12300,
  text12300,
  TextTitle,
  Centered,
  actionBlack,
  Beige,
  ActionBlack,
  centered,
  TextUPPER,
  textUpper,
  Gold,
} from "../../Shared";

interface BestSellerProps {
  cart?: any; // See MealMenuScreen for comments
  dish: Dish;
  index: number;
  isMember: boolean;
  isAuthenticated: boolean;
  restaurantIsMemberOnly: boolean;
  onOpenDishDetails: (e: React.MouseEvent<HTMLInputElement>) => void;
  onAddDishToCart: () => void;
  onRemoveDishFromCart: () => void;
  onShowAuth: () => void;
  setShowDetails?: () => void;
  isSaving?: boolean;
  menuLink?: string;
  source?: string;
  fbclid?: string;
}

const IMAGE_SIZE = 120;
const IMAGE_WIDTH = 127;
const IMAGE_HEIGHT = 70;
const TEXT_CONTAINER_MIN_WIDTH = 120;

const MOBILE_DISH_CONTAINER_MARGIN = 6;

const DESKTOP_DISH_CONTAINER_MARGIN = 8;
const DESKTOP_DISH_CONTAINER_MIN_WIDTH =
  TEXT_CONTAINER_MIN_WIDTH + IMAGE_SIZE + DESKTOP_DISH_CONTAINER_MARGIN * 2;

const WHITE_HOVER_COLOR = "#dbdbdb";

type ContainerProps = { isMobile: boolean };

const BestSellerContainer = styled.button`
  ${centered}
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 190px;
  width: ${IMAGE_WIDTH}px;
  padding: ${IMAGE_HEIGHT + 10}px 7px 40px 7px;
  margin: 10px 0px 10px 20px;
  background-color: ${Beige};
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0px 4px 2px rgba(52, 41, 41, 0.2);
  flex-shrink: 0;
  /* pointer-events: ${(props) => (props.theme.disabled ? "none" : "auto")}; */
`;

const BestSellerReadOnlyContainer = styled(Centered)`
  height: 170px;
  width: ${IMAGE_WIDTH}px;
  padding: 10px;
  margin: 10px 16px 10px 0px;
  background-color: ${Beige};
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0px 4px 2px rgba(0, 0, 0, 0.2);
  flex-shrink: 0;
`;
const DishName = styled(TextUPPER)`
  /* margin: 5px; */
  /* position: absolute;
  top: 80px;
  left: 0px; */
  /* height: 40px; */
  display: block;
  font-size: 9px;
  line-height: 1.5;
  /* height: 20px; */
  align-items: flex-start;
  text-align: left;
  /* padding: 5px 10px; */
  /* height: 20px; */
  overflow: hidden;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 5px;
  @supports (-webkit-line-clamp: 2) {
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
const DishPrice = styled(Text12300)`
  text-align: right;
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 15px;
  line-height: 1.4;
  font-weight: ${(props) => (props.theme === "selected" ? 500 : 400)}; ;
`;

type DishImageProps = { backgroundImageUrl: string };

const DishImage = styled.div<DishImageProps>`
  background-image: url(${(props) => props.backgroundImageUrl});
  background-size: cover;
  background-position: center;
  height: ${IMAGE_HEIGHT}px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: ${IMAGE_WIDTH}px;
`;

const DishRank = styled(TextTitle)`
  z-index: 1;
  background: ${Gold};
  /* color: white; */
  font-size: 11px;
  width: 24px;
  line-height: 24px;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: -10px;
  text-align: center;
`;
const BuyButton = styled.button`
  /* position: absolute;
  top: 118px;
  right: 5px; */
  /* padding: 0px 5px; */
  ${centered}
  text-align: center;
  font-size: 7px;
  border-radius: 50%;
  font-weight: 300;
  width: 17px;
  height: 17px;
  line-height: 17px;
  background-color: white;
  line-height: 15px;
  text-align: center;
  /* box-shadow: 0 0px 4px 2px rgba(0, 0, 0, 0.2); */
  border: 1px solid rgba(217, 217, 217, 1);
  margin: 0px 5px;
  box-sizing: border-box;
`;
const CartButtonContainer = styled(Centered)`
  position: absolute;
  bottom: 8px;
  right: 10px;
  button:last-child {
    margin-right: 0px;
  }
`;

const ModifyItemCountContainer = styled(Centered)``;
const DishCountInCart = styled(Centered)`
  background-color: green;
  color: white;
  border-radius: 50%;
  font-weight: 400;
  font-size: 10px;
  border: 1px solid rgba(217, 217, 217, 1);
  width: 18px;
  height: 18px;
  box-sizing: border-box;
`;
const AddToCartButton = styled(Centered)`
  ${actionBlack}
  font-size: 7px;
  height: 17px;
  padding: 0px 10px;
`;
const CountInCart = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  left: 32.5px;
  background-color: rgba(0, 128, 0, 0.8);
  color: white;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0 0 8px 2px rgba(28, 29, 33, 0.3);
  span {
    display: inline-block;
    text-align: center;
  }
  span.count {
    font-family: "Theano Didot", serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 1;
    letter-spacing: 0.6px;
    margin-bottom: 2px;
  }
  span.in-cart,
  span.already {
    font-family: Montserrat;
    font-size: 8px;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: 0.6px;
  }

  @media (min-width: ${BREAK_POINTS.ml}px) {
    top: 5px;
  }
`;

export const BestSeller: React.FC<BestSellerProps> = ({
  cart,
  dish,
  index,
  onOpenDishDetails,
  onAddDishToCart,
  onRemoveDishFromCart,
  isMember,
  isAuthenticated,
  onShowAuth,
  restaurantIsMemberOnly,
  setShowDetails,
  isSaving,
  menuLink,
  source,
  fbclid,
}: BestSellerProps): ReactElement => {
  const hookDishCountInCart = useDishCountInCart(dish._id);
  const dishCountInCart =
    cart != null ? getDishCountInCart(dish._id, cart) : hookDishCountInCart;
  const { priceFoodieListed, priceFoodieNonMember, priceDoordashListed } = dish;
  const memberOnly =
    // restaurantIsMemberOnly ||
    priceFoodieListed && !priceDoordashListed;
  // LOGGING && console.log('DishV2 rendering with dish:', dish);
  const debouncedRemove = debounce(onRemoveDishFromCart, DedupClickMiliSeconds);
  const debouncedAdd = debounce(onAddDishToCart, DedupClickMiliSeconds);
  return (
    <BestSellerContainer
      key={index}
      // @ts-ignore
      onClick={
        memberOnly && !isMember
          ? isAuthenticated
            ? (e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowDetails();
              }
            : onShowAuth
          : onOpenDishDetails
      }
    >
      {/* <DishSold theme={{ range: dish.orderedRange }}>{`${dish.orderedRange} sold`}</DishSold> */}
      <DishImage backgroundImageUrl={dish.imageURL} />
      {dishCountInCart > 0 ? (
        <CountInCart>
          <span className="count">{dishCountInCart}</span>
          <span className="already">already</span>
          <span className="in-cart">in cart</span>
        </CountInCart>
      ) : null}
      <DishRank>#{index + 1}</DishRank>
      <DishName>{dish.name}</DishName>
      <Prices
        priceFoodieListed={priceFoodieListed}
        priceFoodieNonMember={priceFoodieNonMember}
        priceDoordashListed={priceDoordashListed}
        isMember={isMember}
      />
      {memberOnly && !isMember ? (
        <GetMemberShip
          to="/membership"
          onClick={() => {
            // Insert Facebook Pixel tracking here
            // @ts-ignore
            if (window.fbq && fbclid) {
              LOGGING && console.log("membership click is tracked by fbq");
              // @ts-ignore
              window.fbq("track", "ViewContent", {
                content_name: `${menuLink} to membership`,
                content_category: "pageSingleMeal",
                value: 0,
                currency: "USD",
              });

              // Track Ads Event
              TrackAdsEvent(fbclid, {
                source: source,
                eventCode: "ViewContent",
                page: "pageSingleMeal",
                contentName: `${menuLink} to membership`,
                orderId: cart?._id,
              });
            }
          }}
        >
          Join
        </GetMemberShip>
      ) : (
        <CartButtonContainer>
          {dishCountInCart > 0 ? (
            (dish.selections ?? []).length === 0 &&
            dish.dishType !== DishType.GROUP ? (
              <ModifyItemCountContainer>
                <BuyButton
                  // disabled={isSaving}
                  onClick={(e) => {
                    e.stopPropagation();
                    debouncedRemove();
                  }}
                >
                  <FontAwesomeIcon
                    icon={dishCountInCart === 1 ? faTrash : faMinus}
                  />
                </BuyButton>
                {/* {isSaving ? (
                  <LoadingCircle size={18} margin={"0px"} />
                ) : ( */}
                <DishCountInCart>{dishCountInCart}</DishCountInCart>
                {/* )} */}
                <BuyButton
                  // disabled={isSaving}
                  onClick={(e) => {
                    e.stopPropagation();
                    debouncedAdd();
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </BuyButton>
              </ModifyItemCountContainer>
            ) : (
              <AddToCartButton>add</AddToCartButton>
            )
          ) : (dish.selections ?? []).length === 0 &&
            dish.dishType !== DishType.GROUP ? (
            <BuyButton
              onClick={(e) => {
                e.stopPropagation();
                onAddDishToCart();
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </BuyButton>
          ) : (
            <AddToCartButton>add</AddToCartButton>
          )}
        </CartButtonContainer>
      )}
    </BestSellerContainer>
  );
};

interface BestSellerReadOnlyProps {
  dish: Dish;
  index: number;
}

export const BestSellerReadOnly: React.FC<BestSellerReadOnlyProps> = ({
  dish,
  index,
}: BestSellerReadOnlyProps): ReactElement => {
  return (
    <BestSellerReadOnlyContainer key={index}>
      <DishImage backgroundImageUrl={dish.imageURL} />
      <DishRank>#{index + 1}</DishRank>
      <DishName>{dish.name}</DishName>
      <DishPrice>
        {dish.priceFoodieListed ? `$${dish.priceFoodieListed}` : "Variable"}
      </DishPrice>
    </BestSellerReadOnlyContainer>
  );
};
