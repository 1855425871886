import * as Components from ".";

const KoiCupertino = ({ onClick }) => {
  const link = "/meal/01-28-2025/dinner/koi-palace-contempo-cupertino";
  const linkLunch = "/meal/01-28-2025/lunch/koi-palace-contempo-cupertino";
  const image =
    "https://images.squarespace-cdn.com/content/v1/6371e838ebd0567316712e65/50b71cab-a535-4868-8da9-b98a3a4db8ee/2019+Palette+Dishes_058.jpg";
  return (
    <Components.MenuContainer>
      <Components.MealImage src={image} />
      <Components.MealText>
        <Components.RestaurantName>
          Koi Palace Contempo 尚品鲤鱼门
        </Components.RestaurantName>
        <Components.MenuName>
          金蛇新春特别菜品<br></br>Lunar New Year Only
        </Components.MenuName>
        <Components.Notes>
          <Components.DeliveryTime>
            <Components.Bullet></Components.Bullet>
            Order by&nbsp;<b>Sunday Jan 26</b>.
          </Components.DeliveryTime>
          <Components.Instructions>
            <Components.Bullet></Components.Bullet>
            All sales are final.
          </Components.Instructions>
        </Components.Notes>
        <Components.AlaCarteList>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>贺年小菜单品</Components.DishName>
            </Components.AlaCarteName>
            <Components.DishDescription>
              金陵乳猪件($48),
              发菜莲藕汤($20)，蟹肉鱼肚羹($24)，八宝瑶柱羹($28),
              彩凤花胶雪燕羹($38)，发菜蚝豉猪利($38),
              发菜猪手($28)，上汤焗龙虾($58)，姜葱大肉蟹($58)，什菌爆海参($32)，
              贵妃黄毛鸡($22)，
              北京片皮鸭($32)，清蒸大龙利($25)，北菇东坡肉($25)，鲜椒大虾($38)，原只鲍鱼配花菇($160)，瑶柱蛋白海鲜炒饭($22)，
              鲍汁三丝伊面($28)
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>聚宝盆菜</Components.DishName>
            </Components.AlaCarteName>
            <Components.DishDescription>
              五福临门(5人份) $288, 十全十美(10人份) $468,
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                Family Meal A (5-6) 阖家贺岁宴
              </Components.DishName>
              <Components.AlaCartePrice>$388</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              三福烧味拼 (Three Flavors Combination Platter) 八宝瑶柱羹 (Eight
              Treasures Scallop Soup) 发菜蚝豉猪利 (Oyster and Pig Tongue w/ Fa
              Cai) 姜葱大肉蟹 (Stir-fried Cucumber w/ Mushrooms) 粟米粒虾球炒饭
              (Corn and Shrimp Fried Rice) 红豆沙汤圆 (Sweet Red Bean Paste w/
              Rice Balls)
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                Family Meal B (6-8) 吉庆满堂宴
              </Components.DishName>
              <Components.AlaCartePrice>$688</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              五福大拼盘 (Five Flavors Combination Platter) 金沙大虾球 (Salted
              Egg Yolk Prawn and Pumpkin) 发菜蚝豉猪利 (Oyster and Pig Tongue w/
              Fa Cai) 凤凰海味羹 (Shredded Chicken, Abalone, Fish Maw, Dried
              Scallop Soup) 什菌爆海参 (Stir-fried Sea Cucumber w/ Assorted
              Mushrooms) 尚品鸳鸯鸡 (Soy Sauce Chicken & Poached Chicken)
              上汤焗龙虾2只 (Stir-fried Lobster in Supreme Sauce) 清蒸海上鲜
              (Steamed Whole Fish) 鲍汁三丝伊面 (Braised Yu-Noodles) 红豆沙汤圆
              (Sweet Red Paste with Rice Balls)
            </Components.DishDescription>
          </Components.AlaCarteItem>
          {/* <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                Family Meal C (10) 金蛇贺岁宴
              </Components.DishName>
              <Components.AlaCartePrice>$888</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              鸿运金猪大拼盘 (Suckling Pig Platter) 百花蒸酿仙掌 (Duck Feet
              Studded w/ Shrimp) 发菜蚝豉猪利 (Oyster and Pig Tongue w/ Fa Cai)
              凤凰海味羹 (Shredded Chicken, Abalone, Fish Maw, Dried Scallop
              Soup) 鲍鱼配花菇 (Whole Abalone w/ Mushrooms) 尚品鸳鸯鸡 (Soy
              Sauce Chicken & Poached Chicken) 上汤焗龙虾 (Stir-fried Lobster in
              Supreme Sauce) 清蒸大龙利 (Steamed Flounder) 瑶柱蛋白海鲜炒饭
              (Dried Scallop and Seafood w/ Egg White Fried Rice) 美点双晖
              (Double Brilliance)
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                Family Meal D (10) 富贵吉祥宴
              </Components.DishName>
              <Components.AlaCartePrice>$988</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              金陵乳猪件 (Suckling Pig w/ Jellyfish) 酥脆炸虾丸 (Golden Fried
              Shrimp Balls) 发菜蚝豉猪利 (Oyster and Pig Tongue w/ Fa Cai)
              彩凤花胶雪燕羹 (Shredded Chicken & Fish Maw, Bird's Nest Soup)
              鲍鱼扣鹅掌 (Whole Abalone w/ Goose Feet) 芝士牛油焗龙虾
              (Stir-fried Lobster w/ Cheese and Butter) 北京片皮鸭 (Apple Wood
              Smoked Peking Duck) 骨香龙利球 (Stir-fried Flounder Fish Fillet
              over Fried Fish Bones) 瑶柱蛋白海鲜炒饭 (Dried Scallop and Seafood
              w/ Egg White Fried Rice) 美点双晖 (Double Brilliance)
            </Components.DishDescription>
          </Components.AlaCarteItem> */}
        </Components.AlaCarteList>
        <Components.PreOrderButtonWithNotesContainer>
          {/* <span>Check meal details below</span> */}
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(linkLunch);
            }}
            disabled
          >
            Sold Out<br></br>除夕午餐
          </Components.PreOrderButton>
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(link);
            }}
            disabled
          >
            Sold Out<br></br>除夕晚餐
          </Components.PreOrderButton>
        </Components.PreOrderButtonWithNotesContainer>
      </Components.MealText>
    </Components.MenuContainer>
  );
};

export default KoiCupertino;
