// import all components
// and export so that they can be imported using index file

import exp from "constants";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  ActionBlack,
  actionBlack,
  ALLCAPTEXT,
  BackgroundDarkGreen,
  Beige,
  BorderGrey,
  card,
  Centered,
  CenteredByColumn,
  CenteredByColumnLeft,
  Text12300,
  text14300,
  TextGrey,
  TextTitle,
} from "../Shared";

export { default as Beijing } from "./Beijing";
export { default as Company43 } from "./43";
export { default as HLPeninsula } from "./HLPeninsula";
export { default as HunanHouse } from "./HunanHouse";
export { default as HongKongRestaurant } from "./HongKongRestaurant";
export { default as KeeWah } from "./KeeWah";
export { default as KoiCupertino } from "./KoiCupertino";
export { default as SweetHome } from "./SweetHome";

export const MenuContainer = styled(CenteredByColumn)`
  ${card}
  flex-grow: 1;
  align-self: stretch;
  /* padding: 30px; */
  position: relative;
  margin-bottom: 30px;
  max-width: 400px;
  border: 1px solid ${BorderGrey};
  @media screen and (min-width: 700px) {
    width: 350px;
    justify-content: flex-start;
    margin: 10px;
  }
`;
export const RestaurantName = styled(Text12300)`
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-family: "Kai", serif;
`;
export const PreOrderButton = styled(ActionBlack)`
  margin-top: 30px;
  height: 60px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  line-height: 1.8;
  font-family: "Kai", serif;
  &:disabled {
    background-color: #444;
  }
  @media screen and (min-width: 700px) {
    position: absolute;
    bottom: 0px;
  }
`;

export const PreOrderButtonWithNotesContainer = styled(CenteredByColumn)`
  padding-top: 20px;
  width: 100%;
  > span {
    text-transform: capitalize;
  }
  > button {
    margin-top: 8px;
    position: relative;
  }
`;

export const PreOrderButtonWithNotes = styled(PreOrderButton)`
  margin-bottom: 10px;
`;
export const MenuName = styled.div`
  ${ALLCAPTEXT}
  text-align: center;
  margin-bottom: 20px;
  font-family: "Kai", serif;
`;

export const MealImage = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
`;
export const ImageOverlay = styled(CenteredByColumn)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  padding: 10px;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
`;

export const MealText = styled(CenteredByColumn)`
  padding: 18px;
  width: 100%;
  @media screen and (min-width: 700px) {
    /* padding-bottom: 100px; */
  }
`;

export const AlaCarteList = styled(CenteredByColumn)`
  align-self: stretch;
  flex-grow: 1;
  margin-top: 20px;
  background-color: ${Beige};
  padding: 12px;
  @media screen and (min-width: 700px) {
    flex-grow: 1;
    align-self: stretch;
  }
`;

export const AlaCarteItem = styled(CenteredByColumnLeft)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  align-self: stretch;
  align-items: flex-start;
  flex-grow: 1;
`;

export const PackageItem = styled(Centered)`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
  align-self: stretch;
  flex-grow: 1;
  line-height: 1;
  div {
    margin-bottom: 2px;
  }
`;

export const AlaCarteName = styled(Centered)`
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  margin-bottom: 5px;
`;

export const DishName = styled(Text12300)`
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  text-transform: capitalize;
  line-height: 1.4;
  font-family: "Kai", serif;
`;
export const DishDescription = styled(Text12300)`
  line-height: 1.4;
  font-weight: 400;
  color: ${TextGrey};
  margin: 3px 0px;
  font-family: "Kai", serif;
  b {
    color: black;
    font-weight: 500;
    font-size: 12px;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 5px;
  }
`;
export const AlaCartePrice = styled(Text12300)`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4;
`;

export const Warning = styled(Text12300)`
  font-size: 14px;
  font-weight: 400;
  /* font-weight: 600; */
  /* color: red; */
`;

export const Notes = styled(CenteredByColumnLeft)`
  align-self: stretch;
  b {
    font-weight: 600;
    display: inline;
    white-space: nowrap;
  }
  svg {
    margin-right: 5px;
  }
`;
export const DeliveryTime = styled(Centered)`
  ${text14300}
  font-weight: 400;
  justify-content: flex-start;
  /* align-items: flex-start; */
`;

export const Instructions = styled(Text12300)`
  font-size: 14px;
  font-weight: 400;
`;
const bulletWidth = 5;
export const Bullet = styled.span`
  margin-right: 7px;
  margin-bottom: 1px;
  background-color: black;
  width: ${bulletWidth}px;
  height: ${bulletWidth}px;
  border-radius: 50%;
  /* flex: 0; */
  /* align-self: stretch; */
  display: inline-block; /* Ensures proper sizing */
  vertical-align: middle;
`;

export const PackageTitle = styled(TextTitle)`
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 12px;
  margin-top: 5px;
  text-align: left;
  /* text-decoration: underline; */
  border-bottom: 1px solid black;
`;

export const MenuType = styled(Text12300)`
  margin-bottom: 10px;
  text-decoration: underline;
`;

export const Subnote = styled(Text12300)`
  padding-left: ${bulletWidth * 2 + 7}px;
`;
