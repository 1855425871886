import React, { useState } from "react";
import { faStar as faStarSolid } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useStore } from "react-redux";
import styled from "styled-components";
import { Centered } from "../Shared";

const StarContainer = styled(Centered)`
  height: 30px;
`;

const Star = styled.span<{ filled: boolean }>`
  font-size: 18px;
  margin-right: 8px;
  cursor: pointer;
  color: ${({ filled }) => (filled ? "#FFD700" : "#BDBDBD")};
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${({ filled }) => (filled ? "#FFD700" : "#BDBDBD")};
  }
`;

const RatingStars = ({ restaurant, onStarRating }) => {
  // const [rating, setRating] = useState(restaurant.rating || 0);
  // const dispatch = useDispatch();
  // const store = useStore();

  // const handleStarClick = async (newRating: number) => {
  //   setRating(newRating); // Update local rating state immediately
  //   console.log("saving rating to db", newRating, restaurant._id);
  //   // try {
  //   //   await rateGood(orderId, goodId, newRating, restaurantName, dishName)(
  //   //     dispatch,
  //   //     store.getState
  //   //   );
  //   // } catch (error) {
  //   //   console.error("Failed to update rating:", error);
  //   //   throw error;
  //   // }
  // };

  const rating = restaurant.starRating || 0;
  return (
    <StarContainer>
      {Array.from({ length: 5 }, (_, i) => (
        <Star
          key={i + 1}
          filled={i + 1 <= rating}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onStarRating(restaurant?.restaurantId || restaurant?._id, i + 1);
          }}
        >
          <FontAwesomeIcon icon={i + 1 <= rating ? faStarSolid : faStar} />
        </Star>
      ))}
    </StarContainer>
  );
};

export default RatingStars;
