import React from "react";
import { faChevronLeft, faHome } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { centered } from "../tasting_menu/components/Shared";
const BackButton = styled.button`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 50px;
  height: 80px;
  ${centered}
  border: none;
  cursor: pointer;
  margin: 0px;
  svg {
    font-size: 24px;
  }

  @media (min-width: 800px) {
    left: calc(50vw - 380px);
    top: 20px;
  }
`;
const BackOrHome = () => {
  const history = useHistory();
  const hasHistory = history.length > 2; // Check if there is browser history

  const handleClick = () => {
    if (hasHistory) {
      history.goBack();
    } else {
      history.push("/");
    }
  };

  return (
    <BackButton onClick={handleClick}>
      <FontAwesomeIcon icon={hasHistory ? faChevronLeft : faHome} />
    </BackButton>
  );
};

export default BackOrHome;
