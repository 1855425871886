import {
  faCheckCircle as faHasNotTried,
  faBookmark as faNotWantToTry,
} from "@fortawesome/pro-light-svg-icons";
import {
  faCheckCircle as faHasTried,
  faBookmark as faWantsToTry,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { MechanicGreen } from "../Shared";

export const UserAction = styled.button`
  width: 30px;
  height: 30px;
  margin: 0px;
  margin-left: 10px;
  svg {
    font-size: 18px;
  }
`;
export const Tried = ({ restaurant, onHasTried }) => {
  const hasTried = restaurant?.hasTried;
  return (
    <UserAction
      onClick={(e) => {
        e.preventDefault();
        onHasTried(restaurant?.restaurantId || restaurant?._id, !hasTried);
      }}
    >
      <FontAwesomeIcon
        color={hasTried ? "green" : "#aaa"}
        icon={hasTried ? faHasTried : faHasNotTried}
      />
    </UserAction>
  );
};

export const WantToTry = ({ restaurant, onWantToTry }) => {
  const wantsToTry = restaurant?.wantsToTry;
  return (
    <UserAction
      onClick={async (e) => {
        e.preventDefault();
        onWantToTry(restaurant?.restaurantId || restaurant?._id, !wantsToTry);
      }}
    >
      <FontAwesomeIcon
        color={wantsToTry ? "black" : "#aaa"}
        icon={wantsToTry ? faWantsToTry : faNotWantToTry}
      />
    </UserAction>
  );
};
