import React, { ReactElement, useCallback } from "react";
import {
  faHeadset as faChatSelected,
  faUtensils as faNominateSelected,
  faHome as faHomeSelected,
} from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { faCrown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components";
import { LOGGING } from "../..";
import {
  Centered,
  centeredByColumn,
  text12300,
  Brown,
  Beige,
  CenteredByColumn,
  Text12300,
  TextGrey,
  BorderGrey,
} from "../../Shared";
const Container = styled(CenteredByColumn)`
  align-items: flex-start;
  /* @media only screen and (min-width: 1200px) {
    align-items: flex-start;
    justify-content: flex-end;
  } */
`;
const Price = styled(Centered)`
  ${text12300}
  /* font-size: 11px; */
  line-height: 1.4;
  text-align: left;
  font-weight: 600;
  color: ${TextGrey};
  /* margin-bottom: 5px; */
  svg {
    margin-left: 4px;
    margin-bottom: 2px;
    font-size: 12px;
    color: black;
  }
  /* @media only screen and (min-width: 1200px) {
    margin: 0px;
  } */
`;
const PriceNumber = styled.span`
  /* width: 43px; */
  color: ${TextGrey};
`;
const PriceTag = styled.span`
  margin-left: 5px;
  color: ${TextGrey};
`;
const MemberOnly = styled(Price)`
  /* border: 1px solid #ccc; */
  padding: 3px 5px;
  margin-bottom: 3px;
  font-size: 10px;
  border-radius: 5px;
  background-color: ${(props) => (props?.theme?.isMember ? "#bf9b30" : "#bbb")};
  color: black;
  color: ${BorderGrey} svg {
    margin: 0px 5px 2px 0;
    font-size: 8px;
  }
  svg {
    margin-left: 0px;
    margin-right: 4px;
  }
`;
type PricesProps = {
  priceFoodieListed: number;
  priceDoordashListed: number;
  priceFoodieNonMember: number;
  isMember: boolean;
  isItem?: boolean;
};

export const Prices: React.FC<PricesProps> = ({
  priceFoodieListed,
  priceDoordashListed,
  priceFoodieNonMember,
  isMember,
  isItem,
}): ReactElement => {
  const priceRegular = priceDoordashListed || priceFoodieNonMember || 0;
  const priceMember = priceFoodieListed || 0;
  // LOGGING &&
  //   console.log("Prices rendering with:", {
  //     priceFoodieListed,
  //     priceDoordashListed,
  //     priceFoodieNonMember,
  //     isMember,
  //     isItem,
  //   });
  return (
    <Container>
      {!isItem && !priceRegular && !priceMember ? (
        <Price>Price Variable</Price>
      ) : (
        <>
          {priceRegular ? (
            <Price theme={isMember ? "" : "selected"}>
              <PriceNumber>{`$${(
                priceDoordashListed ||
                priceFoodieNonMember ||
                0
              ).toFixed(2)}`}</PriceNumber>
              <PriceTag>Regular</PriceTag>
              {isMember ? null : <FontAwesomeIcon icon={faCheck} />}
            </Price>
          ) : isItem ? null : (
            <MemberOnly theme={{ isMember }}>
              <FontAwesomeIcon icon={faCrown} />
              <span>Member Only</span>
            </MemberOnly>
          )}
          <Price theme={isMember ? "selected" : ""}>
            <PriceNumber>{`$${(priceFoodieListed || 0).toFixed(
              2
            )}`}</PriceNumber>
            <PriceTag>Member</PriceTag>
            {isMember ? <FontAwesomeIcon icon={faCheck} /> : null}
          </Price>
        </>
      )}
    </Container>
  );
};
