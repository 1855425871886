import { faStar, faFire, faGem } from "@fortawesome/free-solid-svg-icons";
import { faImages } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  textTitle,
  text12300,
  BorderGrey,
  Centered,
  CenteredByColumn,
  CenteredByColumnLeft,
  centeredByColumn,
  BackgroundGrey,
  ALLCAPTEXT,
} from "src/screens/tasting_menu/components/Shared";
import styled, { keyframes } from "styled-components";
import { IndexWidget } from "./ImageCarousel";
import NextDelivery, { LastDelivery } from "./NextDelivery";
import RatingStars from "./RatingStars";
import RestaurantComment from "./RestaurantComment";
import { WantToTry, Tried } from "../favorites/WantToTry";
export const RestaurantCity = styled(Centered)`
  span {
    ${text12300}
    text-transform: capitalize;
    line-height: 20px;
    position: relative;
    margin-right: 15px;
    svg {
      font-size: 10px;
      position: relative;
      top: -1px;
      color: #444;
    }
  }
  span::after {
    content: "";
    position: absolute;
    right: -9px;
    top: 8px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    display: block;
    background-color: #444;
  }
  span:last-child::after {
    display: none;
  }
`;

const RestaurantContainer = styled(CenteredByColumn)<{ noShadow?: boolean }>`
  /* box-shadow: 0 0px 4px 2px rgba(0, 0, 0, 0.15); */
  box-shadow: ${(props) =>
    props.noShadow ? "none" : "0 0px 4px 2px rgba(0, 0, 0, 0.15)"};
  border: 1px solid ${BorderGrey};
  width: 100%;
  max-width: 500px;
  margin: 5px 0px;
  flex-grow: 1;
  align-self: stretch;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
  border-radius: 10px;
  background-color: white;
  @media (min-width: 800px) {
    width: 350px;
    margin: 5px;
  }
`;
const RestaurantCover = styled.img`
  width: 150px;
  height: 120px;
  object-fit: cover;
`;

const RestaurantImageContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 150px;
  height: 120px;
  overflow: hidden;
  border-radius: 10px;
`;
const RestaurantImageOverlay = styled(CenteredByColumn)`
  position: absolute;
  right: 0px;
  top: 0px;
  width: 150px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 40px;
`;

const RestaurantName = styled.span`
  ${textTitle}
  text-align: left;
  line-height: 1.2;
  align-self: stretch;
  margin-bottom: 5px;
`;
const RestaurantNamePlaceholder = styled(RestaurantName)`
  display: inline-block;
  width: 100%;
  height: 20px;
  background-color: ${BackgroundGrey};
`;
const RestaurantContent = styled(Link)`
  ${centeredByColumn}
  padding: 10px;
  padding-right: 170px;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 140px;
  box-sizing: border-box;
`;
const RestaurantContentPlaceholder = styled(CenteredByColumn)`
  padding: 15px;
  padding-right: 170px;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 120px;
  box-sizing: border-box;
`;
const RestaurantActions = styled(Centered)`
  /* height: 60px; */
  width: 100%;
  /* border-top: 1px solid ${BorderGrey}; */
  /* justify-content: space-between; */
  justify-content: flex-end;
  padding: 0px 20px 10px 10px;
`;

const BookMarkContainer = styled(Centered)`
  justify-content: flex-end;
`;

const UserFeedback = styled(CenteredByColumnLeft)`
  flex-grow: 1;
  align-self: stretch;
  justify-content: flex-end;
  margin: 0px 0px 10px 0px;
`;

const shimmer = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`;

export const ShimmerEffect = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    ${BackgroundGrey} 25%,
    #e0e0e0 50%,
    ${BackgroundGrey} 75%
  );
  background-size: 400% 100%;
  animation: ${shimmer} 1.5s infinite;
`;
export const awardName = ["Bronze", "Silver", "Gold"];
const AwardContainer = styled(Centered)<{ level }>`
  width: 80px;
  height: 30px;
  border-radius: 5px;
  background-color: ${(props) =>
    props.level === 3
      ? "rgb(157, 131, 78)"
      : props.level === 2
      ? "rgb(140, 140, 140)"
      : "rgb(134, 106, 86)"};
  color: white;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    ${text12300}
    font-weight: 600;
    /* font-size: 10px; */
    text-transform: uppercase;
    letter-spacing: 1px;
  }
`;
const AwardList = styled(Centered)`
  margin-bottom: 8px;
`;
const Special = styled(Centered)`
  /* width: 80px; */
  padding: 0px 10px;
  height: 30px;

  margin-left: 5px;
  border-radius: 5px;
  background-color: black;
  color: white;
  border: 1px solid black;
  span {
    ${text12300}
    font-size: 9px;
    line-height: 1.2;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
  }
  svg {
    font-size: 9px;
    margin-right: 5px;
  }
`;
const specialNames = {
  new: "hot & new",
  gem: "hidden gem",
};
const specialIcons = {
  new: faFire,
  gem: faGem,
};
const SpecialList = styled(CenteredByColumnLeft)``;
const RestaurantFooter = styled(CenteredByColumn)`
  /* background-color: red; */
  /* height: 150px; */
  /* min-height: 150px; */
  /* flex-grow: 1; */
  /* align-self: stretch; */
  justify-content: flex-end;
`;

const ActionContainer = styled(CenteredByColumn)`
  margin-left: 30px;
  button {
    margin-left: 0px;
  }
`;

const ActionNote = styled.span`
  ${text12300}
  text-transform: capitalize;
  letter-spacing: 0.5px;
`;

export const RestaurantAward = ({ award, specials }) => (
  <AwardList>
    <AwardContainer level={award}>
      <span>{awardName[award - 1]}</span>
    </AwardContainer>
    {specials?.length > 0 && (
      <Special>
        <FontAwesomeIcon icon={specialIcons[specials[0]]} />
        <span>{specialNames[specials[0]]}</span>
      </Special>
    )}
  </AwardList>
);

const TimeTableRow = styled(Centered)`
  width: 100%;
  justify-content: flex-end;
  margin: 10px 0px 20px 0px;
  padding: 0px 20px;
`;
const RestaurantThumbnail = ({
  restaurant,
  onWantToTry,
  onHasTried,
  onStarRating,
  onReview,
}) => {
  return (
    <RestaurantContainer>
      <RestaurantContent to={`/guide2025/${restaurant.restaurantId}`}>
        <RestaurantAward
          award={restaurant.awardLevel || 1}
          specials={restaurant.awards}
        />
        <RestaurantName>
          {restaurant.name.split(/[-,]/)[0].trim()}
        </RestaurantName>
        <RestaurantCity>
          <span>{restaurant.awardCategory}</span>
          <span>{restaurant.city}</span>
        </RestaurantCity>
        <RestaurantCity>
          <span>{"$".repeat(restaurant.priceLevel)}</span>
          <span>
            Yelp {restaurant.yelp.rating} <FontAwesomeIcon icon={faStar} /> (
            {restaurant.yelp.reviews.toLocaleString()})
          </span>
        </RestaurantCity>
        <RestaurantImageContainer>
          <RestaurantCover
            src={restaurant.heroImages?.[0] || restaurant.heroImage}
            alt={restaurant.name}
          />
          <RestaurantImageOverlay>
            <IndexWidget isMobile={true} isLarger={false}>
              <FontAwesomeIcon icon={faImages} />
              <span>{restaurant.heroImages.length}</span>
            </IndexWidget>
            <IndexWidget isMobile={false} isLarger={false}>
              <FontAwesomeIcon icon={faImages} />
              <span>{restaurant.heroImages.length}</span>
            </IndexWidget>
          </RestaurantImageOverlay>
        </RestaurantImageContainer>
      </RestaurantContent>
      <RestaurantFooter>
        <RestaurantActions>
          {/* <RatingStars restaurant={restaurant} onStarRating={onStarRating} /> */}

          <BookMarkContainer>
            <ActionContainer>
              <Tried restaurant={restaurant} onHasTried={onHasTried} />
              <ActionNote>tried</ActionNote>
            </ActionContainer>
            <ActionContainer>
              <WantToTry restaurant={restaurant} onWantToTry={onWantToTry} />
              <ActionNote>want to try</ActionNote>
            </ActionContainer>
          </BookMarkContainer>
        </RestaurantActions>
        <TimeTableRow>
          {restaurant.nextMealLink ? (
            <NextDelivery
              link={restaurant.nextMealLink}
              time={restaurant.nextMealWindowStart}
            />
          ) : restaurant.lastMealWindowStart ? (
            <LastDelivery time={restaurant.lastMealWindowStart} />
          ) : null}
        </TimeTableRow>
        {/* <UserFeedback>
          <RestaurantComment
            restaurant={restaurant}
            onReview={onReview}
            customStyle={{
              padding: "0px 10px",
            }}
          />
        </UserFeedback> */}
      </RestaurantFooter>
    </RestaurantContainer>
  );
};

export const RestaurantThumbnailEmpty = () => {
  return (
    <RestaurantContainer noShadow={true}>
      <RestaurantContentPlaceholder>
        <RestaurantNamePlaceholder />
        <RestaurantNamePlaceholder />
        <RestaurantNamePlaceholder />
        <RestaurantImageContainer>
          {/* <RestaurantImageOverlay></RestaurantImageOverlay> */}
          <ShimmerEffect />
        </RestaurantImageContainer>
      </RestaurantContentPlaceholder>
    </RestaurantContainer>
  );
};
export default RestaurantThumbnail;
