import { DispatchProp } from "react-redux";
import { Store } from "redux";
import { LOGGING } from "src/constants";
import { ObjectId } from "src/constants/types";
import { readCurrentMealOrders } from "src/store/actions";
import { apiCall } from "src/store/api";
import { SerializedRestaurant } from "./restaurants";

export const GetStatsForMeal = (mealIds) => {
  return async (_, getState) => {
    const { currentUser } = getState();
    try {
      const mealStats = await apiCall(
        "post",
        `/meals/GetStatsForMeals`,
        {
          mealIds: mealIds,
        },
        currentUser
      );
      return mealStats;
    } catch (err) {
      LOGGING && console.log("GetStatsForMeal got err", err);
    }
  };
};

export const CancelDishForMeal = (
  mealId: string,
  dishId: string,
  mealtime: number // epoch time ms
): ((dispatch: DispatchProp, store: Store) => Promise<void>) => {
  return async (dispatch, store) => {
    try {
      await apiCall("PUT", `/meals/cancelDish/${mealId}`, { dishId });
      await readCurrentMealOrders()(dispatch, store.getState);
    } catch (err) {
      LOGGING && console.log("CancelDishForMeal got err", err);
    }
  };
};

export enum MealStatus {
  Unknown = "Unknown",
  Draft = "Draft",
  NotConfirmed = "NotConfirmed",
  Confirmed = "Confirmed",
  Complete = "Complete",
}
export type MealSerialized = {
  _id: ObjectId;
  confirmedWithRestaurant: boolean;
  dishes: Array<any>; // dishes
  restaurant: SerializedRestaurant;
  preOrderedDishes: Array<any>; //dishes
  windowStart: number; // epoch time ms
  // epoch time ms. The latest time an order can come through
  orderCutoffTime: number;
  // epoch time ms. When the last modifications can be made by the restaurant.
  // e.g cancel items, etc.
  editOrderCutoffTime: number;
  mealStatus: MealStatus;
  stats: MealStats;
};
export type MealStats = {
  numOrders: number;
  netPayout: number;
};
export type GetUpcomingMealsForRestaurantResponse = { meals: MealSerialized[] };
export const GetUpcomingMealsForRestaurant = async (
  restaurantId: ObjectId
): Promise<GetUpcomingMealsForRestaurantResponse> => {
  try {
    return await apiCall("POST", `/meals/GetUpcomingMealsForRestaurant`, {
      restaurantId,
    });
  } catch (err) {
    LOGGING && console.log("GetUpcomingMealsForRestaurant got err", err);
    return { meals: [] };
  }
};

export type GetPastMealsForRestaurantResponse = { meals: MealSerialized[] };
export const GetPastMealsForRestaurant = async (
  restaurantId: string
): Promise<GetPastMealsForRestaurantResponse> => {
  try {
    return await apiCall("POST", "/meals/GetPastMealsForRestaurant", {
      restaurantId,
    });
  } catch (err) {
    LOGGING && console.log("GetPastMealsForRestaurant got err", err);
    return { meals: [] };
  }
};

export type GetMealByIdResponse = { meal: MealSerialized | null };
export const GetMealById = async (
  mealId: string
): Promise<GetMealByIdResponse> => {
  try {
    return await apiCall("POST", `/meals/GetMealById`, { mealId });
  } catch (err) {
    LOGGING && console.log("GetMealById got err", err);
    return { meal: null };
  }
};

export type GetPromoMealsResponse = {
  promo: Object;
  promoMeals: Array<MealSerialized> | null;
};
export const GetPromoMeals = async (
  promoCode: string,
  userId: string
): Promise<GetPromoMealsResponse> => {
  try {
    return await apiCall("POST", `/promos/${promoCode}`, { userId });
  } catch (err) {
    LOGGING && console.log("GetAwardMeals got err", err);
    return { promo: null, promoMeals: [] };
  }
};

type SaveMealForRestaurantPayload = {
  _id: ObjectId; // meal ID
  dishes: string[]; // dish IDs
  restaurant: string; // restaurant ID
};
export const restaurantSaveMealMenu = async (
  mealPayload: SaveMealForRestaurantPayload,
  currentUser: any
): Promise<any> => {
  const payload = {
    ...mealPayload,
    source: "restaurant",
    onlyEditDishes: true,
  };
  return await apiCall("post", `/meals`, payload, currentUser);
};

export type SerializedRestaurantOrder = {
  _id: ObjectId;
  name: string;
  orderHash: string;
  restaurantConfirmTime: number | null;
  goods: SerializedRestaurantGood[];
  window: { start: number };
};
export type SerializedRestaurantGood = {
  _id: ObjectId;
  price: number;
  dish: SerializedRestaurantDish;
  selections: SerializedRestaurantGoodSelection[];
  quantity: number;
  cancelQuantity: number;
  comment: string;
};
export type SerializedRestaurantDish = {
  _id: ObjectId;
  name: string;
  priceFoodieListed: number;
  selections: SerializedRestaurantDishSelection[];
};
export type SerializedRestaurantDishSelection = {
  _id: ObjectId;
  items: SerializedRestaurantDishSelectionItem[];
  maxSelection?: number;
  minSelection?: number;
  name: string;
};
export type SerializedRestaurantDishSelectionItem = {
  _id: ObjectId;
  name: string;
  price: number;
  priceFoodieNonMember: number;
  priceDoordashListed: number;
  isRequired: boolean;
  outOfStock: boolean;
  isDefaultChoice: boolean;
};
type SerializedRestaurantGoodSelection = {
  _id: ObjectId;
  dishSelection: ObjectId;
  selectedItems: SerializedRestaurantGoodSelectedItem[];
};
type SerializedRestaurantGoodSelectedItem = {
  _id: ObjectId;
  item: ObjectId;
  count: number;
  cancelCount: number;
};
export type GetNextUnconfirmedOrderForMealResponse = {
  order: SerializedRestaurantOrder | null;
};
export const GetNextUnconfirmedOrderForMeal = async (
  mealId: string
): Promise<GetNextUnconfirmedOrderForMealResponse> => {
  try {
    return await apiCall("POST", `/meals/GetNextUnconfirmedOrderForMeal`, {
      mealId,
    });
  } catch (err) {
    LOGGING && console.log("GetNextUnconfirmedOrderForMeal got err", err);
    return { order: null };
  }
};

export const RecordMealPayment = async (
  mealId: ObjectId,
  payment: number
): Promise<any> => {
  try {
    return await apiCall("POST", "/meals/RecordMealPayment", {
      mealId: mealId,
      recordedPayment: payment,
    });
  } catch (err) {
    LOGGING && console.log("RecordMealPayment got err", err);
    return null;
  }
};

type StopMapViewAttributes = {
  stopNum: number | null;
  color: string | null;
  label: string;
};

type DriverStopDetails = {
  driverId: ObjectId;
  stopId: ObjectId;
  firstName: string;
  color: string;
  isSelected: boolean;
  stopNumsAssigned: number;
  selectedStopNum: number | null;
  earliestStopNum: number | null;
};

type SerializedStop = {
  _id: ObjectId;
  address: string;
  stopType: string;
  meal: ObjectId;
  isLunch: boolean;
  parent: ObjectId;
  mapLat: number;
  mapLng: number;
  mapViewAttributes: StopMapViewAttributes;
  eligibleDriverDetails: DriverStopDetails;
};
type DriverRouteSummary = {
  driverId: ObjectId;
  driverName: string;
  stops: SerializedStop[];
};
type GetRouteSummaryForMealByDriverResponse = DriverRouteSummary[];
export async function GetRouteSummaryForMealByDriver(
  mealId: ObjectId
): Promise<GetRouteSummaryForMealByDriverResponse> {
  try {
    return await apiCall("POST", `/meals/GetRouteSummaryForMealByDriver`, {
      mealId: mealId,
    });
  } catch (err) {
    LOGGING && console.log("GetRouteSummaryForMealByDriver got err", err);
    return [];
  }
}

export type MealOrderableDetails = {
  mealId: ObjectId;
  isOrderable: boolean;
  timeLeft: number; // ms
  capacityLeft: number | null;
  errorMsgs: string[];
};
export type GetIsMealOrderableResponse = MealOrderableDetails | null;
export async function GetIsMealOrderable(mealId: ObjectId): Promise<any> {
  try {
    return await apiCall("POST", `/meals/GetIsMealOrderable`, {
      mealId: mealId,
    });
  } catch (err) {
    LOGGING && console.log("GetIsMealOrderable go err", err);
    return null;
  }
}
export async function MealVisited(
  mealName: String,
  userName: String,
  mealId?: any,
  userId?: any,
  windowStart?: any,
  source: String = "web"
): Promise<any> {
  LOGGING && console.log("MealVisited called with:", { mealName, userName });
  try {
    return await apiCall("POST", `/meals/MealVisited`, {
      mealName,
      userName,
      mealId,
      userId,
      windowStart,
      source,
    });
  } catch (err) {
    LOGGING && console.log("MealVisited go err", err);
    return null;
  }
}
