import { ReactNode, useEffect } from "react";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BREAK_POINTS } from "src/constants";
import { BorderGrey, card } from "src/screens/tasting_menu/components/Shared";
import styled from "styled-components";
import { Flex } from "../Layout";

export type ModalProps = {
  isOpen: boolean;
  onClose?: () => void;
  transparent?: boolean;
  width?: number;
  title?: string;
  showClose?: boolean;
  children?: ReactNode;
  noPadding?: boolean;
};

export const Modal = (props: ModalProps) => {
  const {
    isOpen,
    width,
    title,
    onClose,
    transparent,
    showClose,
    children,
    noPadding,
  } = props;

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "unset";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <>
          <PopupOverlay $transparent={transparent} />
          <PopupContainer>
            <PopupBody $width={width} $noPadding={noPadding}>
              <Flex justify="space-between" align="center" gap={12}>
                {title && <h3>{title}</h3>}
                {showClose && (
                  <PopupCloseIconWrapper
                    $hasTitle={Boolean(title)}
                    onClick={onClose}
                  >
                    <PopupCloseIcon icon={faTimes} />
                  </PopupCloseIconWrapper>
                )}
              </Flex>
              {children}
            </PopupBody>
          </PopupContainer>
        </>
      )}
    </>
  );
};

const PopupOverlay = styled.div<{ $transparent?: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  z-index: 200;
  margin: 0px;
  padding: 0px;
  ${({ $transparent }) =>
    $transparent ? "" : "background: rgba(0, 0, 0, 0.7);"}
`;

const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 300;
  height: 100vh;
  width: 100vw;
  margin: 0px;
  padding: 0px;
  /* border: 1px solid purple; */
`;

const PopupBody = styled.div<{ $width?: number; $noPadding?: boolean }>`
  position: relative;
  background: #ffffff;
  box-shadow: 0 4px 32px 0 rgb(28 29 33 / 12%);
  max-width: 100vw;
  overflow-y: auto;
  max-height: 100%;

  @media (min-width: ${BREAK_POINTS.md}px) {
    max-height: calc(100% - 20px);
    top: 10px;
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }

  ${({ $width }) => ($width ? `width: ${$width}px;` : "")}
  ${({ $noPadding }) => ($noPadding ? "padding: 0px;" : "padding: 24px;")}
`;

const PopupCloseIcon = styled(FontAwesomeIcon)`
  font-size: 30px;
`;

const PopupCloseIconWrapper = styled.div<{ $hasTitle: boolean }>`
  cursor: pointer;
  /* background: rgba(240, 240, 240, 0.5); */
  ${card}
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: white;
  color: black;
  z-index: 3;
  border: 1px solid black;

  @media (min-width: ${BREAK_POINTS.md}px) {
    right: calc(50% - 230px);
    top: 30px;
  }

  ${({ $hasTitle }) =>
    $hasTitle
      ? ""
      : `
        position: fixed;
        top: 10px;
        right: 10px;
      `}
`;
