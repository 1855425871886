import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MealVisited } from "src/api/meals";
import { RootState } from "src/store";
import styled from "styled-components";
import {
  BackgroundGrey,
  CenteredByColumnLeft,
  text12300,
  Centered,
  ActionBlack,
} from "../Shared";

const Container = styled(CenteredByColumnLeft)`
  position: relative;
  /* align-self: stretch; */
  width: 100%;
  padding: 0px;
  label {
    ${text12300}
    /* padding-left: 10px; */
    text-transform: capitalize;
    margin-bottom: 2px;
  }
  textarea {
    display: flex;
    /* align-self: stretch; */
    width: calc(100% - 20px);
    ${text12300}
    resize: none;
    border-radius: 10px;
    border: none;
    background-color: ${BackgroundGrey};
    padding: 10px;
  }
  @media screen and (min-width: 600px) {
    /* padding: 0px calc(50vw - 300px); */
    align-items: center;
    /* padding-left: calc(50% - 300px);  */
    /* max-width: 600px; */
    textarea {
      width: calc(100% - 20px);
      max-width: 580px;
      flex-grow: 0;
    }
  }
`;
const SaveButtonRow = styled(Centered)`
  width: 100%;
  justify-content: space-between;
  /* height: 50px; */
  min-height: 40px;
  align-items: flex-end;
  @media screen and (min-width: 600px) {
    max-width: 600px;
    position: relative;
  }
`;
const Save = styled(ActionBlack)`
  position: absolute;
  top: 5px;
  right: 10px;
  height: 30px;
  border-radius: 5px;
  font-size: 12px;
`;
export const RestaurantComment = ({
  restaurant,
  onReview,
  customStyle = {},
}) => {
  const [isEdittingComment, setIsEdittingComment] = useState(false);
  const [comment, setComment] = useState(restaurant?.review);
  const [originalComment, setOriginalComment] = useState(restaurant?.review);
  const hasChanged = comment?.trim() !== originalComment?.trim();
  const userName = useSelector(
    (state: RootState) =>
      state.currentUser.user.firstName + " " + state.currentUser.user.lastName
  );
  useEffect(() => {
    setComment(restaurant?.review);
    setOriginalComment(restaurant?.review);
  }, [restaurant?.review]);
  return (
    <Container style={customStyle}>
      <SaveButtonRow>
        <label>Notes</label>
        {isEdittingComment && hasChanged && (
          <Save
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              try {
                onReview(restaurant?.restaurantId || restaurant?._id, comment);
                MealVisited(
                  comment,
                  `${userName} made notes on ${restaurant?.name} `
                );
                setOriginalComment(comment); // Update original comment
              } catch (error) {
                console.error("Error saving comment:", error);
              }
            }}
          >
            Save
          </Save>
        )}
      </SaveButtonRow>
      <textarea
        value={comment}
        // placeholder="write a comment..."
        // disabled={!isEdittingComment}
        onChange={(e) => {
          setComment(e.target.value);
        }}
        onFocus={() => setIsEdittingComment(true)}
        onBlur={() => setTimeout(() => setIsEdittingComment(false), 200)} // Graceful blur handling
      />
    </Container>
  );
};

export default RestaurantComment;
