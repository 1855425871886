import React, { ReactElement, useState } from "react";
import {
  faPlus,
  faTrash,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Button } from "src/components/Button";
import { DishTypeChecker } from "src/components/DishTypeChecker";
import { updateCategory } from "src/store/actions";
import {
  getDishesByCategoryName,
  getSortedCategoryNamesFromDishes,
} from "src/util/categories";
import styled from "styled-components";
import { LOGGING } from "..";
import { RemoveDishFromBestSellers } from "../../../../api/dish";
import {
  Grey,
  Purple,
  ActionGrey,
  Centered,
  Yellow,
  Beige,
  Card,
  card,
  ALLCAPTEXT,
  textOverflow,
  text12300,
  Text12300,
  TextTitle,
  CenteredByColumnLeft,
  CenteredByColumn,
  ActionBlack,
} from "../Shared";
import { actionBlack } from "../Shared";

const DishField = styled(Centered)`
  ${text12300}
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
  flex-wrap: wrap;
  label,
  b {
    font-weight: 500;
    margin-right: 10px;
    text-transform: capitalize;
    line-height: 1.2;
  }
  b {
    display: block;
    width: 100%;
    flex-shrink: 0;
    text-align: left;
    margin-bottom: 5px;
  }
  span {
    ${textOverflow}
    text-transform: capitalize;
    white-space: pre-wrap;
    line-height: 1.2;
    text-align: left;
  }
`;

const DishDetails = styled(Link)`
  width: 100%;
`;
const AddDish = styled(Card)`
  span,
  svg {
    ${text12300}
    line-height: 40px;
  }
  height: 40px;
  padding: 0px 10px;
  text-transform: capitalize;
`;
const BestSellerContainer = styled(CenteredByColumnLeft)``;

const BestSellerTitle = styled(TextTitle)``;
const Categories = styled(CenteredByColumnLeft)`
  border: 1px solid ${Grey};
  padding: 20px;
  margin-bottom: 20px;
`;
const ExistingCategories = styled(Centered)`
  max-width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
  padding: 5px;
`;
export interface CategoryProps {
  invalid: Boolean;
  selected: Boolean;
}
const Category = styled(Centered)<CategoryProps>`
  position: relative;
  /* background-color: ${(props) =>
    props.selected ? `${Purple}` : `${Grey}`}; */
  background-color: ${Grey};
  display: ${(props) => (props.invalid ? "none" : "flex")};
  margin: 10px;
  padding: 10px 20px;
  button,
  span {
    ${text12300}
    font-weight: 500;
  }
  > button {
    margin: 0 20px 0 0;
    padding: 0px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    @extend .card;
    background-color: ${(props) => (props.selected ? `${Purple}` : `white`)};
    color: ${(props) => (props.selected ? `white` : `black`)};
  }
`;
const AddCategory = styled(Centered)`
  justify-content: flex-start;
  margin-top: 20px;
  input {
    margin: 10px 0px;
    padding-left: 5px;
    width: 150px;
  }
  button {
    ${actionBlack}
    margin-left:10px;
  }
`;
const ToggleCategories = styled(ActionBlack)`
  margin: 0px;
  svg {
    margin-left: 10px;
  }
`;
const BestSellers = styled(Centered)`
  flex-wrap: wrap;
  justify-content: flex-start;
`;
const BestSellerText = styled(CenteredByColumn)`
  padding: 10px 10px 20px 10px;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  button {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  span {
    display: inline-block;
  }
`;
const ActionWrapper = styled(Centered)`
  margin-bottom: 20px;
`;

const BestSeller = styled(CenteredByColumn)`
  ${card}
  position: relative;
  margin: 5px;
  width: 200px;
  height: 100px;
  ${text12300}
  padding-left:100px;
  span {
    padding: 0px 5px;
    text-align: center;
    line-height: 1.5;
  }
  img {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 0px;
    top: 0px;
  }
`;

interface RestaurantAdminDishListProps {
  dishes: Array<any>;
  bestSellers: Array<any>;
  categories: Array<any>;
  restaurantId: string;
  heroImageUrl: string;
  heroImages: Array<any>;
  onSetHeroImageUrl: (restaurantId: string, imageUrl: string) => {};
  onSaveNewCategory: (name: string) => {};
  onUpdateCategory: ({}) => {};
  onToggleHeroImages: (restaurantId: string, imageUrl: string) => {};
}

interface PriorityContainerProps {
  selected: boolean;
}
const PriorityContainer = styled.button<PriorityContainerProps>`
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.16) 0px 0px 5px 0px;
  background-color: ${(props) =>
    props.selected === true ? `${Purple}` : "white"};
  color: ${(props) => (props.selected ? "white" : `${Purple}`)};
  border: 1px solid ${Purple};
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  margin: 5px;
  ${text12300};
  padding: 0px;
  font-size: 15px;
  font-weight: 500;
`;
const Priority = ({ selected, index, onClick }) => {
  LOGGING &&
    console.log("Priority rendering with:", { selected, index, onClick });
  return (
    <PriorityContainer selected={selected} onClick={onClick.bind(this, index)}>
      {index}
    </PriorityContainer>
  );
};

const PriorityList = styled(Centered)`
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: 10;
  width: auto;
  height: auto;
`;

const CateoryPriority = ({ currentPriority, length, onClick }) => {
  LOGGING &&
    console.log("CateoryPriority rendering with:", {
      currentPriority,
      length,
      onClick,
    });
  return (
    <PriorityList>
      {Array(length)
        .fill(0)
        .map((a, index) => (
          <Priority
            key={index}
            index={index}
            selected={currentPriority === index}
            onClick={onClick}
          />
        ))}
    </PriorityList>
  );
};

export const RestaurantAdminDishList: React.FC<RestaurantAdminDishListProps> =
  ({
    dishes,
    restaurantId,
    heroImageUrl,
    heroImages,
    bestSellers,
    categories,
    onSetHeroImageUrl,
    onSaveNewCategory,
    onToggleHeroImages,
    onUpdateCategory,
  }): ReactElement => {
    const [newCategory, setNewCategory] = React.useState("");
    const [showCategoryPriority, setShowCategoryPriority] = React.useState(-1);
    const [showCategories, setShowCategories] = React.useState(false);
    const onToggleShowCategoryPriority = (index, e) => {
      e.preventDefault();
      console.log("onToggleShowCategoryPriority called with index:", index);
      setShowCategoryPriority(showCategoryPriority > -1 ? -1 : index);
    };
    const onSetPriority = (categoryId, priority, e) => {
      console.log("onSetPriority called with:", { e, categoryId, priority });
      e.preventDefault();

      // call backend
      onUpdateCategory({ priority, categoryId });
      setShowCategoryPriority(-1);
    };
    const onToggleDetails = (e) => {
      e.preventDefault();
      setShowCategories(!showCategories);
    };
    const onChangeInput = (event) => {
      setNewCategory(event.target.value);
    };

    const categoriesForRestaurant = getSortedCategoryNamesFromDishes(dishes);
    // let initShowCategoryDetails = {};
    // categoriesForRestaurant.forEach((c) => (initShowCategoryDetails[c] = false));
    // const [showCategoryDetails, setShowCategoryDetails] = useState(initShowCategoryDetails);

    const categorizedDishes = getDishesByCategoryName(dishes);
    // const categoriesForRestaurant = categories.map((c) => c.name);
    LOGGING &&
      console.log("RestaurantAdminDishList got:", {
        categoriesForRestaurant,
        dishes,
        newCategory,
        categorizedDishes,
        categories,
        showCategoryPriority,
      });

    return (
      <>
        <ActionWrapper>
          <Link key={-1} to={`/dish/new/restaurant/${restaurantId}`}>
            <AddDish>
              <FontAwesomeIcon icon={faPlus} /> <span>add dish</span>
            </AddDish>
          </Link>
        </ActionWrapper>

        <Categories>
          <ToggleCategories onClick={onToggleDetails}>
            <span>categories</span>
            <FontAwesomeIcon
              icon={showCategories ? faChevronUp : faChevronDown}
            />
          </ToggleCategories>
          {showCategories ? (
            <>
              <ExistingCategories>
                {categories
                  .sort((a, b) => (a.priority > b.priority ? 1 : -1))
                  .map((c, index) => (
                    <Category
                      key={c._id}
                      invalid={c.name === "undefined"}
                      selected={showCategoryPriority === index}
                    >
                      <button
                        onClick={onToggleShowCategoryPriority.bind(this, index)}
                      >
                        {c.priority}
                      </button>
                      <span>{c.name}</span>
                      {showCategoryPriority === index ? (
                        <CateoryPriority
                          currentPriority={c.priority}
                          length={categories.length}
                          onClick={onSetPriority.bind(this, c._id)}
                        />
                      ) : null}
                    </Category>
                  ))}
              </ExistingCategories>
              <AddCategory>
                <input
                  type="text"
                  placeholder="new category name"
                  onChange={onChangeInput}
                  value={newCategory}
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    onSaveNewCategory(newCategory);
                    setNewCategory("");
                  }}
                >
                  add
                </button>
              </AddCategory>
            </>
          ) : null}
        </Categories>

        <BestSellerContainer>
          <BestSellerTitle>best sellers</BestSellerTitle>
          <BestSellers>
            {bestSellers.map((d) => (
              <BestSeller key={d._id}>
                <img className="dish-item-image" src={d.imageURL} alt="dish" />
                <BestSellerText>
                  <span>{d.name}</span>
                  <button onClick={RemoveDishFromBestSellers.bind(this, d._id)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </BestSellerText>
              </BestSeller>
            ))}
          </BestSellers>
        </BestSellerContainer>

        {categoriesForRestaurant.map((category, categoryIndex) => (
          <div className="dish-cateogry" key={categoryIndex}>
            <div className="category-name">
              <span>{category}</span>
            </div>

            {categorizedDishes[category].map((d, index) => (
              <div className="dish-item" key={index}>
                <DishTypeChecker dish={d} />
                <div className="dish-name">{d._id}</div>
                <DishDetails to={`/dish/${d._id}`}>
                  <img
                    className="dish-item-image"
                    src={d.imageURL}
                    alt="dish"
                  />
                  <DishField>
                    <label>name</label>
                    <span>{d.name}</span>
                  </DishField>
                  <DishField>
                    <b>description</b>
                    <span>{d.description}</span>
                  </DishField>
                  <DishField>
                    <label>D</label>
                    <span>{`${
                      d.priceFoodieListed === undefined ||
                      d.priceFoodieListed === 0 ||
                      d.priceFoodieListed === null
                        ? "variable"
                        : `$${d.priceDoordashListed?.toFixed(2)}`
                    }`}</span>
                  </DishField>
                  <DishField>
                    <label>F</label>
                    <span>{`${
                      d.priceFoodieListed === undefined ||
                      d.priceFoodieListed === 0 ||
                      d.priceFoodieListed === null
                        ? "variable"
                        : `$${d.priceFoodieListed?.toFixed(2)}`
                    }`}</span>
                  </DishField>
                  <DishField>
                    <label>R</label>
                    <span>{`${
                      d.priceFoodieListed === undefined ||
                      d.priceFoodieListed === 0 ||
                      d.priceFoodieListed === null
                        ? "variable"
                        : `$${d.priceFoodieRestaurant?.toFixed(2)}`
                    }`}</span>
                  </DishField>
                  <DishField>
                    <label>Selections</label>
                    <span>{`${
                      d.selections.length > 0
                        ? d.selections.map((s) => s.name).join(", ")
                        : "None"
                    }`}</span>
                  </DishField>
                </DishDetails>
                {d.imageURL && (
                  <>
                    {heroImageUrl === d.imageURL ? (
                      <div className="hero-image">Current hero image</div>
                    ) : (
                      <Button
                        className="set-hero-image"
                        size="small"
                        variant="secondary"
                        onClick={() => {
                          onSetHeroImageUrl(restaurantId, d.imageURL);
                        }}
                      >
                        Set hero image1
                      </Button>
                    )}
                  </>
                )}
                {d.imageURL && (
                  <Button
                    className={`toggle-hero-image ${
                      heroImages.includes(d.imageURL) ? "in" : "out"
                    }`}
                    size="small"
                    variant="secondary"
                    onClick={() => {
                      onToggleHeroImages(restaurantId, d.imageURL);
                    }}
                  >
                    {`${
                      heroImages.includes(d.imageURL) ? "remove" : "add"
                    } hero images`}
                  </Button>
                )}
              </div>
            ))}
          </div>
        ))}
      </>
    );
  };
