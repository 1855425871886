import GoogleMapReact from "google-map-react";
import styled from "styled-components";
import { Centered, CenteredByColumn } from "../Shared";

// Styles for the map container
const mapContainerStyle = {
  width: "100%",
  height: "400px", // Adjust height as needed
};
const Container = styled(Centered)`
  width: 100%;
  height: 200px;
  position: relative; // Allow positioning of child elements
  background-color: white;
  overflow: hidden; // Prevent content overflow
`;
const RestaurantMarker = styled(CenteredByColumn)`
  font-size: 30px;
`;
const RestaurantOnMap = ({ map, userMap }: { map: any; userMap: any }) => {
  const lat = map?.lat;
  const lng = map?.lng;
  const userLat = userMap?.lat;
  const userLng = userMap?.lng;
  const centerLat = userLat ? (lat + userLat) / 2 : lat;
  const centerLng = userLng ? (lng + userLng) / 2 : lng;

  const renderMarker = (map: any, maps: any) => {
    new maps.Marker({
      position: { lat, lng },
      map,
      title: "Restaurant Location", // Tooltip text when hovering
    });
  };
  return (
    <Container>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyB-0SwH76HPTJGm_mhM1Rg0ACcpYGpAt1Q",
          sensor: false,
        }}
        defaultZoom={10}
        // disableDefaultUI={true}
        // style={{ text: "none" }}
        center={[centerLat, centerLng]}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => renderMarker(map, maps)}
      >
        {userLat && userLng && (
          <RestaurantMarker
            // @ts-ignore
            lat={userLat}
            lng={userLng}
          >
            🏠
          </RestaurantMarker>
        )}
      </GoogleMapReact>
    </Container>
  );
};

export default RestaurantOnMap;
