import React, { ReactElement, useCallback, useState } from "react";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import {
  faHouse,
  faTruck,
  faChevronRight,
  faChevronLeft,
  faInfoCircle,
  faCheck,
  faChevronSquareRight as faEnter,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { useAsync } from "react-async";
import { useSelector, useDispatch, useStore } from "react-redux";
import { Link } from "react-router-dom";
import { GetReferrerFromCode } from "src/api/referrals";
import { TrackAdsEvent } from "src/api/tracker";
import { CheckoutBanner } from "src/screens/tasting_menu/components";
import { RootState } from "src/store";
import { addGoodsToCart } from "src/store/actions";
import styled, { keyframes } from "styled-components";
import { breakPoint } from "./MyReferralCodeEventMember";
import { LOGGING, PopUp, IconImages, SupportedSanJoseZipCodes } from "..";
import { DishChooserContainer } from "../dish/DishChooserContainer";
import {
  CenteredByColumn,
  Text12300,
  CenteredByColumnLeft,
  BorderGrey,
  Centered,
  Yellow,
  Beige,
  Grey,
  Pink,
  text12300,
  card,
  centeredByColumn,
  NavHeight,
  textTitle,
  SpringYellow,
  actionBlack,
} from "../Shared";
import { RibbonYellow } from "../Shared";

export interface ReferralAwardProps {
  promo: any;
  socialInviteCode: string;
  dishes: any[];
  promoMeals: any[];
  restaurant: string;
}
const Container = styled(Centered)`
  width: 100vw;
  padding-bottom: 10px;
  @media only screen and (min-width: 1200px) {
    justify-content: flex-start;
    padding-left: 10vw;
    /* margin-top: 20px; */
  }
`;
const topHeight = 90;
const Amount = styled.span`
  /* background-color: ${Beige}; */
  /* padding: 30px 40px; */
  /* align-self: stretch;
 ${text12300}
 line-height: 24px;
 text-align: center; */
  /* margin-bottom: 10px; */
  label {
    /* font-size: 40px; */
    font-weight: 600;
    font-size: 45px;
    letter-spacing: 2px;
    /* line-height: 35px; */
    /* margin: 0 6px; */
    /* line-height: 1; */
  }
`;
const SubFooter = styled.span`
  /* margin: 5px 0px; */
  ${textTitle}
  ${text12300}
    font-size: 11px;
  line-height: 1.8;
  z-index: 1;
  text-transform: none;
  text-transform: capitalize;
  b {
    font-weight: 600;
  }
`;
const Footer = styled(CenteredByColumn)`
  margin-top: 10px;
  svg {
    font-size: 5px;
    margin: 0px 5px;
  }
  /* ${text12300}
    font-size: 14px;    
    line-height: 1.1;    
    text-transform: uppercase;     */
`;
const CreditContentContainer = styled(Centered)`
  ${card}
  padding: 15px;
  width: calc(100% - 30px);
  max-width: 500px;
  margin-top: 10px;
  /* margin-bottom: 20px; */
  border: 1px solid ${BorderGrey};
  position: relative;
  @media only screen and (min-width: 500px) {
    margin-bottom: 20px;
    /* width: 360px; */
  }
  @media only screen and (min-width: 1200px) {
    width: 360px;
  }
`;
const Icon = styled(Centered)`
  position: absolute;
  font-size: 60px;
  left: -5px;
  bottom: -20px;
  transform: rotate(-15deg);
`;
const Border = styled(CenteredByColumn)`
  border: 2px solid ${RibbonYellow};
  /* height: ${NavHeight}px;   */

  /* background-color: ${Beige}; */

  /* padding-bottom: ; */
  padding: 20px;
  align-self: stretch;
  flex-grow: 1;
  h1 {
    width: 100%;
    text-align: center;
    ${textTitle}
    /* ${text12300} */
    font-size: 20px;
    line-height: 1;
    font-weight: 400;
    /* border-bottom: 1px solid black; */
    /* line-height: 28px; */
    /* font-weight: 500; */
    /* margin-top: 5px; */
    z-index: 1;
    /* text-transform: uppercase; */
    /* text-transform: none; */
    /* background-color: ${Beige}; */
    margin: 0px;
    margin-bottom: 12px;
  }
  /* h1 {
    ${text12300}
    font-size: 14px;    
    line-height: 1.1;    
    text-transform: uppercase;    
  } */
`;
const SignUpNow = styled(Link)`
  background-color: black;
  ${text12300}
  color: white;
  font-size: 15px;
  margin-top: 12px;
  height: 35px;
  line-height: 35px;
  text-transform: capitalize;
  white-space: normal;
  padding: 5px 20px;
  font-weight: 500;
  text-align: center;
  flex-grow: 1;
  align-self: stretch;
  @media only screen and (max-width: 370px) {
    width: 85px;
  }
  @media only screen and (min-width: 800px) {
    white-space: nowrap;
    /* ${actionBlack} */
    /* margin-left: 15px; */
  }
`;

export interface NewGuestCreditProps {
  source?: string;
  fbclid?: string;
}
export const NewGuestCredit: React.FC<NewGuestCreditProps> = ({
  source,
  fbclid,
}: NewGuestCreditProps): ReactElement => {
  LOGGING && console.log("NewGuestCredit called with: ", { source, fbclid });
  return (
    <Container>
      <CreditContentContainer>
        <Border>
          <h1>welcome credit</h1>
          <Amount>
            <label>$10</label>
          </Amount>
          <Footer>
            <SubFooter>$20 minimum spend</SubFooter>
            {/* <FontAwesomeIcon icon={faCircle} /> */}
            <SubFooter>
              Expires <b>{moment().add(7, "day").format("ddd MMM D, YYYY")}</b>
            </SubFooter>
            <SignUpNow
              to="/auth"
              onClick={() => {
                // Insert Facebook Pixel tracking here
                // @ts-ignore
                if (window.fbq && fbclid) {
                  LOGGING && console.log("sign up now click is tracked by fbq");
                  // @ts-ignore
                  window.fbq("track", "ViewContent", {
                    content_name: `newGuestCredit`,
                    content_category: "pageGuest",
                    value: 0,
                    currency: "USD",
                  });

                  // Track Ads Event
                  TrackAdsEvent(fbclid, {
                    source,
                    eventCode: "ViewContent",
                    page: "pageGuest",
                    contentName: "newGuestCredit",
                  });
                }
              }}
            >
              sign up now
            </SignUpNow>
          </Footer>
        </Border>
        <Icon>💝</Icon>
      </CreditContentContainer>
    </Container>
  );
};
