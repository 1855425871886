import styled from "styled-components";
import { HeroImage } from "..";
import { Kai, LunarNewYearSlogan } from "../../PageSMLinkPreview";
import {
  Overlay,
  Content,
  RestaurantName,
  Title,
  MealLink,
} from "../customers/banners/MealHighlights";
import {
  actionTransparent,
  ALLCAPTEXT,
  Centered,
  centeredByColumn,
  CenteredByColumn,
} from "../Shared";
const HeroHeight = 30;
const heroImages = [
  // "https://43andcompany.myshopify.com/cdn/shop/files/79C7E6E0-8BC3-4441-B342-DD4A494D80D4_1024x1024.jpg?v=1737404415",
  "https://images.squarespace-cdn.com/content/v1/6371e838ebd0567316712e65/50b71cab-a535-4868-8da9-b98a3a4db8ee/2019+Palette+Dishes_058.jpg",
  // "https://43andcompany.myshopify.com/cdn/shop/files/79C7E6E0-8BC3-4441-B342-DD4A494D80D4_1024x1024.jpg?v=1737404415",
  "https://p8.itc.cn/images01/20220107/903bacef9f53486fbc3453e0941b9c0c.jpeg",
  "https://i.pinimg.com/1200x/ec/22/c4/ec22c41a7b7421dfd9755e8cebe37a6b.jpg",
  // "https://b2df3c4e7ac2a0296ac2.cdn6.editmysite.com/uploads/b/b2df3c4e7ac2a0296ac2156d5bf65b599c0d61b5220447349e3c1b4b8ff1879f/23-KW-CNY-Rabbit_Panda_Pudding-all-web_1673479591.jpeg?width=2400&optimize=medium",
];
const SubTitle = styled(RestaurantName)`
  margin-bottom: 10px;
`;
const ContentContainer = styled.a`
  ${centeredByColumn}
  width: 100vw;
  height: ${HeroHeight}vh;
  position: relative;
  .container {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
  }
  @media only screen and (max-height: 600px) {
    height: 40vh;
  }
  @media only screen and (min-width: 1200px) {
    height: ${HeroHeight}vh;
    .container {
      .pic {
        height: 100%;
      }
    }
  }
`;
const TextContainer = styled(Content)`
  padding: 20px 0px;
`;
const TitleText = styled(CenteredByColumn)`
  margin-bottom: 20px;
`;

const Slogan = styled.span`
  ${ALLCAPTEXT}
  font-size: 32px;
  line-height: 1.1;
`;

const Action = styled(Centered)`
  ${actionTransparent}
  border-color: white;
  text-transform: uppercase;
`;
const NewYearMenus = () => {
  return (
    <ContentContainer href={`/holiday`}>
      <Overlay></Overlay>
      {heroImages ? <HeroImage images={heroImages} /> : null}
      <TextContainer>
        <SubTitle>Lunar New Year Special Menus</SubTitle>
        <TitleText>
          <Slogan>happy new year</Slogan>
          <LunarNewYearSlogan />
        </TitleText>

        <Action>Pre-Order Now</Action>
      </TextContainer>
    </ContentContainer>
  );
};
export default NewYearMenus;
