import moment from "moment";
import { Link } from "react-router-dom";
import { TrackAdsEvent } from "src/api/tracker";
import { GetMealType } from "src/constants";
import {
  DeliveryWindowDisplay,
  GetMealTypeFromWindowStart,
} from "src/util/time";
import styled from "styled-components";
import { Centered, text12300 } from "../Shared";

const Container = styled(Centered)`
  label {
    ${text12300}
    line-height: 20px;
    margin-right: 6px;
    border-bottom: 1px solid white;
    /* margin-bottom: 1px; */
  }
  span,
  a {
    ${text12300}
    font-weight: 600;
    line-height: 20px;
    border-bottom: 1px solid #000;
    text-transform: capitalize;
  }
  span {
    /* font-weight: 300; */
    border-bottom: 1px solid white;
    color: #444;
  }
`;
const dateFormat = "dddd, MMM D, YYYY";
const dateFormat2 = "ddd, MMM D";
const NextDelivery = ({ link, time, source = null, fbclid = null }) => {
  const mealType = GetMealType({ windowStart: time });
  const receiptTime = DeliveryWindowDisplay[GetMealTypeFromWindowStart(time)];
  return (
    <Container>
      <label>Next Delivery</label>
      <Link
        to={`/meal/${link}`}
        onClick={() => {
          // Insert Facebook Pixel tracking here
          // @ts-ignore
          if (window.fbq && fbclid) {
            // @ts-ignore
            window.fbq("track", "ViewContent", {
              content_name: `restaurant guide to ${link}`,
              content_category: "pageTopRestaurants",
              value: 0,
              currency: "USD",
            });

            // Track Ads Event
            TrackAdsEvent(fbclid, {
              source: source,
              eventCode: "ViewContent",
              page: "pageTopRestaurants",
              contentName: `restaurant guide to ${link}`,
            });
          }
        }}
      >
        {receiptTime}, {moment(time).format(dateFormat2)}
      </Link>
    </Container>
  );
};

export default NextDelivery;

export const LastDelivery = ({ time }) => {
  const relativeTime = moment(time).fromNow();
  return (
    <Container>
      <label>Last Delivery</label>
      <span>{relativeTime}</span>
    </Container>
  );
};
