import { Component } from "react";
import { connect } from "react-redux";
import { Back, LOGGING } from "./";
import { GetEmailOrGoogle } from "../tasting_menu/components/auth/GetEmailOrGoogle";
import { Welcome } from "../tasting_menu/components/auth/Shared";

class PageAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalErrorMessage: null,
      title: props?.location?.state?.newUserOnly ? "sign up" : "log in/sign up",
    };
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleSetModalError = this.handleSetModalError.bind(this);
    this.handleSetAuthMode = this.handleSetAuthMode.bind(this);
  }
  handleSetAuthMode(title) {
    this.setState({ title });
  }
  handleGoBack(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  handleSetModalError(modalErrorMessage) {
    this.setState({ modalErrorMessage });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    LOGGING &&
      console.log("PageAuth rendering", {
        props: this.props,
        state: this.state,
      });

    const { modalErrorMessage, title } = this.state;
    const { pixel } = this.props;

    return (
      <div className="page">
        <div className="auth-title">
          <Back onClick={this.handleGoBack} leftEnd={false} />
          <span>{title}</span>
          {this.props?.location?.state?.title ? (
            <label>{`${this.props?.location?.state?.title}${
              this.props?.location?.state?.inviteCode ? " (new user only)" : ""
            }`}</label>
          ) : null}
        </div>
        {modalErrorMessage && (
          <div className="error-message auth">{modalErrorMessage}</div>
        )}
        {/* <Welcome /> */}
        <GetEmailOrGoogle
          defaultEmail={this.props?.location?.state?.email}
          allowSignUp={true}
          allowLogin={!this?.props?.location?.state?.inviteCode}
          parentError={modalErrorMessage}
          setParentError={this.handleSetModalError}
          next={this?.props?.location?.state?.next}
          treatPath={this?.props?.location?.state?.treatPath}
          inviteCode={this?.props?.location?.state?.inviteCode}
          inviter={this?.props?.location?.state?.inviter}
          setParentTitle={this.handleSetAuthMode}
          source={pixel?.source}
          fbclid={pixel?.fbclid}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    pixel: state.pixel,
  };
}

export default connect(mapStateToProps, {})(PageAuth);
