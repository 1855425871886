import React from "react";
import { TrackAdsEvent } from "src/api/tracker";
import { LOGGING } from "src/constants";
import { BackgroundBlue, Beige, BorderGrey } from "../../Shared";

const AppStoreLink = ({
  customStyle,
  source,
  fbclid,
}: {
  customStyle?: any;
  source?: any;
  fbclid?: any;
}) => {
  const appStoreUrl = "https://apps.apple.com/app/FoodieEarth/6738896239"; // Replace with your actual App Store URL

  return (
    <a
      href={appStoreUrl}
      target="_blank"
      rel="noopener noreferrer"
      style={{ ...styles.link, ...customStyle }}
      onClick={() => {
        // Insert Facebook Pixel tracking here
        // @ts-ignore
        if (window.fbq && fbclid) {
          LOGGING && console.log("app store link click is tracked by fbq");
          // @ts-ignore
          window.fbq("track", "ViewContent", {
            content_name: `appStoreLink`,
            content_category: "pageGuest",
            value: 0,
            currency: "USD",
          });

          // Track Ads Event
          TrackAdsEvent(fbclid, {
            source,
            eventCode: "ViewContent",
            page: "pageGuest",
            contentName: "appStoreLink",
          });
        }
      }}
    >
      <img
        src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
        alt="Download on the App Store"
        style={styles.image}
      />
    </a>
  );
};

const styles = {
  link: {
    textDecoration: "none",
    display: "flex", // Makes the container a flexbox
    alignItems: "center", // Centers content vertically
    justifyContent: "center", // Centers content horizontally
    // width: "100%", // Ensures the link spans the full width
    padding: "10px",
    // backgroundColor: Beige,
    // borderBottom: `1px solid ${BorderGrey}`,
  },
  image: {
    // width: "auto",
    // width: "100%",
    height: "60px", // Ensures consistent image height
  },
};

export default AppStoreLink;
