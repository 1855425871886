import React, { ReactElement, useCallback, useState, useEffect } from "react";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
import { faPiggyBank } from "@fortawesome/pro-duotone-svg-icons";
import { faTimes, faTimesCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { Close } from "src/screens/shared";
import styled, { keyframes } from "styled-components";
import { Overlay } from "./MealHighlights";
import { AnonymousRestaurantSlides } from "../../home/CoverForPopUp";
import {
  Centered,
  TextTitle,
  text12300,
  CenteredByColumn,
  Gold,
  Pink,
  Brown,
  Yellow,
  Peach,
  Purple,
  Blue,
  BackgroundPink,
  Text12300,
  textTitle,
  CenteredByColumnLeft,
  ALLCAPTEXT,
  ActionBlack,
} from "../../Shared";
export interface Membership30DTrialProps {
  onClick: () => {};
}

const ContentContainer = styled(CenteredByColumn)<{ height: number }>`
  left: max(20px, 50vw - 250px);
  top: 20px; /* 20px from the top */
  width: calc(100vw - 40px);
  max-width: 500px;
  height: auto; /* Automatically adjust height */
  max-height: calc(100vh - 40px); /* Keep a 20px margin at the bottom */
  background-color: white;
  flex-direction: column;
  position: fixed;
  justify-content: flex-start;
  padding-bottom: 20px;
  @media only screen and (max-width: 400px) {
    width: calc(100vw - 40px);
    max-width: 400px;
    left: max(20px, 50vw - 200px);
  }

  @media only screen and (max-width: 350px) {
    width: calc(100vw - 20px);
    max-width: 330px;
    left: max(10px, 50vw - 165px);
    top: 10px;
    height: auto;
    max-height: calc(100vh - 20px); /* Ensure margin at the bottom */
  }
`;

export const SeeDetails = styled(ActionBlack)`
  margin-top: 20px;
`;
const Title = styled(CenteredByColumn)`
  ${text12300}
  font-size: 24px;
  /* margin-top: 20px; */
  line-height: 1.1;
  text-transform: uppercase;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.6px;
  width: 100%;
  margin: 10px 0px;

  span.cross-out {
    text-decoration: line-through;
    color: grey;
  }
  @media only screen and (min-width: 500px) {
    text-align: left;
    font-size: 32px;
    /* width: 100%; */
  }
  @media only screen and (max-height: 600px) {
    margin: 0px;
  }
`;

const Tagline = styled.span`
  ${text12300}
  /* ${textTitle} */
  text-transform: uppercase;
  margin: 8px 0px;
  line-height: 16px;
  font-weight: 500;
  /* text-decoration: underline; */
  /* border-bottom: 1px solid black; */
  color: #333;
  letter-spacing: 0.6px;
  width: 100%;
  text-align: center;
  /* text-transform: uppercase; */
  svg {
    color: ${Brown};
    margin-right: 5px;
    font-size: 20px;
    margin-bottom: 2px;
  }
`;

const Benefits = styled(CenteredByColumnLeft)`
  /* margin-top: 20px; */
  padding: 10px;
`;
const Benefit = styled(Text12300)`
  ${text12300}
  text-transform: capitalize;
  margin-bottom: 5px;
  /* font-size: 14px; */
  line-height: 1.5;
  font-weight: 400;
  position: relative;
  margin-left: 15px;
  &::before {
    content: " ";
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: #333;
    border-radius: 50%;
    left: -15px;
    top: 6px;
  }
  @media only screen and (max-width: 400px) {
    font-size: 12px;
    line-height: 16px;
    margin-left: 0px;
    &::before {
      left: -10px;
    }
  }
  @media only screen and (min-width: 1000px) {
  }
`;
export const SkipButton = styled.button`
  ${text12300}
  font-weight: 500;
  font-size: 13px;
  line-height: 2;
  text-transform: uppercase;
  border-bottom: 1px solid black;
  padding: 0px 10px;
  margin: 0px;
`;
const images = [
  "https://p8.itc.cn/images01/20220107/903bacef9f53486fbc3453e0941b9c0c.jpeg",
  "https://img.ikh.tw/freshweekly/t_freshweekly_3e9yk3pxty3m_5.jpg",
  "https://i.pinimg.com/736x/24/23/fa/2423fa2c33221ea7f500223f9540dff9.jpg",
  "https://images.squarespace-cdn.com/content/v1/6371e838ebd0567316712e65/50b71cab-a535-4868-8da9-b98a3a4db8ee/2019+Palette+Dishes_058.jpg",
];
const imageHeight = 240;
const Image = styled.img`
  width: 100%;
  height: ${imageHeight}px;
  object-fit: cover;
  flex-shrink: 0;
  flex-grow: 0;
`;
const ImageOverlay = styled(Centered)`
  width: calc(100% - 40px);
  max-width: 500px;
  height: ${imageHeight}px;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 20px;
  left: max(20px, 50vw - 250px);
  z-index: 1;
  span {
    ${textTitle}
    color: white;
    font-size: 25px;
    line-height: 1.5;
    text-align: center;
  }
  @media only screen and (max-width: 400px) {
    width: calc(100vw - 40px);
    max-width: 400px;
    left: max(20px, 50vw - 200px);
  }
`;
const CloseButton = styled.button`
  position: fixed;
  top: 30px;
  right: max(30px, 50vw - 250px + 10px);
  background-color: rgba(255, 255, 255, 0.8);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  svg {
    font-size: 20px;
  }
`;
const LunarNewYear2025: React.FC<Membership30DTrialProps> = ({
  onClick,
}: Membership30DTrialProps): ReactElement => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <ContentContainer height={windowHeight}>
      <Image src={images[0]} />
      <CloseButton onClick={onClick.bind(this, 0)}>
        <FontAwesomeIcon icon={faTimes} />
      </CloseButton>
      <ImageOverlay>
        <span>
          恭喜發財<br></br>Lunar new year<br></br>special menus
        </span>
      </ImageOverlay>

      <Benefits>
        <Tagline>pre-order required</Tagline>
        <Benefit>Kee wah Bakery 奇華餅家</Benefit>
        <Benefit>Koi Palace Contempo 尚品鲤鱼门</Benefit>
        <Benefit>HL Peninsula 半岛豪苑酒家</Benefit>
        <Benefit>Hunan House🌶️一屋饭香</Benefit>
        <Benefit>Beijing Duck House 北京食府</Benefit>
        <Benefit>Hong Kong Restaurant 香港小馆</Benefit>
        <Benefit>43&Co Artisan Bakery</Benefit>
        <Benefit>Sweet Home Kitchen 家之味</Benefit>
      </Benefits>
      {/* <Real>$50~$60 average saving per month</Real> */}
      <SkipButton onClick={onClick.bind(this, 0)}>skip</SkipButton>
      <SeeDetails onClick={onClick.bind(this, -1)}>
        <span>see details</span>
      </SeeDetails>
    </ContentContainer>
  );
};

export default LunarNewYear2025;
