import { HeroImage } from "../";
import {
  ContentContainer,
  Overlay,
  Content,
  RestaurantName,
  Title,
  MealLink,
} from "../customers/banners/MealHighlights";
const heroImages = [
  "https://i.pinimg.com/1200x/48/63/cf/4863cf181261b8c10cfcf986d283aae0.jpg",
  // "https://s3-media0.fl.yelpcdn.com/bphoto/9twRePHhqqhe6PvfVS3DZg/o.jpg",
  "https://menuimages.chownowcdn.com/image-resizing?image=1622%2F3bf9b9f9-be53-4043-97bf-ff9c6c61346b.jpg&left=309&top=0&right=48&bottom=0&height=400&width=462&quality=85&fit=contain",
  "https://leitesculinaria.com/wp-content/uploads/2011/11/deep-fried-turkey-1.jpg",
];
const ThanksgivingMenus = () => {
  return (
    <ContentContainer>
      <Overlay></Overlay>
      {heroImages ? <HeroImage images={heroImages} /> : null}
      <Content>
        <RestaurantName>Thanksgiving Menu</RestaurantName>
        <Title>
          <span>supplies won't last!</span>
        </Title>
        <MealLink href={`/holiday`}>Pre-Order Now</MealLink>
      </Content>
    </ContentContainer>
  );
};
export default ThanksgivingMenus;
