import { ObjectId } from "src/constants/types";
import { apiCall } from "src/store/api";

export enum FoodieEvent {
  UNKNOWN = "UNKNOWN",
  PAGE_SIGN_IN = "PAGE_SIGN_IN",
  PAGE_SIGN_UP = "PAGE_SIGN_UP",
  PAGE_HOME = "PAGE_HOME",
  PAGE_SINGLE_MEAL = "PAGE_SINGLE_MEAL",
  PAGE_SINGLE_MEAL_FETCH = "PAGE_SINGLE_MEAL_FETCH",
  PAGE_CART = "PAGE_CART",
  PAGE_CHECKOUT = "PAGE_CHECKOUT",
  PAGE_POST_CHECKOUT = "PAGE_POST_CHECKOUT",
  PAGE_REFERRAL = "PAGE_REFERRAL",
  PAGE_ANONYMOUS_LANDING = "PAGE_ANONYMOUS_LANDING",

  // Actions
  ACTION_ADD_TO_CART = "ACTION_ADD_TO_CART",
  ACTION_COPY_REFERRAL_CODE = "ACTION_COPY_REFERRAL_CODE",
  ACTION_SHARE_EMAIL_REFERRAL_CODE = "ACTION_SHARE_EMAIL_REFERRAL_CODE",
  ACTION_SHARE_SMS_REFERRAL_CODE = "ACTION_SHARE_SMS_REFERRAL_CODE",
}

export enum FoodieSource {
  UNKNOWN = "UNKNOWN",
  BUSINESS_CARDS = "BUSINESS_CARDS",
  INSTAGRAM = "INSTAGRAM",
  STORY = "STORY", // Instagram Story
  PROMO = "PROMO", // Instagram Promo

  // Pages
  PAGE_POST_CHECKOUT = "PAGE_POST_CHECKOUT",
  PAGE_REFERRAL = "PAGE_REFERRAL",
}

export const TrackEvent = async (
  event: FoodieEvent,
  uuid: String,
  source: FoodieSource,
  userId: ObjectId,
  mealId: ObjectId,
  latency: Number
): Promise<void> => {
  await apiCall("POST", "/tracking/TrackEvent", {
    event: event,
    uuid: uuid,
    source: source,
    userId: userId,
    mealId: mealId,
    latency: latency,
  });
};

export const TrackAdsEvent = async (
  fbclid: String,
  data: any
): Promise<void> => {
  const { source, eventCode, page, contentName, orderId } = data;

  await apiCall("POST", "/tracking/TrackAdsEvent", {
    fbclid: fbclid,
    source: source,
    eventCode: eventCode,
    page: page,
    contentName: contentName,
    orderId: orderId,
  });
};
