import styled from "styled-components";
import * as Components from ".";
import { text12300 } from "../Shared";
const Left = styled.span`
  ${text12300}
`;
const Beijing = ({ onClick }) => {
  const link = "/meal/01-28-2025/dinner/beijing-duck-house-";
  const linkLunch = "/meal/01-28-2025/lunch/beijing-duck-house-";
  const image =
    "https://s3-media0.fl.yelpcdn.com/bphoto/O-tQQC1MF5o6w6CVu0Tnhw/o.jpg";
  return (
    <Components.MenuContainer>
      <Components.MealImage src={image} />
      <Components.MealText>
        <Components.RestaurantName>
          Beijing Duck House
        </Components.RestaurantName>
        <Components.MenuName>
          春节超值北京烤鸭<br></br>Peking Duck Set
        </Components.MenuName>
        <Components.Notes>
          {/* <Components.Warning>          
            <Components.Bullet></Components.Bullet>
            Only&nbsp;<b>3 ducks left</b>
          </Components.Warning> */}

          <Components.DeliveryTime>
            <Components.Bullet></Components.Bullet>
            Order by&nbsp;<b>Sunday Jan 26</b>.
          </Components.DeliveryTime>

          <Components.Instructions>
            {/* <FontAwesomeIcon icon={faHeat} /> */}
            <Components.Bullet></Components.Bullet>
            All sales are final.
          </Components.Instructions>
        </Components.Notes>
        <Components.AlaCarteList>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                北京烤鸭 Beijing Roast Duck
              </Components.DishName>
              <Components.AlaCartePrice>$79.99</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              12张鸭饼, 1份鸭酱, 葱, ⻩⽠, 鸭汤 (鸭汤可换椒盐鸭架)。
            </Components.DishDescription>
          </Components.AlaCarteItem>
        </Components.AlaCarteList>
        <Components.PreOrderButtonWithNotesContainer>
          {/* <Left>
            only <b>0 ducks</b> left
          </Left> */}
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(linkLunch);
            }}
            disabled
          >
            Sold Out<br></br>除夕午餐
          </Components.PreOrderButton>
          {/* <Left>
            only <b>1 ducks</b> left
          </Left> */}
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(link);
            }}
            disabled
          >
            Sold Out<br></br>除夕晚餐
          </Components.PreOrderButton>
        </Components.PreOrderButtonWithNotesContainer>
      </Components.MealText>
    </Components.MenuContainer>
  );
};

export default Beijing;
