import { setMeals } from "./meals";
import { LOGGING } from "../../constants";
import {
  LOAD_RESTAURANTS,
  LOAD_TOP_RESTAURANTS,
  LOAD_RESTAURANT_DETAILS,
  LOAD_FAVORITES,
  LOAD_CUISINES,
} from "../actionTypes";
import { apiCall } from "../api";

export const LoadRestaurants = (restaurants) => ({
  type: LOAD_RESTAURANTS,
  restaurants,
});
export const LoadTopRestaurants = (topRestaurants) => ({
  type: LOAD_TOP_RESTAURANTS,
  topRestaurants,
});
export const LoadRestaurantDetails = (restaurantDetails) => ({
  type: LOAD_RESTAURANT_DETAILS,
  restaurantDetails,
});
export const LoadCuisines = (cuisines) => ({
  type: LOAD_CUISINES,
  cuisines,
});
export const LoadFavorites = (favorites) => ({
  type: LOAD_FAVORITES,
  favorites,
});
export const createRestaurantAccount = (restaurantId) => {
  LOGGING && console.log("createRestaurantAccount called with:", restaurantId);
  return (dispatch, getState) => {
    return apiCall(
      "post",
      `/restaurants/account/${restaurantId}`,
      getState().currentUser
    )
      .then(() => {
        LOGGING && console.log("createRestaurantAccount success");
        return;
      })
      .catch((err) => {
        LOGGING && console.log("saveRestaurant got err", err);
        throw err;
      });
  };
};
export const saveRestaurant = (restaurant, options) => {
  LOGGING && console.log("saveRestaurant called with:", restaurant);
  return (dispatch, getState) => {
    const { restaurants } = getState();
    const { _id } = restaurant;
    const path = _id ? `/restaurants/${_id}` : "/restaurants";
    return apiCall("post", path, { ...restaurant }, getState().currentUser)
      .then((updatedRestaurant) => {
        LOGGING &&
          console.log(
            "saveRestaurant got updatedRestaurant",
            updatedRestaurant
          );
        let updatedRestaurants = _id
          ? restaurants.map((restaurant) =>
              restaurant._id === _id
                ? { ...updatedRestaurant }
                : { ...restaurant }
            )
          : [...restaurants, updatedRestaurant];
        // updatedRestaurants.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
        dispatch(LoadRestaurants(updatedRestaurants));
        options?.success();
      })
      .catch((err) => {
        LOGGING && console.log("saveRestaurant got err", err);
        options?.error(err);
      });
  };
};

export const saveMarkups = ({ _id, markups }) => {
  LOGGING && console.log("saveMarkups called with:", { _id, markups });
  return (dispatch, getState) => {
    const { restaurants } = getState();
    return apiCall(
      "post",
      `/restaurants/markups/${_id}`,
      { markups },
      getState().currentUser
    )
      .then((updatedRestaurant) => {
        LOGGING &&
          console.log("saveMarkups got updatedRestaurant", updatedRestaurant);
        let updatedRestaurants = _id
          ? restaurants.map((restaurant) =>
              restaurant._id === _id
                ? { ...updatedRestaurant }
                : { ...restaurant }
            )
          : [...restaurants, updatedRestaurant];
        // updatedRestaurants.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
        dispatch(LoadRestaurants(updatedRestaurants));
      })
      .catch((err) => {
        LOGGING && console.log("saveMarkups got err", err);
      });
  };
};

export const saveCommission = (restaurant) => {
  LOGGING && console.log("saveCommission called with:", restaurant);
  const { commissionBefore, commission, commissionAfter, _id } = restaurant;
  return (dispatch, getState) => {
    return apiCall(
      "put",
      `/restaurants/${_id}`,
      { commissionBefore, commission, commissionAfter },
      getState().currentUser
    )
      .then((result) => {
        LOGGING && console.log("saveCommission got result", result);
        return result;
      })
      .catch((err) => {
        LOGGING && console.log("saveCommission got err", err);
        throw err;
      });
  };
};

export const fetchRestaurant = (placeId) => {
  LOGGING && console.log("fetchRestaurant called with:", placeId);
  return (dispatch, getState) => {
    return apiCall("GET", `/restaurants/${placeId}`, {}, getState().currentUser)
      .then((restaurant) => {
        LOGGING && console.log("fetchRestaurant got restaurant", restaurant);
        return restaurant;
      })
      .catch((err) => {
        LOGGING && console.log("fetchRestaurant got err", err);
      });
  };
};

export const readRestaurantDetails = (restaurantId) => {
  LOGGING && console.log("readRestaurantDetails called with:", restaurantId);
  return (dispatch, getState) => {
    return apiCall(
      "get",
      `/restaurants/details/${restaurantId}`,
      {},
      getState().currentUser
    )
      .then((restaurant) => {
        LOGGING &&
          console.log("readRestaurantDetails got restaurant", restaurant);
        return restaurant;
      })
      .catch((err) => {
        LOGGING && console.log("readRestaurantDetails got err", err);
      });
  };
};

export const updateCategory = ({
  restaurantId,
  name,
  priority,
  categoryId,
}) => {
  LOGGING &&
    console.log("updateCategory called with:", {
      restaurantId,
      name,
      priority,
      categoryId,
    });
  return (dispatch, getState) => {
    return apiCall(
      "put",
      `/restaurants/category/${restaurantId}`,
      { name, priority, categoryId },
      getState().currentUser
    )
      .then((categories) => {
        LOGGING && console.log("updateCategory got categories", categories);
        return categories;
      })
      .catch((err) => {
        LOGGING && console.log("updateCategory got err", err);
      });
  };
};

export const createCategory = (restaurantId, cateogryName) => {
  LOGGING &&
    console.log("createCategory called with:", restaurantId, cateogryName);
  return (dispatch, getState) => {
    return apiCall(
      "post",
      `/restaurants/category/${restaurantId}`,
      { name: cateogryName },
      getState().currentUser
    )
      .then((categories) => {
        LOGGING && console.log("createCategory got categories", categories);
        return categories;
      })
      .catch((err) => {
        LOGGING && console.log("createCategory got err", err);
      });
  };
};

export const readRestaurantByName = (restaurantName) => {
  LOGGING && console.log("readRestaurantByName called with:", restaurantName);
  return (dispatch, getState) => {
    return apiCall(
      "get",
      `/restaurants/name/${restaurantName}`,
      {},
      getState().currentUser
    )
      .then(({ restaurant, meals }) => {
        LOGGING &&
          console.log("readRestaurantByName got restaurant", {
            restaurant,
            // dishes,
            meals,
          });
        return { restaurant, meals };
      })
      .catch((err) => {
        LOGGING && console.log("readRestaurantByName got err", err);
      });
  };
};

export const readRestaurantSelections = (restaurantId) => {
  LOGGING && console.log("readRestaurantSelections called with:", restaurantId);
  return (dispatch, getState) => {
    return apiCall(
      "get",
      `/restaurants/selections/${restaurantId}`,
      {},
      getState().currentUser
    )
      .then((selectionsForRestaurant) => {
        LOGGING &&
          console.log(
            "readRestaurantSelections got selectionsForRestaurant",
            selectionsForRestaurant
          );
        return selectionsForRestaurant;
      })
      .catch((err) => {
        LOGGING && console.log("readRestaurantSelections got err", err);
      });
  };
};

export const getRestaurantsByAlphabet = () => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    const { user } = currentUser;
    return apiCall("GET", `/restaurants/alphabet/${user?._id}`)
      .then(({ cuisines, favorites, restaurants }) => {
        // LOGGING &&
        //   console.log("getRestaurantsByAlphabet got restaurants", restaurants);
        dispatch(LoadRestaurants(restaurants));
        dispatch(LoadFavorites(favorites));
        dispatch(LoadCuisines(cuisines));
        return cuisines;
      })
      .catch((err) => {
        LOGGING && console.log("getRestaurantsByAlphabet got err", err);
      });
  };
};

export const readRestaurantHistory = (restaurantId) => {
  LOGGING && console.log("readRestaurantHistory called with:", restaurantId);
  return (dispatch, getState) => {
    return apiCall(
      "get",
      `/restaurants/history/${restaurantId}`,
      {},
      getState().currentUser
    )
      .then((result) => {
        LOGGING && console.log("readRestaurantHistory got result", result);
        return result;
      })
      .catch((err) => {
        LOGGING && console.log("readRestaurantHistory got err", err);
      });
  };
};

export const readRestaurantMeals = (restaurantId) => {
  LOGGING && console.log("readRestaurantMeals called with:", restaurantId);
  return (dispatch, getState) => {
    return apiCall(
      "get",
      `/meals/restaurant/${restaurantId}`,
      {},
      getState().currentUser
    )
      .then((meals) => {
        LOGGING && console.log("readRestaurantMeals got meals", meals);
        return meals;
      })
      .catch((err) => {
        LOGGING && console.log("readRestaurantMeals got err", err);
      });
  };
};

export const readRestaurants = () => {
  LOGGING && console.log("readRestaurants called.");
  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall(
      "put",
      `/restaurants/${currentUser.user._id}`,
      {},
      getState().currentUser
    )
      .then((result) => {
        LOGGING && console.log("readRestaurants got result:", result);
        let restaurants = result.map((restaurant) => ({
          ...restaurant,
          name: restaurant.name ? restaurant.name.toLowerCase() : "",
        }));
        LOGGING &&
          console.log(
            "readRestaurants got lower case restaurants:",
            restaurants
          );
        // restaurants.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
        dispatch(LoadRestaurants(restaurants));
        return restaurants;
      })
      .catch((err) => {
        LOGGING && console.log("readRestaurants got err", err);
      });
  };
};

export const getNearByRestaurants = ({ restaurantId, dayOfWeek, mealType }) => {
  LOGGING && console.log("getNearByRestaurants called with:", restaurantId);
  return apiCall("post", "/restaurants/GetNearByRestaurants", {
    restaurantId,
    radius: 1,
    dayOfWeek,
    mealType,
  })
    .then((restaurants) => {
      LOGGING &&
        console.log("getNearByRestaurants got restaurants", restaurants);
      return restaurants;
    })
    .catch((err) => {
      LOGGING && console.log("getNearByRestaurants got err", err);
    });
};

export const readRestaurantStats = (restaurantId) => {
  LOGGING && console.log("readRestaurantStats called with:", restaurantId);
  return (dispatch, getState) => {
    return apiCall(
      "get",
      `/restaurants/readRestaurantStats/${restaurantId}`,
      {},
      getState().currentUser
    )
      .then((stats) => {
        LOGGING && console.log("readRestaurantStats got stats", stats);
        return stats;
      })
      .catch((err) => {
        LOGGING && console.log("readRestaurantStats got err", err);
      });
  };
};

export const readTopRestaurants = () => {
  // LOGGING && console.log("readTopRestaurants called.");
  return (dispatch, getState) => {
    return apiCall("get", "/restaurants/metrics/user?isTopChoice=true")
      .then((topRestaurants) => {
        LOGGING &&
          console.log(
            "readTopRestaurants got restaurants from server: ",
            topRestaurants.length
          );
        const oldTopRestaurants = getState().topRestaurants;
        const updatedTopRestaurants = topRestaurants.map((r) => {
          const found = oldTopRestaurants.find(
            (restaurant) => restaurant.restaurantId === r.restaurantId
          );
          return found ? { ...found, ...r } : r;
        });
        dispatch(LoadTopRestaurants(updatedTopRestaurants));
        return updatedTopRestaurants;
      })
      .catch((err) => {
        LOGGING && console.log("readTopRestaurants got err", err);
      });
  };
};

export const readTopRestaurantUserStats = (restaurantIds) => {
  // LOGGING && console.log("readTopRestaurants called.");
  return (dispatch, getState) => {
    return apiCall("post", "/restaurants/metrics/user", {
      userId: getState().currentUser?.user?._id,
      restaurantIds,
    })
      .then((restaurantLikes) => {
        LOGGING &&
          console.log(
            "readTopRestaurantUserStats got restaurant likes from server: ",
            restaurantLikes
          );
        const updatedTopRestaurants = getState().topRestaurants.map((r) => {
          return { ...r, ...restaurantLikes[r.restaurantId] };
        });
        dispatch(LoadTopRestaurants(updatedTopRestaurants));
        return updatedTopRestaurants;
      })
      .catch((err) => {
        LOGGING && console.log("readTopRestaurantUserStats got err", err);
      });
  };
};

export const readMealRestaurantUserStats = (restaurantId) => {
  return (dispatch, getState) => {
    return apiCall("post", "/restaurants/metrics/user", {
      userId: getState().currentUser?.user?._id,
      restaurantIds: [restaurantId],
    })
      .then((restaurantLikes) => {
        LOGGING &&
          console.log(
            "readMealRestaurantUserStats got restaurant likes from server: ",
            restaurantLikes
          );
        const oldMeals = getState().meals;
        // LOGGING && console.log("readMealRestaurantUserStats oldMeals", oldMeals);
        const updatedMeals = oldMeals.map((m) => {
          if (m.restaurant?._id === restaurantId) {
            const updatedRestaurant = {
              ...m.restaurant,
              ...restaurantLikes[restaurantId],
            };
            return { ...m, restaurant: updatedRestaurant };
          } else {
            return m;
          }
        });
        // LOGGING && console.log("readMealRestaurantUserStats updatedMeals", updatedMeals);
        dispatch(setMeals(updatedMeals));
        return restaurantLikes[restaurantId];
      })
      .catch((err) => {
        LOGGING && console.log("readMealRestaurantUserStats got err", err);
      });
  };
};

export const setRestaurantWantsToTry = (restaurantId, wantsToTry) => {
  LOGGING &&
    console.log("setRestaurantWantsToTry called with:", {
      restaurantId,
      wantsToTry,
    });
  return (dispatch, getState) => {
    return apiCall("PUT", `/restaurants/metrics/user`, {
      userId: getState().currentUser?.user?._id,
      restaurantId,
      wantsToTry,
    })
      .then((restaurant) => {
        LOGGING &&
          console.log("setRestaurantWantsToTry got restaurant", restaurant);
        const wantsToTry = restaurant[0]?.wantsToTry;
        const topRestaurants = getState().topRestaurants.map((r) =>
          r.restaurantId === restaurantId ? { ...r, wantsToTry } : r
        );
        dispatch(LoadTopRestaurants(topRestaurants));
        const updatedRestaurantDetails = getState().restaurantDetails.map((r) =>
          r._id === restaurantId ? { ...r, wantsToTry } : r
        );
        dispatch(LoadRestaurantDetails(updatedRestaurantDetails));
        return wantsToTry;
      })
      .catch((err) => {
        LOGGING && console.log("setRestaurantWantsToTry got err", err);
      });
  };
};

export const setRestaurantHasTried = (restaurantId, hasTried) => {
  LOGGING &&
    console.log("setRestaurantHasTried called with:", {
      restaurantId,
      hasTried,
    });
  return (dispatch, getState) => {
    return apiCall("PUT", `/restaurants/metrics/user`, {
      userId: getState().currentUser?.user?._id,
      restaurantId,
      hasTried,
    })
      .then((restaurant) => {
        LOGGING &&
          console.log("setRestaurantHasTried got restaurant", restaurant);
        const hasTried = restaurant[0]?.hasTried;
        const topRestaurants = getState().topRestaurants.map((r) =>
          r.restaurantId === restaurantId ? { ...r, hasTried } : r
        );
        dispatch(LoadTopRestaurants(topRestaurants));
        const updatedRestaurantDetails = getState().restaurantDetails.map((r) =>
          r._id === restaurantId ? { ...r, hasTried } : r
        );
        dispatch(LoadRestaurantDetails(updatedRestaurantDetails));
        return hasTried;
      })
      .catch((err) => {
        LOGGING && console.log("setRestaurantHasTried got err", err);
      });
  };
};

export const setRestaurantStarRating = (restaurantId, starRating) => {
  LOGGING &&
    console.log("setRestaurantStarRating called with:", {
      restaurantId,
      starRating,
    });
  return (dispatch, getState) => {
    return apiCall("PUT", `/restaurants/metrics/user`, {
      userId: getState().currentUser?.user?._id,
      restaurantId,
      starRating,
    })
      .then((restaurant) => {
        LOGGING &&
          console.log("setRestaurantStarRating got restaurant", restaurant);
        const starRating = restaurant[0]?.starRating;
        const topRestaurants = getState().topRestaurants.map((r) =>
          r.restaurantId === restaurantId ? { ...r, starRating } : r
        );
        dispatch(LoadTopRestaurants(topRestaurants));
        const updatedRestaurantDetails = getState().restaurantDetails.map((r) =>
          r._id === restaurantId ? { ...r, starRating } : r
        );
        dispatch(LoadRestaurantDetails(updatedRestaurantDetails));
        return starRating;
      })
      .catch((err) => {
        LOGGING && console.log("setRestaurantStarRating got err", err);
      });
  };
};

export const setRestaurantReview = (restaurantId, review) => {
  LOGGING &&
    console.log("setRestaurantReview called with:", {
      restaurantId,
      review,
    });
  return (dispatch, getState) => {
    return apiCall("PUT", `/restaurants/metrics/user`, {
      userId: getState().currentUser?.user?._id,
      restaurantId,
      review,
    })
      .then((restaurant) => {
        LOGGING &&
          console.log("setRestaurantReview got restaurant", restaurant);
        const review = restaurant[0]?.review;
        const topRestaurants = getState().topRestaurants.map((r) =>
          r.restaurantId === restaurantId ? { ...r, review } : r
        );
        dispatch(LoadTopRestaurants(topRestaurants));
        const updatedRestaurantDetails = getState().restaurantDetails.map((r) =>
          r._id === restaurantId ? { ...r, review } : r
        );
        dispatch(LoadRestaurantDetails(updatedRestaurantDetails));
        return review;
      })
      .catch((err) => {
        LOGGING && console.log("setRestaurantReview got err", err);
      });
  };
};

export const readRestaurantDetails2 =
  (restaurantId) => async (dispatch, getState) => {
    LOGGING && console.log("readRestaurantDetails2 called with:", restaurantId);
    try {
      const restaurantDetails = await apiCall(
        "post",
        `/restaurants/details`,
        {
          restaurantId,
          userId: getState().currentUser?.user?._id,
        },
        getState().currentUser
      );
      console.log(
        "readRestaurantDetails2 got restaurantDetails from server: ",
        restaurantDetails
      );

      const updatedRestaurantDetails = (() => {
        const exists = getState().restaurantDetails.some(
          (restaurant) => restaurant._id === restaurantId
        );
        if (exists) {
          return getState().restaurantDetails.map((restaurant) =>
            restaurant._id === restaurantId
              ? { ...restaurant, ...restaurantDetails }
              : { ...restaurant }
          );
        } else {
          return [
            ...getState().restaurantDetails,
            { _id: restaurantId, ...restaurantDetails },
          ];
        }
      })();
      dispatch(LoadRestaurantDetails(updatedRestaurantDetails));
      return restaurantDetails;
    } catch (err) {
      LOGGING && console.log("readRestaurantDetails2 got err", err);
      throw err;
    }
  };
