import * as Components from ".";

const Company43 = ({ onClick }) => {
  const linkLunch = "/meal/01-29-2025/lunch/43-company";
  const image =
    "https://43andcompany.myshopify.com/cdn/shop/files/79C7E6E0-8BC3-4441-B342-DD4A494D80D4_1024x1024.jpg?v=1737404415";
  return (
    <Components.MenuContainer>
      <Components.MealImage src={image} />
      <Components.MealText>
        <Components.RestaurantName>43 & Co.</Components.RestaurantName>
        <Components.MenuName>Lunar New Year Special</Components.MenuName>
        <Components.Notes>
          <Components.DeliveryTime>
            <Components.Bullet></Components.Bullet>
            Order by&nbsp;<b>Monday Jan 27</b>.
          </Components.DeliveryTime>
          <Components.Instructions>
            <Components.Bullet></Components.Bullet>
            All sales are final.
          </Components.Instructions>
        </Components.Notes>
        <Components.AlaCarteList>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                Special Flavor Cream Puffs
              </Components.DishName>
              <Components.AlaCartePrice>$28</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              3 Red Bean Vanilla Cream Puffs, 3 Thai Tea Cream Puffs.
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>Pineapple Tarts</Components.DishName>
              <Components.AlaCartePrice>$56</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>0.5 dozen.</Components.DishDescription>
          </Components.AlaCarteItem>
        </Components.AlaCarteList>
        <Components.PreOrderButtonWithNotesContainer>
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(linkLunch);
            }}
          >
            pre-order LNY day lunch<br></br>春節午餐
          </Components.PreOrderButton>
        </Components.PreOrderButtonWithNotesContainer>
      </Components.MealText>
    </Components.MenuContainer>
  );
};

export default Company43;
