export const LOAD_BOOKMARKS = "LOAD_BOOKMARKS";
export const LOAD_CART = "LOAD_CART";
export const LOAD_CUISINES = "LOAD_CUISINES";
export const LOAD_DISHES = "LOAD_DISHES";
export const LOAD_MENUS = "LOAD_MENUS";
export const LOAD_ORDERS = "LOAD_ORDERS";
export const LOAD_CHECKOUTS = "LOAD_CHECKOUTS";
export const LOAD_CARTS = "LOAD_CARTS";
export const LOAD_CANCELED_ORDERS = "LOAD_CANCELED_ORDERS";
export const LOAD_POSTS = "LOAD_POSTS";
export const LOAD_RESTAURANTS = "LOAD_RESTAURANTS";
export const LOAD_TOP_RESTAURANTS = "LOAD_TOP_RESTAURANTS";
export const LOAD_RESTAURANT_DETAILS = "LOAD_RESTAURANT_DETAILS";
export const LOAD_FAVORITES = "LOAD_FAVORITES";
export const LOAD_ROUTE = "LOAD_ROUTE";
export const LOAD_ROUTES = "LOAD_ROUTES";
export const LOAD_STATS = "LOAD_STATS";
export const LOAD_WEEK = "LOAD_WEEK";
export const LOAD_TOP_BLOCKS = "LOAD_TOP_BLOCKS";
export const RESET_BOOKMARKS = "RESET_BOOKMARKS";
export const RESET_CART = "RESET_CART";
export const RESET_CUISINES = "RESET_CUISINES";
export const RESET_FAVORITES = "RESET_FAVORITES";
export const RESET_DISHES = "RESET_DISHES";
export const RESET_MEALS = "RESET_MEALS";
export const RESET_NOMINATION = "RESET_NOMINATION";
export const RESET_ORDERS = "RESET_ORDERS";
export const RESET_CHECKOUTS = "RESET_CHECKOUTS";
export const RESET_CARTS = "RESET_CARTS";
export const RESET_CANCELED_ORDERS = "RESET_CANCELED_ORDERS";
export const RESET_POSTS = "RESET_POSTS";
export const RESET_RESTAURANTS = "RESET_RESTAURANTS";
export const RESET_TOP_RESTAURANTS = "RESET_TOP_RESTAURANTS";
export const RESET_RESTAURANT_DETAILS = "RESET_RESTAURANT_DETAILS";
export const RESET_TOP_BLOCKS = "RESET_TOP_BLOCKS";
export const RESET_STATS = "RESET_STATS";
export const SET_CONFIG_ADDRESS_LIST = "SET_CONFIG_ADDRESS_LIST";
export const SET_COUPON_CODES = "SET_COUPON_CODES";
export const SET_CREDIT = "SET_CREDIT";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const RESET_CURRENT_USER = "RESET_CURRENT_USER";
export const SET_DAY_ORDERS = "SET_DAY_ORDERS";
export const SET_DELIVERY_ZONES = "SET_DELIVERY_ZONES";
export const SET_DELIVERY_ZONE_BY_CITY = "SET_DELIVERY_ZONE_BY_CITY";
export const SET_DRIVERS = "SET_DRIVERS";
export const SET_FEATURES = "SET_FEATURES";
export const SET_GIFT_CARD = "SET_GIFT_CARD";
export const SET_GIFT_CARDS = "SET_GIFT_CARDS";
export const SET_GIFT_CARD_TEMPLATES = "SET_GIFT_CARD_TEMPLATES";
export const SET_IS_LUNCH = "SET_IS_LUNCH";
export const SET_MEALS = "SET_MEALS";
export const SET_MILEAGE = "SET_MILEAGE";
export const SET_NOMINATION = "SET_NOMINATION";
export const SET_PHONE_ONLY = "SET_PHONE_ONLY";
export const SET_SUPPORT_LUNCH_EARLY_DELIVERY =
  "SET_SUPPORT_LUNCH_EARLY_DELIVERY";
export const SET_USER_DRIVER_INFO = "SET_USER_DRIVER_INFO";
export const SET_VIEW_BY_WEEK = "SET_VIEW_BY_WEEK";
export const SET_DELIVERY_CITY = "SET_DELIVERY_CITY";
export const RESET_DELIVERY_CITY = "RESET_DELIVERY_CITY";
export const SET_REFERRAL_CODE = "SET_REFERRAL_CODE";
export const CLEAN_REFERRAL_CODE = "CLEAN_REFERRAL_CODE";
export const SET_STATS_TYPE = "SET_STATS_TYPE";
export const SET_WEEKLY_MENU = "SET_WEEKLY_MENU";
export const RESET_MILEAGE = "RESET_MILEAGE";
export const RESET_WEEKLY_MENU = "RESET_WEEKLY_MENU";
export const LOAD_ORDERS_HISTORY_BY_RESTAURANT =
  "LOAD_ORDERS_HISTORY_BY_RESTAURANT";
export const RESET_ORDERS_HISTORY_BY_RESTAURANT =
  "RESET_ORDERS_HISTORY_BY_RESTAURANT";

// Admin Delivery V2 actions
export const LOAD_ADMIN_DELIVERY_V2_DRIVERS = "LOAD_ADMIN_DELIVERY_V2_DRIVERS";
export const LOAD_ADMIN_DELIVERY_V2_MEALS = "LOAD_ADMIN_DELIVERY_V2_MEALS";
export const LOAD_ADMIN_DELIVERY_V2_STOPS = "LOAD_ADMIN_DELIVERY_V2_STOPS";
export const ADMIN_DELIVERY_V2_INVITE_DRIVER =
  "ADMIN_DELIVERY_V2_INVITE_DRIVER";
export const ADMIN_DELIVERY_V2_SET_DRIVER_FILTER =
  "ADMIN_DELIVERY_V2_SET_DRIVER_FILTER";
export const ADMIN_DELIVERY_V2_SET_IS_LUNCH_FILTER =
  "ADMIN_DELIVERY_V2_SET_IS_LUNCH_FILTER";
export const ADMIN_DELIVERY_V2_SET_MEAL_TYPE_FILTER =
  "ADMIN_DELIVERY_V2_SET_MEAL_TYPE_FILTER";
export const ADMIN_DELIVERY_V2_SET_MEAL_FILTER =
  "ADMIN_DELIVERY_V2_SET_MEAL_FILTER";
export const LOAD_PICKUPRESTAURANTS = "LOAD_PICKUPRESTAURANTS";
export const RESET_PICKUPRESTAURANTS = "RESET_PICKUPRESTAURANTS";
export const LOAD_START = "LOAD_START";
export const RESET_START = "RESET_START";
export const LOAD_TRACKING = "LOAD_TRACKING";
export const RESET_TRACKING = "RESET_TRACKING";

// Route V2 actions
export const LOAD_ROUTE_V2 = "LOAD_ROUTE_V2";

// Stop actions
export const LOAD_STOPS = "LOAD_STOPS";
export const POP_STOP = "POP_STOP";

// Task actions
export const LOAD_TASKS = "LOAD_TASKS";

// Transient message actions
export const LOAD_TRANSIENT_MESSAGE = "LOAD_TRANSIENT_MESSAGE";

// Restaurant Meal Menu

export const MEAL_MENU_LOAD_RESTAURANT = "RESTAURANT_MEAL_MENU_LOAD_RESTAURANT";
export const MEAL_MENU_LOAD_RESTAURANT_DISHES =
  "MEAL_MENU_LOAD_RESTAURANT_DISHES";
export const MEAL_MENU_LOAD_SELECTED_DISHES = "MEAL_MENU_LOAD_SELECTED_DISHES";

// FOR Highlight on home
export const SET_HIGHLIGHT = "SET_HIGHLIGHT";
export const RESET_HIGHLIGHT = "RESET_HIGHLIGHT";

// FOR holiday
export const LOAD_HOLIDAY_MENUS = "LOAD_HOLIDAY_MENUS";

// FOR pixel
export const LOAD_PIXEL = "LOAD_PIXEL";
