import {
  LOAD_RESTAURANT_DETAILS,
  RESET_RESTAURANT_DETAILS,
} from "../actionTypes";

const restaurantDetails = (state = [], action) => {
  switch (action.type) {
    case LOAD_RESTAURANT_DETAILS:
      return [...action.restaurantDetails];
    case RESET_RESTAURANT_DETAILS:
      return [];
    default:
      return state;
  }
};

export default restaurantDetails;
