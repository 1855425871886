import { useState } from "react";
import { faShoppingCart } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { TrackAdsEvent } from "src/api/tracker";
import { getItemNumberInCart } from "src/constants";
import { LOGGING } from "src/constants";
import { RootState } from "src/store";
import styled from "styled-components";
import { CartDetails } from "./CartDetails";
import { Counter } from "./Counter";
import { JustAdded } from "./JustAdded";
import { NextIcon } from "./NextIcon";
import {
  CenteredByColumn,
  Centered,
  text12300,
  centered,
  NavHeight,
  mobileNextAction,
  BorderGrey,
} from "../../Shared";
import { BreakPoint } from "../mealMenu/MealHeader";

export const ButtonTop = 20;
export const ButtonRight = "70px";
export const ButtonHeight = 40;
export const ButtonWidth = 150;

const ButtonContainer = styled(CenteredByColumn)`
  position: fixed;
  z-index: 102;
  top: ${ButtonTop}px;
  right: ${ButtonRight};
  @media screen and (max-width: ${BreakPoint}px) {
    top: auto;
    bottom: 0px;
    background-color: transparent;
    width: 100vw;
    right: 0px;
    padding: 0px 10px 15px 10px;
    /* height: ${NavHeight}px; */
    /* box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1); */
  }
`;
export const NextActionContainer = styled(Centered)<{ opacity?: number }>`
  /* border: 1px solid orange; */
  @media screen and (max-width: ${BreakPoint}px) {
    position: fixed;
    width: 100vw;
    padding: 10px;
    height: 80px;
    left: 0px;
    bottom: 0px;
    background: ${({ opacity }) => `rgba(255, 255, 255, ${opacity || 0})`};
    /* border-top: 1px solid ${BorderGrey}; */
    box-shadow: 0px -2px 4px ${({ opacity }) => `rgba(0, 0, 0, ${opacity ? 0.1 : 0})`};
  }
`;
export const ToggleCartDetails = styled.button<{
  rotated?: boolean;
  hasGoods?: boolean;
  style?: {};
}>`
  ${centered}
  display: ${({ hasGoods }) => (hasGoods ? "flex" : "none")}!important;
  span {
    ${text12300}
    font-weight: 400;
    margin-right: 10px;
  }
  height: 100%;
  margin: 10px 0 0 20px;
  padding: 0px;
  svg:first-child {
    margin-right: 10px;
    font-size: 14px;
    margin-bottom: 2px;
  }
  /* svg:last-child {
    transition: transform 0.3s ease;
    transform: ${({ rotated }) => (rotated ? "rotate(90deg)" : "rotate(0deg)")};
  } */
  ${({ style }) => ({ ...style })}
  @media screen and (max-width: ${BreakPoint}px) {
    ${mobileNextAction}
    justify-content: space-between;
    svg {
      font-size: 20px !important;
    }
  }
`;
const Container = styled(Centered)``;

const GoToCheckout = styled(Link)<{ disabled?: boolean }>`
  ${centered}
  margin: 0px;
  background-color: black;
  color: white;
  border-radius: 20px;
  padding: 0px 20px;
  height: ${ButtonHeight}px;
  width: ${ButtonWidth}px;
  justify-content: flex-end;
  position: relative;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  > span {
    display: inline-block;
    ${text12300}
    font-weight: 500;
    text-transform: uppercase;
    position: absolute;
    width: 100%;
    left: 0px;
    text-align: center;
  }
  svg {
    font-size: 20px;
  }
  @media screen and (max-width: ${BreakPoint}px) {
    display: none;
  }
`;
export const CheckoutThumbnail = ({
  showPeek,
  onHidePeek,
  goodsToPeek,
  cartCount,
  isMember,
  onEditGoodInCart,
  showCartOnMeal,
  menuLink,
  source,
  fbclid,
}) => {
  const [showDetails, setShowDetails] = useState(showCartOnMeal); // true for debug
  const cart = useSelector((state: RootState) => state.cart.payLoad);
  const onToggleDetails = (e) => {
    e.preventDefault();
    setShowDetails(!showDetails);
  };

  return (
    <Container>
      <ButtonContainer>
        <GoToCheckout
          to="/checkout"
          disabled={getItemNumberInCart(cart) === 0}
          onClick={(e) => {
            // Insert Facebook Pixel tracking here
            // @ts-ignore
            if (window.fbq && fbclid) {
              LOGGING &&
                console.log(`checkout click on ${menuLink} is tracked by fbq`);
              // @ts-ignore
              window.fbq("track", "InitiateCheckout", {
                content_name: `${menuLink} to checkout`,
                content_category: "pageSingleMeal",
                value: 0,
                currency: "USD",
              });

              // Track Ads Event
              TrackAdsEvent(fbclid, {
                source: source,
                eventCode: "InitiateCheckout",
                page: "pageSingleMeal",
                contentName: `${menuLink} to checkout`,
                orderId: cart?._id,
              });
            }
          }}
        >
          {/* <GoToCheckout to="/checkout" disabled={getItemNumberInCart(cart)===0}> */}
          <span>checkout</span>
          {/* <FontAwesomeIcon icon={faChevronRight} /> */}
          <NextIcon />
        </GoToCheckout>
        <NextActionContainer>
          <ToggleCartDetails
            onClick={onToggleDetails}
            rotated={showDetails}
            hasGoods={cartCount > 0}
          >
            <FontAwesomeIcon icon={faShoppingCart} />
            <span>view cart</span>
            <Counter number={cartCount} />
            {/* <FontAwesomeIcon icon={faChevronUp} /> */}
          </ToggleCartDetails>
        </NextActionContainer>
      </ButtonContainer>
      <CartDetails
        show={showDetails}
        isMember={isMember}
        onClose={(e) => {
          e.preventDefault();
          setShowDetails(false);
        }}
        onEditGoodInCart={onEditGoodInCart}
      />
      <JustAdded
        show={showPeek && !showDetails}
        onHide={onHidePeek}
        goodsToPeek={goodsToPeek}
      />
    </Container>
  );
};
