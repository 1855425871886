import React, { useEffect, useState } from "react";
import { useStore, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { deleteAccount } from "src/store/actions";
import styled from "styled-components";
import {
  actionBlack,
  Centered,
  CenteredByColumnLeft,
  NavHeight,
  text12300,
  text13500,
} from "./components/Shared";
import { Back } from "../shared";
const ButtonContainer = styled(Centered)`
  margin-top: 20px;
  width: 100%;
  button:disabled {
    opacity: 0.5;
  }
`;
const ButtonCancel = styled.button`
  ${actionBlack}
  color: black;
  background-color: white;
  margin-right: 20px;
`;
const ButtonContinue = styled.button`
  ${actionBlack}
  background-color: red;
  color: white;
`;
const List = styled(CenteredByColumnLeft)`
  margin-top: ${NavHeight}px;
  padding: 0 20px 20px 20px;
  h2 {
    ${text13500}
    margin-bottom: 0px;
  }
  p {
    ${text12300}
  }
  a {
    ${actionBlack}
    background-color: red;
  }
`;
const PageDeleteAccount = () => {
  const [deleting, setDeleting] = useState(false);
  const currentUser = useStore().getState().currentUser;
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();
  const onBack = (e) => {
    e.preventDefault();
    window.history.back();
  };
  const onDelete = async (e) => {
    e.preventDefault();
    try {
      setDeleting(true);
      console.log("deleting account");
      await dispatch(deleteAccount());
      console.log("account delete is done");
      setDeleting(false);
      history.push("/home");
    } catch (error) {
      console.log("error", error);
      setDeleting(false);
    }
  };
  useEffect(() => {
    console.log("currentUser", currentUser);
  }, [currentUser]);
  return (
    <div className="list">
      <div className="list-title">
        {deleting ? null : <Back onClick={onBack} />}
        <span>delete account</span>
      </div>
      <List>
        <h2 className="list-item-title">
          What happens when you delete your account?
        </h2>
        <p>
          Your account and customer data will be permanently removed from our
          system, any credits and gift card balances will be forfeited.
        </p>
        <ButtonContainer>
          <ButtonCancel onClick={onBack} disabled={deleting}>
            cancel
          </ButtonCancel>
          {deleting ? (
            <ButtonContinue disabled={deleting}>deleting...</ButtonContinue>
          ) : (
            <ButtonContinue onClick={onDelete} disabled={deleting}>
              continue
            </ButtonContinue>
          )}
        </ButtonContainer>
      </List>
    </div>
  );
};
export default PageDeleteAccount;
