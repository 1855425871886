import { useEffect, useState } from "react";
import { useRef } from "react";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { useSelector, useDispatch, useStore } from "react-redux";
import { useLocation, useHistory, Link } from "react-router-dom";
import { MealVisited } from "src/api/meals";
import { readHolidayMenus } from "src/store/actions";
import {
  GetMealTypeFromWindowStart,
  DeliveryWindowDisplay,
} from "src/util/time";
import styled from "styled-components";
import AppStoreLink from "./components/customers/banners/AppStoreLink";
import { BackToTop } from "./components/customers/mealMenu/BackToTop";
import {
  Beijing,
  Company43,
  KeeWah,
  KoiCupertino,
  HLPeninsula,
  HongKongRestaurant,
  HunanHouse,
  SweetHome,
} from "./components/lny2025";
import SharePage from "./components/referrals/SharePage";
import {
  Beige,
  BorderGrey,
  card,
  CenteredByColumn,
  centeredByColumn,
  Text12300,
  textTitle,
  textUpper,
  ALLCAPTEXT,
  actionBlack,
  Card,
  TextTitle,
  Centered,
  BackgroundGrey,
} from "./components/Shared";
import { Kai } from "./PageSMLinkPreview";
import { GetMealLink } from "../../constants";
import { RootState } from "../../store";
// import all components from thanksgiviing2024

interface LocationState {
  homeScrollY?: number;
  // Add other state properties as needed
}

const MealContainer = styled(Link)`
  ${card}
  ${centeredByColumn}
  margin: 10px 15px;
  /* padding-bottom: 20px; */
  border: 1px solid ${BorderGrey};
  flex-grow: 1;
  align-self: stretch;
  max-width: 500px;
  position: relative;
`;
const RestaurantName = styled.span`
  ${ALLCAPTEXT}
  text-align: center;
`;
const MealImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
`;
const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.15);
`;
const MealText = styled(CenteredByColumn)`
  padding: 20px 30px 30px 30px;
  width: 100%;
`;

const Description = styled(Text12300)`
  text-align: center;
  margin-top: 10px;
`;

const Action = styled.div`
  ${actionBlack}
  margin-top: 20px;
  text-transform: uppercase;
  height: 60px;
  font-size: 13px;
  font-weight: 500;
`;

const MealDate = styled.div`
  ${textUpper}
  ${centeredByColumn}  
  text-align: center;
  font-size: 14px;
  padding: 0px;
  margin-bottom: 10px;
`;

const EarlyCutoff = styled(MealDate)`
  display: inline;
  margin-top: 10px;
  margin-bottom: 5px;
  line-height: 1;
  white-space: nowrap;
`;

const Early = styled.span`
  font-weight: 600;
  display: inline;
  /* background-color: yellow;
  padding: 3px 5px; */
`;
const symbolSize = 60;
const SymbolContainer = styled(Centered)`
  background-color: ${BackgroundGrey};
  width: ${symbolSize}px;
  height: ${symbolSize}px;
  border-radius: 50%;
  font-size: ${symbolSize * 0.6}px;
  margin: 5px;
`;
const Symbols = styled(Centered)`
  margin-top: 10px;
`;
const symbolMap = {
  hot: "🍲",
  cold: "🧊",
  turkey: "🦃",
  steak: "🥩",
  duck: "🦆",
};
const Symbol = ({ symbols }) => {
  return (
    <Symbols>
      {symbols.map((symbol) => (
        <SymbolContainer key={symbol}>{symbolMap[symbol]}</SymbolContainer>
      ))}
    </Symbols>
  );
};

const HolidayMeal = ({ meal, homeScrollY }) => {
  const { restaurant, description, earlyCutoffTime } = meal;
  const mealDate = MealToString(meal);
  const { name, heroImages, heroImage } = restaurant;
  const link = GetMealLink(meal);
  return (
    // make meal container a link to the meal
    <MealContainer
      to={{
        pathname: link,
        state: { fromHoliday: true, homeScrollY: window.scrollY },
      }}
    >
      <MealImage src={heroImage || heroImages?.[0] || heroImage} />
      <ImageOverlay></ImageOverlay>
      {meal?.holidaySymbol?.length > 0 ? (
        <Symbol symbols={meal.holidaySymbol} />
      ) : null}
      <MealText>
        {earlyCutoffTime && (
          <EarlyCutoff>
            order by{" "}
            <Early>{moment(earlyCutoffTime).format("h:mmA ddd MM/DD")}</Early>
          </EarlyCutoff>
        )}
        <MealDate>{mealDate}</MealDate>
        <RestaurantName>{name.split("-")[0].trim()}</RestaurantName>
        {description && <Description>{description}</Description>}
        <Action>Pre-Order Now</Action>
      </MealText>
    </MealContainer>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 70px;
  background-color: ${Beige};
  @media screen and (min-width: 700px) {
    flex-direction: row;
  }
`;
const Carousel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 0;
  padding: 20px 15px;
  max-width: 100vw;
  overflow-x: auto;
  ${card}
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 2;
`;
const CarouselContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
  button {
    ${ALLCAPTEXT}
    font-weight: 500;
    font-size: 14px;
    border-bottom: 2px solid transparent;
    margin-right: 25px;
    white-space: nowrap;
    line-height: 2;
    padding: 0px;
  }
  button.active-button {
    border-bottom: 2px solid black;
  }
`;
const List = styled(CenteredByColumn)`
  overflow-y: "auto";
  max-height: "100vh";
  align-self: stretch;
  align-items: center;
  padding: 50px 20px 20px 20px;
  @media screen and (min-width: 700px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const Section = styled(CenteredByColumn)`
  margin-top: 20px;
`;
const DeliverySectionTitle = styled(CenteredByColumn)`
  padding: 15px;
  text-align: center;
  background: transparent;
  box-shadow: none;
  border: none;
  margin-bottom: 25px;
`;
const Day = styled.div`
  ${textTitle}
  /* font-size: 20px; */
  font-size: 24px;
  margin-bottom: 12px;
  position: relative;
  &::before {
    content: " ";
    display: block;
    width: 15px;
    height: 2px;
    background-color: black;
    position: absolute;
    left: -20px;
    top: 46%;
  }
  &::after {
    content: " ";
    display: block;
    width: 15px;
    height: 2px;
    background-color: black;
    position: absolute;
    right: -20px;
    top: 46%;
  }
`;
const MealToString = (meal: any) =>
  `Receive ${
    DeliveryWindowDisplay[GetMealTypeFromWindowStart(meal.windowStart)]
  } ${moment(meal.windowStart).format("ddd MM/DD")}`;

const MealToStringThanksgiving = (meal: any) => {
  let result = "thanksgiving";
  const dayOfWeek = moment(meal.windowStart).format("ddd");
  if (dayOfWeek === "Wed") {
    result = "thanksgiving eve";
  }

  result += moment(meal.windowStart).format(" (ddd, MM/DD) ");

  const hour = moment(meal.windowStart).hour();
  if (hour >= 12) {
    result += " 6~7PM";
  } else {
    result += " 11:30~12:30PM";
  }

  return result;
};

const MealToStringChristmas = (meal: any) => {
  let result = "christmas";
  const dayOfWeek = moment(meal.windowStart).format("ddd");
  if (dayOfWeek === "Tue") {
    result = "christmas eve";
  }

  result += moment(meal.windowStart).format(" (ddd, MM/DD) ");

  const hour = moment(meal.windowStart).hour();
  if (hour >= 12) {
    result += " 6~7PM";
  } else {
    result += " 11:30~12:30PM";
  }

  return result;
};

const formatDateStringSectionTitle = (input) => {
  const regex = /(.*?)\s\((\w+),\s([\d/]+)\)\s(.*)/; // Regex to extract parts
  const match = input.match(regex);

  if (match) {
    const part1 = match[1]; // Extract "thanksgiving eve" or "christmas eve"
    const day = match[2]; // Extract "Wed"
    const date = match[3]; // Extract "11/27"
    const suffix = match[4]; // Extract "dinners"

    return { part1, part2: `Receive ${suffix} ${day} ${date}` };
  }

  return input; // Return original string if format doesn't match
};

const formatDateToLinesCarousel = (input) => {
  const regex = /(.*?)\s\((\w+),\s([\d/]+)\)\s(.*)/; // Regex to extract parts
  const match = input.match(regex);

  if (match) {
    const day = match[2]; // Extract "Wed"
    const date = match[3]; // Extract "11/27"
    const time = match[4]; // Extract "6~7PM"
    return `${time} ${day} ${date}`; // Combine and return
  }

  return ""; // Return empty string if format doesn't match
};

const Line = styled.div`
  ${ALLCAPTEXT}
  font-size: 14px;
  display: block;
  margin: 0; /* Ensures no additional spacing between lines */
`;

const EndingContainer = styled(Day)`
  margin: 40px 0px 100px 0px;
  @media screen and (min-width: 700px) {
    display: none;
  }
`;
const BackButton = styled(Link)`
  position: absolute;
  left: 30px;
  font-size: 24px;
`;
const MenuTitle = styled(CenteredByColumn)``;
const NavContainer = styled(Centered)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100px;
  background-color: white;
  z-index: 2;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0 70px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
`;
const MenuSubtitle = styled(Text12300)`
  text-transform: capitalize;
`;
const Nav = () => (
  <NavContainer>
    <BackButton to="/">
      <FontAwesomeIcon icon={faChevronLeft} />
    </BackButton>
    <MenuTitle>
      <TextTitle>Lunar New Year</TextTitle>
      <MenuSubtitle>special menus</MenuSubtitle>
    </MenuTitle>
    <SharePage
      url="https://foodie.earth/holiday"
      style={{
        right: "20px",
        height: "100px",
      }}
    />
  </NavContainer>
);
const Ending = () => (
  <EndingContainer>
    <Kai>蛇年大吉</Kai>
  </EndingContainer>
);

const PageHoliday = () => {
  const holiday = useSelector((state: RootState) => state.holiday);
  const [activeSection, setActiveSection] = useState(0); // Track active section
  const userName = useSelector(
    (state: RootState) =>
      state.currentUser.user.firstName + " " + state.currentUser.user.lastName
  );
  const [loading, setLoading] = useState(true);
  const [uniqueDates, setUniqueDates] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<LocationState>();
  const [homeScrollY, setHomeScrollY] = useState<number>(
    location.state?.homeScrollY || 0
  );
  const store = useStore();
  const listRef = useRef<HTMLDivElement>(null);
  const sectionRefs = useRef<HTMLDivElement[]>([]);
  const buttonRefs = useRef<HTMLButtonElement[]>([]); // Track button elements

  const scrollButtonToCenter = (index: number) => {
    const button = buttonRefs.current[index];
    if (button) {
      button.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  };

  const scrollToDate = (index: number) => {
    const section = sectionRefs.current[index];
    if (section) {
      const offset = 100; // Adjust for the header height
      const top = section.offsetTop - offset;

      window.scrollTo({
        top,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (!holiday?.meals || loading) {
      readHolidayMenus()(dispatch, store.getState).then((holiday) => {
        setLoading(false);
        console.log(
          "PageHoliday got holiday",
          holiday.meals.map((m) => m.holidaySymbol)
        );
      });
    }
    const sortedMeals = holiday?.meals?.slice().sort((a: any, b: any) => {
      return (
        new Date(a.windowStart).getTime() - new Date(b.windowStart).getTime()
      );
    });
    const sortedDates = Array.from(
      new Set(sortedMeals?.map((meal: any) => MealToStringThanksgiving(meal)))
    );
    setUniqueDates(sortedDates);
  }, [holiday]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      let newActiveSection = 0;
      sectionRefs.current.forEach((section, index) => {
        const sectionTop = section.offsetTop - 100; // Adjust for fixed header
        const sectionBottom = sectionTop + section.offsetHeight;

        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          newActiveSection = index;
        }
      });

      setActiveSection((prevActive) => {
        if (prevActive !== newActiveSection) {
          scrollButtonToCenter(newActiveSection); // Center the button
        }
        return newActiveSection;
      });
    };

    const scrollButtonToCenter = (index: number) => {
      const button = buttonRefs.current[index];
      if (button) {
        button.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    };
    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sectionRefs, uniqueDates]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, homeScrollY);
    }, 1);
  }, [homeScrollY]);

  const onSelectMeal = (link: string) => {
    history.push(link, {
      fromHoliday: true,
      homeScrollY: window.scrollY,
    });
  };

  useEffect(() => {
    MealVisited("holiday menus", userName);
  }, []);
  // LOGGING && console.log("PageHoliday uniqueDates", uniqueDates);
  return (
    <Container>
      {/* <Carousel>
        <CarouselContent>
          {uniqueDates.map((date, index) => (
            <button
              key={index}
              ref={(el) => (buttonRefs.current[index] = el!)}
              className={index === activeSection ? "active-button" : ""}
              onClick={(e) => {
                e.preventDefault();
                setActiveSection(index);
                scrollButtonToCenter(index);
                scrollToDate(index);
              }}
            >
              {formatDateToLinesCarousel(date)}
            </button>
          ))}
        </CarouselContent>
      </Carousel> */}
      <List ref={listRef}>
        <Nav />
        <AppStoreLink
          customStyle={{
            backgroundColor: Beige,
            width: "100%",
            paddingTop: "0px",
            paddingBottom: "15px",
            // borderBottom: `1px solid ${BorderGrey}`,
          }}
        />
        {/* <SweetHome onClick={onSelectMeal} /> */}
        <KoiCupertino onClick={onSelectMeal} />
        <Beijing onClick={onSelectMeal} />
        <HunanHouse onClick={onSelectMeal} />
        <Company43 onClick={onSelectMeal} />
        <HLPeninsula onClick={onSelectMeal} />
        <HongKongRestaurant onClick={onSelectMeal} />
        <KeeWah onClick={onSelectMeal} />
        <Ending />
      </List>
      <BackToTop bottom={20} />
    </Container>
  );
};
export default PageHoliday;
