import { HeroImage } from "..";
import {
  ContentContainer,
  Overlay,
  Content,
  RestaurantName,
  Title,
  MealLink,
} from "../customers/banners/MealHighlights";
const heroImages = [
  "https://restaurantscatering.com/wp-content/uploads/2024/08/Maggianos-little-Italy-Thanksgiving-Menu.png",
  "https://132406897.cdn6.editmysite.com/uploads/1/3/2/4/132406897/s435470380367631874_p433_i2_w2048.jpeg?dpr=2",
  "https://miro.medium.com/v2/resize:fit:3200/1*3TWASWnqXTtsEtULLuoknQ.jpeg",
];
const ChristmasMenus = () => {
  return (
    <ContentContainer>
      <Overlay></Overlay>
      {heroImages ? <HeroImage images={heroImages} /> : null}
      <Content>
        <RestaurantName>Christmas Menu</RestaurantName>
        <Title>
          <span>supplies won't last!</span>
        </Title>
        <MealLink href={`/holiday`}>Pre-Order Now</MealLink>
      </Content>
    </ContentContainer>
  );
};
export default ChristmasMenus;
