import React, { ReactElement, useState } from "react";
import {
  faPiggyBank,
  faSackDollar,
  faShoppingCart,
  faGift,
  faBook,
} from "@fortawesome/pro-light-svg-icons";
import { faAlarmClock, faBurgerSoda } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "src/store";
import styled, { css } from "styled-components";
import { LOGGING } from "../..";
import { FeedbackForm } from "../../favorites/FeedbackForm";
import {
  Beige,
  BorderGrey,
  Centered,
  centered,
  centeredByColumn,
  text13500,
  textTitle,
  NavHeight,
  card,
  text12300,
  Lavendar,
  Blue,
  Neon,
  Gold,
  Bronze,
  VividBlue,
  TextGrey,
} from "../../Shared";
import { BreakPoint } from "../mealMenu/MealHeader";

export interface BannerProps {
  // message: string;
  showUserMenu: boolean;
  onShowNominationForm: () => void;
}

const HEIGHT = 40;
interface ContentContainerProps {
  showUserMenu: boolean;
}
const Icon = styled(Centered)`
  font-size: 30px;
`;
const CreditIcon = styled(Icon)`
  /* background-color: ${Gold}; */
  /* width: 37px; */
  height: 40px;
  /* border-radius: 50%; */
  /* border: 1px solid black; */
  ${text12300}
  font-weight: 600;
  font-size: 22px;
`;
const ContentContainer = styled(Centered)<ContentContainerProps>`
  width: 100%;
  /* height: ${HEIGHT}px; */
  /* z-index: ${(props) => (props.showUserMenu ? 99 : 102)}; */
  /* position: fixed;
  top: var(--nav-height); */
  position: relative;
  background-color: white;
  align-items: center;

  border-bottom: 1px solid ${BorderGrey};
  /* justify-content: space-around; */
  padding: 20px 0px 15px 0px;
  z-index: 99;
  /* justify-content: center */
  a:last-child,
  svg {
    /* color: ${TextGrey} */
  }
  button:last-child {
    border: none;
  }
  @media only screen and (min-width: ${BreakPoint}px) {
    justify-content: center;
    border-top: 1px solid ${BorderGrey};
  }
  @media only screen and (min-width: 1200px) {
    /* background-color: ${Beige}; */
  }
`;
const quickAccess = css`
  ${centeredByColumn}
  /* font-size: 12px; */
  /* margin: 0px 15px;   */
  /* border-bottom: 1px solid black; */
  /* border-right: 1px solid ${BorderGrey}; */
  flex-grow: 1;
  width: 33%;
  max-width: 200px;
  /* align-self: stretch; */
  /* max-width: 150px;
  width: 25%; */
  position: relative;
  svg {
    font-size: 15px;
    margin-right: 7px;
    /* display: none; */
  }
  span {
    ${text12300}
    /* ${textTitle} */
    margin-top: 3px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: capitalize;
  }
`;
const ButtonButton = styled.button`
  ${quickAccess}
`;
const CreditContainer = styled(Centered)`
  ${quickAccess}
`;
const LinkButton = styled(Link)`
  ${quickAccess}
  font-size: 13px;
  span {
    ${text12300}
    /* text-transform: uppercase; */
    margin-top: 5px;
    font-size: 11px;
  }
  @media only screen and (max-width: 360px) {
    span {
      font-size: 11px;
    }
  }
`;
const Count = styled.span`
  display: block;
  width: 20px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  color: white;
  left: calc(50% + 22px);
  top: 6px;
  background-color: ${VividBlue};
  flex-shrink: 0;
  align-self: auto;
  line-height: 20px !important;
`;
const CountCart = styled(Count)`
  background-color: green;
  /* color: black; */
`;
export interface ConfirmationProps {
  visible: boolean;
}
const Confirmation = styled.div<ConfirmationProps>`
  ${card};
  ${textTitle};
  background-color: rgba(5, 5, 5, 0.8);
  color: white;
  text-transform: none;
  font-size: 14px;
  position: fixed;
  top: 10px;
  left: 10px;
  padding: 10px 20px;
  text-align: center;
  border: 1px solid grey;
  height: calc(${1.5 * NavHeight}px - 10px);
  width: calc(100vw - 20px);
  ${centered}
  transform: translateY(${(props) => (props.visible ? "0%" : "-100%")});
  opacity: ${(props) => (props.visible ? "1" : "0")};
  transition: all 400ms ease-in-out;
`;

export const QuickAccess: React.FC<BannerProps> = ({
  showUserMenu,
  onShowNominationForm,
}: BannerProps): ReactElement => {
  const currentUser = useSelector((state: RootState) => state.currentUser);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [showFeedbackConfirmation, setShowFeedbackConfirmation] =
    useState(false);
  const carts = useSelector((state: RootState) => state.carts);
  const orders = useSelector((state: RootState) => state.orders);
  const now = moment().valueOf();
  const upcoming = orders.filter((o) => moment(o.window.end).valueOf() > now);
  // LOGGING && console.log("QuickAccess GOT upcoming", { orders, upcoming });
  const handleSubmit = () => {
    setShowFeedbackForm(false);
    setShowFeedbackConfirmation(true);
    setTimeout(() => {
      setShowFeedbackConfirmation(false);
    }, 1200);
  };

  const handleCloseFeedback = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("handleCloseFeedback called");
    setShowFeedbackForm(false);
  };

  return (
    <ContentContainer showUserMenu={showUserMenu}>
      <Confirmation visible={showFeedbackConfirmation}>
        <span>Thank you for your feedback! We will get back to you ASAP!</span>
      </Confirmation>
      {showFeedbackForm && (
        <FeedbackForm
          placeholder={
            "Feature requests, compliments, complaints, or anything else."
          }
          // @ts-ignore
          onClose={handleCloseFeedback}
          onSubmit={handleSubmit}
        />
      )}
      {/* 👩🏻‍🍳🧾🥡🛒💝*/}
      {/* <LinkButton to="/restaurants">
        <Icon>👩🏻‍🍳</Icon>
        <span>vendors</span>
      </LinkButton> */}
      <LinkButton to="/referrals">
        {/* <Icon>💰</Icon> */}
        <FontAwesomeIcon icon={faSackDollar} />
        <span>referrals</span>
      </LinkButton>
      {/* <CreditContainer>
        <CreditIcon>
          ${currentUser?.user?.credit - currentUser?.user?.creditSpent}
        </CreditIcon>
        <span>credit</span> 
      </CreditContainer>*/}
      <LinkButton to="/gifts">
        {/* <Icon>🎁</Icon> */}
        <FontAwesomeIcon icon={faGift} />
        <span>gift card</span>
      </LinkButton>
      {/* <LinkButton to="/orders">
        <FontAwesomeIcon icon={faBurgerSoda} />        
        <span>orders</span>
        {upcoming.length ? <Count>{upcoming.length}</Count> : null}
      </LinkButton> */}
      <LinkButton to="/guide2025">
        <FontAwesomeIcon icon={faBook} />
        <span>Guide 2025</span>
      </LinkButton>
      <LinkButton to="/unpaid-carts">
        {/* <Icon>🛒</Icon> */}
        <FontAwesomeIcon icon={faShoppingCart} />
        <span>carts</span>
        {carts.length ? <CountCart>{carts.length}</CountCart> : null}
      </LinkButton>

      {/* <LinkButton to="/reminders">
        
        <FontAwesomeIcon icon={faAlarmClock} />
        <span>reminders</span>
      </LinkButton> */}
      {/* <LinkButton to="/taste">
        <Icon>👍</Icon>
        <span>My likes</span>
      </LinkButton> */}
      {/* <ButtonButton onClick={onShowNominationForm}>
        <FontAwesomeIcon icon={faNominate} />
        <span>nomination</span>
      </ButtonButton>
      <ButtonButton
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowFeedbackForm(!showFeedbackForm);
        }}
      >
        <FontAwesomeIcon icon={faFeedback} />
        <span>feedback</span>
      </ButtonButton> */}
    </ContentContainer>
  );
};
