import React, { useState } from "react";
import { Back } from "src/screens/shared";
import styled from "styled-components";
import FullScreenCarousel from "./FullScreenCarousel";
import {
  CenteredByColumnLeft,
  DownwardShadow,
  NavHeight,
  textTitle,
  BorderGrey,
} from "../Shared";

const CollageOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px;
  padding-top: ${NavHeight + 2}px;
`;

const CollageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 2px;
  width: 100%;
  max-width: 1000px;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  cursor: pointer;
  /* border: 2px solid transparent; */
  /* border-radius: 5px; */
  &:hover {
    border-color: white;
  }
`;

const CloseButton = styled.button`
  position: fixed;
  top: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  color: white;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2100;

  &:hover {
    background: rgba(255, 255, 255, 0.7);
    color: black;
  }
`;

const Nav = styled(CenteredByColumnLeft)`
  width: 100%;
  background-color: white;
  height: ${NavHeight}px;
  position: fixed;
  top: 0px;
  left: 0px;
  box-shadow: 0 2px 2px 0px rgba(28, 29, 33, 0.15);
  border-bottom: 1px solid ${BorderGrey};
  span {
    display: inline-block;
    width: 100%;
    text-align: center;
    ${textTitle}
  }
`;
const FullScreenCollage = ({ images, onClose }) => {
  const [isCarouselOpen, setIsCarouselOpen] = useState(false);
  const [initialIndex, setInitialIndex] = useState(0);

  const handleThumbnailClick = (index: number) => {
    setInitialIndex(index);
    setIsCarouselOpen(true);
  };

  const handleCloseCarousel = () => {
    setIsCarouselOpen(false);
  };

  return (
    <>
      <CollageOverlay>
        <Nav>
          <Back onClick={onClose} style={{ zIndex: 1 }} />
          <span>{images.length} photos</span>
        </Nav>
        <CollageContainer>
          {images.map((image, index) => (
            <Thumbnail
              key={index}
              src={image}
              alt={`Thumbnail ${index + 1}`}
              onClick={() => handleThumbnailClick(index)}
            />
          ))}
        </CollageContainer>
      </CollageOverlay>

      {isCarouselOpen && (
        <FullScreenCarousel
          images={images}
          initialIndex={initialIndex}
          onClose={handleCloseCarousel}
        />
      )}
    </>
  );
};

export default FullScreenCollage;
