import * as Components from ".";

const HLPeninsula = ({ onClick }) => {
  const eveLink = "/meal/01-28-2025/dinner/hl-peninsula-dim-sum";
  const eveLinkLunch = "/meal/01-28-2025/lunch/hl-peninsula-dim-sum";
  const link = "/meal/01-29-2025/dinner/hl-peninsula-dim-sum";
  const linkLunch = "/meal/01-29-2025/lunch/hl-peninsula-dim-sum";
  const image =
    "https://img.ikh.tw/freshweekly/t_freshweekly_3e9yk3pxty3m_5.jpg";
  return (
    <Components.MenuContainer>
      <Components.MealImage src={image} />
      <Components.MealText>
        <Components.RestaurantName>
          HL Peninsula 半岛豪苑酒家
        </Components.RestaurantName>
        <Components.MenuName>
          超值新春宴<br></br>New Year Sets
        </Components.MenuName>
        <Components.Notes>
          <Components.DeliveryTime>
            <Components.Bullet></Components.Bullet>
            Order by&nbsp;<b>Monday Jan 27</b>.
          </Components.DeliveryTime>
          <Components.Instructions>
            <Components.Bullet></Components.Bullet>
            All sales are final.
          </Components.Instructions>
        </Components.Notes>
        <Components.AlaCarteList>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                Family Meal A (4) 五福临门宴
              </Components.DishName>
              <Components.AlaCartePrice>$238</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              席前精美凉菜 (Delicious Tapas) 冰烧三层肉 (Roasted Pork Belly)
              鲜芦笋红花蚌炒玉带 (Sauteed Red Clams & Scallops w/ Asparagus)
              发财蚝豉焖猪手 (Braised Dried Oyster w/ Pig Trotter & Black Moss)
              黄椒酱蒸海斑件 (Steamed Lingcod w/ Spicy Yellow Pepper Sauce)
              半岛风味双龙虾 (Spicy Lobsters Peninsula Style w/ Peanut)
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                Family Meal B (4) 金玉满堂宴
              </Components.DishName>
              <Components.AlaCartePrice>$328</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              席前精美凉菜 (Delicious Tapas) 半岛招牌贵妃鸡 (Steamed Concubine
              Chicken Half) 发财玉环瑶柱脯 (Dried Scallop in Turnip Ring & Black
              Moss w/ Oyster Sauce) 鲍鱼焖东坡肉 (Braised Abalones w/ Pork
              Belly) 清蒸海上鲜 (Steamed Catch of the Day) 金沙南瓜焗大蟹
              (Sauteed Crab & Pumpkin w/ Salty Egg Yolk)
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                Family Meal C (6) 迎春纳福宴
              </Components.DishName>
              <Components.AlaCartePrice>$388</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              席前精美凉菜 (Delicious Tapas) 锦绣烧味大拼盘 (House BBQ Combo
              Platter) 御品鲍参翅肚羹 (Deluxe Combination Bisque)
              碧绿红花蚌爆玉带 (Sauteed Red Clams & Scallops w/ Tender Green)
              发财蚝豉焖猪手 (Braised Dried Oyster w/ Pig Trotter & Black Moss)
              招牌玉露豉油鸡 (Soy Sauce Chicken) 半岛风味双龙虾 (Spicy Lobsters
              Peninsula Style w/ Peanut) 金丝海鲜炒饭 (Golden Egg & Seafood
              Fried Rice) 是日精美甜品 (Daily Special Dessert)
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                Family Meal D (6) 富贵吉祥宴
              </Components.DishName>
              <Components.AlaCartePrice>$488</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              席前精美凉菜 (Delicious Tapas) 鸿运烧味大拼盘 (House Special BBQ
              Combo Platter) 鲜拆蟹肉鱼肚羹 (Fish Maw & Fresh Crab Meat Bisque)
              碧绿海参扣鲍鱼 (Sauteed Abalones & Sea Cucumber over Tender Green)
              发财玉环瑶柱脯 (Dried Scallop in Turnip Ring & Black Moss w/
              Oyster Sauce) 半岛招牌贵妃鸡 (Steamed Concubine Chicken)
              清蒸海上鲜 (Steamed Catch of the Day) 瑶柱金菇焖伊面 (E-fu Noodle
              w/ Dried Scallop & Enoki Mushroom) 是日精美甜品 (Daily Special
              Dessert)
            </Components.DishDescription>
          </Components.AlaCarteItem>
        </Components.AlaCarteList>
        <Components.PreOrderButtonWithNotesContainer>
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(eveLinkLunch);
            }}
          >
            pre-order LNY eve lunch<br></br>除夕午餐
          </Components.PreOrderButton>
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(eveLink);
            }}
          >
            pre-order LNY eve dinner<br></br>除夕晚餐
          </Components.PreOrderButton>
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(linkLunch);
            }}
          >
            pre-order LNY day lunch<br></br>春節午餐
          </Components.PreOrderButton>
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(link);
            }}
          >
            pre-order LNY day dinner<br></br>春節晚餐
          </Components.PreOrderButton>
        </Components.PreOrderButtonWithNotesContainer>
      </Components.MealText>
    </Components.MenuContainer>
  );
};

export default HLPeninsula;
