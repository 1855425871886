import * as Components from ".";

const SweetHome = ({ onClick }) => {
  const link = "/meal/01-28-2025/dinner/sweet-home-kitchen-";
  const linkLunch = "/meal/01-28-2025/lunch/sweet-home-kitchen-";
  const image =
    "https://p8.itc.cn/images01/20220107/903bacef9f53486fbc3453e0941b9c0c.jpeg";
  return (
    <Components.MenuContainer>
      <Components.MealImage src={image} />
      <Components.MealText>
        <Components.RestaurantName>
          Sweet Home Kitchen 家之味
        </Components.RestaurantName>
        <Components.MenuName>
          南京风味套餐<br></br>Nanjing Style Family Meal
        </Components.MenuName>
        <Components.Notes>
          <Components.DeliveryTime>
            <Components.Bullet></Components.Bullet>
            Order by&nbsp;<b>Friday Jan 24</b>.
          </Components.DeliveryTime>
          <Components.Instructions>
            {/* <FontAwesomeIcon icon={faHeat} /> */}
            <Components.Bullet></Components.Bullet>
            All sales are final.
          </Components.Instructions>
        </Components.Notes>
        <Components.AlaCarteList>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                Family Meal A 套餐A (8-10)
              </Components.DishName>

              <Components.AlaCartePrice>$225.25</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              卤牛腱 凉拌海带丝 凉拌干丝 南京什锦菜 金陵盐水鸭 豆瓣鲈鱼
              雪菜毛豆炖肉圆 牛蛙 烩三鲜 上汤时蔬 茄汁山药 排骨玉米莲藕汤
              赤豆桂花酒酿圆子 烧卖或椰丝奶黄包
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                Family Meal B 套餐B (8-10)
              </Components.DishName>
              <Components.AlaCartePrice>$230.72</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              卤牛腱 凉拌海带丝 凉拌干丝 南京什锦菜 南京烤鸭 豆瓣鲈鱼 油面筋塞肉
              牛蛙 烩三鲜 上汤时蔬 茄汁山药 排骨玉米莲藕汤 赤豆桂花酒酿圆子
              烧卖或椰丝奶黄包
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                Family Meal C 套餐C (5-6)
              </Components.DishName>

              <Components.AlaCartePrice>$159.63</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              卤牛腱 凉拌海带丝 南京什锦菜 豆瓣鲈鱼 雪菜毛豆炖肉圆 烩三鲜
              上汤时蔬 茄汁山药 排骨玉米莲藕汤 赤豆桂花酒酿圆子
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                Family Meal D 套餐D (5-6)
              </Components.DishName>

              <Components.AlaCartePrice>$159.63</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              卤牛腱 凉拌干丝 南京什锦菜 豆瓣鲈鱼 油面筋塞肉 烩三鲜 上汤时蔬
              茄汁山药 排骨玉米莲藕汤 赤豆桂花酒酿圆子
            </Components.DishDescription>
          </Components.AlaCarteItem>
        </Components.AlaCarteList>
        <Components.PreOrderButtonWithNotesContainer>
          {/* <span>Check meal details below</span> */}
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(linkLunch);
            }}
          >
            pre-order LNY eve lunch<br></br>除夕午餐
          </Components.PreOrderButton>
          {/* </Components.PreOrderButtonWithNotesContainer>
        <Components.PreOrderButtonWithNotesContainer> */}
          {/* <span>Check meal details below</span> */}
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(link);
            }}
          >
            pre-order LNY eve dinner<br></br>除夕晚餐
          </Components.PreOrderButton>
        </Components.PreOrderButtonWithNotesContainer>
      </Components.MealText>
    </Components.MenuContainer>
  );
};

export default SweetHome;
