import React, { ReactElement } from "react";
import { faTrash, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { LOGGING } from "..";
import {
  ActionBlack,
  ActionGrey,
  ActionTransparent,
  Centered,
  Beige,
  card,
  text12300,
  textTitle,
  TextTitle,
  centered,
  NavHeight,
  FormSection,
  CenteredByColumn,
  FormSectionBody,
  FormRow,
  stretchWidth,
} from "../Shared";
const DishSelectionItem = styled(FormRow)`
  > button {
    color: red;
    font-size: 20px;
  }
`;
const DishSelectionField = styled(FormRow)``;

const DishSelectionItemBody = styled(Centered)`
  flex-direction: column;
  background-color: ${Beige};
`;

const DishSelectionItemTitle = styled(Centered)`
  margin-bottom: 20px;
  > span {
    ${textTitle}
  }
`;

const DishSelectionTitle = styled(TextTitle)`
  padding: 20px 0px 0px 20px;
`;
const DishSelectionFields = styled(Centered)`
  width: 100%;
  /* ${card} */
  align-items: flex-start;
  > button {
    margin-right: 10px;
  }
  padding: 20px 0px 0px 20px;
  margin-bottom: 20px;
  flex-direction: column;
  > label {
    text-transform: uppercase;
    margin-right: 15px;
    /* background-color: yellow; */
    font-weight: 500;
  }
  > input {
    border: 1px solid black;
    padding-left: 10px;
    margin: 0px;
    margin-right: 20px;
  }
`;
const DishSelectionFieldsBody = styled(Centered)`
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  background-color: ${Beige};
`;

const SelectionFormContainer = styled(CenteredByColumn)`
  z-index: 100;
  position: absolute;
  top: ${NavHeight}px;
  background-color: white;
  padding: 40px;
  width: calc(100vw - 80px);
`;
const SelectionInfo = styled(FormSection)``;
const SelectionInfoBody = styled(FormSectionBody)``;
const Options = styled(FormSection)``;
const OptionsBody = styled(FormSectionBody)`
  > button {
    margin-top: 30px;
    > svg {
      margin-left: 10px;
    }
  }
`;
const Name = styled.input`
  ${stretchWidth}
`;
const NumberField = styled.input`
  width: 50px;
  flex-grow: 0;
  text-align: center;
`;
type EditSelectionFormProps = {
  selection: any;
  onAddSelectionItem: () => void;
  onEditSelectionItem: () => void;
  onDeleteSelectionItem: () => void;
  onEdit: () => void;
  onSave: () => void;
};

export const EditSelectionForm: React.FC<EditSelectionFormProps> = ({
  selection,
  onAddSelectionItem,
  onEditSelectionItem,
  onDeleteSelectionItem,
  onEdit,
  onSave,
}): ReactElement => {
  LOGGING &&
    console.log("EditSelectionForm rendering 1with selection:", selection);
  const { _id, name, items, minSelection, maxSelection } = selection;
  return (
    <SelectionFormContainer>
      <TextTitle>edit selection</TextTitle>
      <SelectionInfo>
        <TextTitle>Selection Info</TextTitle>
        <SelectionInfoBody>
          <DishSelectionField>
            <label>name</label>
            <Name
              value={selection.name}
              onChange={onEdit}
              type="string"
              name="name"
            />
          </DishSelectionField>
          <DishSelectionField>
            <label>max options</label>
            <NumberField
              value={maxSelection}
              onChange={onEdit}
              type="number"
              name="maxSelection"
              step={1}
            />
            <label>min options</label>
            <NumberField
              value={minSelection}
              onChange={onEdit}
              type="number"
              name="minSelection"
              step={1}
            />
          </DishSelectionField>
        </SelectionInfoBody>
      </SelectionInfo>
      <Options>
        <DishSelectionItemTitle>
          <span>options</span>
        </DishSelectionItemTitle>
        <OptionsBody>
          {items.map((item, itemIndex) => {
            LOGGING &&
              console.log("DishSelectionItem rendering with item:", item);
            return (
              <DishSelectionItem key={itemIndex}>
                <label>name</label>
                <Name
                  value={item.name}
                  onChange={onEditSelectionItem.bind(this, Number(itemIndex))}
                  type="string"
                  name="name"
                />
                <label>D</label>
                <NumberField
                  value={item.priceDoordashListed}
                  onChange={onEditSelectionItem.bind(this, Number(itemIndex))}
                  type="number"
                  name="priceDoordashListed"
                  step={0.01}
                />
                <label>F</label>
                <NumberField
                  value={item.price}
                  onChange={onEditSelectionItem.bind(this, Number(itemIndex))}
                  type="number"
                  name="price"
                  step={0.01}
                />
                <label>R</label>
                <NumberField
                  value={item.priceFoodieRestaurant}
                  onChange={onEditSelectionItem.bind(this, Number(itemIndex))}
                  type="number"
                  name="priceFoodieRestaurant"
                  step={0.01}
                />
                <label>is default choice</label>
                <input
                  checked={item.isDefaultChoice || false}
                  onChange={onEditSelectionItem.bind(this, Number(itemIndex))}
                  type="checkbox"
                  name="isDefaultChoice"
                />
                <label>out of stock</label>
                <input
                  checked={item.outOfStock || false}
                  onChange={onEditSelectionItem.bind(this, Number(itemIndex))}
                  type="checkbox"
                  name="outOfStock"
                />
                <button
                  onClick={onDeleteSelectionItem.bind(this, Number(itemIndex))}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </DishSelectionItem>
            );
          })}
          <ActionTransparent onClick={onAddSelectionItem}>
            <span>create option</span>
            <FontAwesomeIcon icon={faPlus} />
          </ActionTransparent>
        </OptionsBody>
      </Options>
      <ActionBlack onClick={onSave}>save</ActionBlack>
    </SelectionFormContainer>
  );
};
