import * as Components from ".";

const KeeWah = ({ onClick }) => {
  const link = "/meal/01-29-2025/lunch/kee-wah-bakery-";
  const image =
    "https://keewah.com/media/catalog/product/cache/484bd534df80d026834f8358e630e944/_/2/_2_15_1.jpg";
  // "https://keewah.us/cdn/shop/products/02.jpg?v=1704587995&width=600";
  return (
    <Components.MenuContainer>
      <Components.MealImage src={image} />
      <Components.MealText>
        <Components.RestaurantName>
          Kee Wah Bakery 奇華餅家
        </Components.RestaurantName>
        <Components.MenuName>
          賀年限定<br></br>Lunar New Year Limited Items
        </Components.MenuName>
        <Components.Notes>
          <Components.DeliveryTime>
            <Components.Bullet></Components.Bullet>
            Order ASAP, supply goes fast.
          </Components.DeliveryTime>
          <Components.Instructions>
            <Components.Bullet></Components.Bullet>
            All sales are final.
          </Components.Instructions>
        </Components.Notes>
        <Components.AlaCarteList>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>姜汁糖年糕</Components.DishName>
              <Components.AlaCartePrice>$23.98</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              Pudding with Ginger
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>椰汁糖年糕</Components.DishName>
              <Components.AlaCartePrice>$23.98</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              Pudding with Coconut
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>紫米红米核桃糖年糕</Components.DishName>
              <Components.AlaCartePrice>$25.08</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              Purple Red Rice Pudding with Walnut
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>麦芽糖年糕</Components.DishName>
              <Components.AlaCartePrice>$25.08</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              Maltitol Low Sugar Pudding
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>亚嬷糖年糕</Components.DishName>
              <Components.AlaCartePrice>$31.68</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              Chinese New Year Pudding
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>红枣糖年糕</Components.DishName>
              <Components.AlaCartePrice>$31.68</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              Pudding with Date
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>贺年全盒</Components.DishName>
              <Components.AlaCartePrice>$53.68</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              New Year Assorted Snacks Gift Set
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>精选迎春礼盒</Components.DishName>
              <Components.AlaCartePrice>$27.28</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              Spring Assorted Snacks Gift Set
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>熊猫蛇年仔装年糕礼盒</Components.DishName>
              <Components.AlaCartePrice>$27.28</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              Panda and Snake Pudding Gift Set
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>贺年熊猫小食礼盒</Components.DishName>
              <Components.AlaCartePrice>$21.78</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              New Year Assorted Panda Snack Gift Set
            </Components.DishDescription>
          </Components.AlaCarteItem>
        </Components.AlaCarteList>
        <Components.PreOrderButtonWithNotesContainer>
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(link);
            }}
          >
            pre-order LNY day lunch<br></br>春節午餐
          </Components.PreOrderButton>
        </Components.PreOrderButtonWithNotesContainer>
      </Components.MealText>
    </Components.MenuContainer>
  );
};

export default KeeWah;
