import React, { useEffect, useState } from "react";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { set } from "lodash";
import { useDispatch, useStore } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { TrackAdsEvent } from "src/api/tracker";
import { LOGGING } from "src/constants";
import {
  ContinueWithGoogle,
  ContinueWithFacebook,
} from "src/screens/shared/Auth";
import { checkUserEmail, signIn, signUp2 } from "src/store/actions";
import validator from "validator";
import { GetPassword } from "./GetPassword";
import { GetPhoneAndSignUp } from "./GetPhone";
import {
  Welcome,
  Separator,
  ErrorMessage,
  EmailContainer,
  AuthFieldInput,
  ButtonEmail,
  SignUpField,
  ButtonCreate,
  FormContainer,
  AnonymousActionsWrapper,
} from "./Shared";
import { Loading } from "..";

export const GetEmailNamePasswordPhoneAndSignUp = ({
  defaultEmail,
  next,
  parentError,
  setParentError,
  inviteCode = null,
  treatPath = null,
  creatingAccount,
  setCreatingAccount,
  source,
  fbclid,
}) => {
  const [fields, setFields] = useState({
    firstName: "",
    lastName: "",
    email: defaultEmail || "",
    password: "",
  });
  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [step, setStep] = useState(0); //0: get email,name,password, 1: get phone
  const [errorMessage, setErrorMessage] = useState(null);
  const onSetField = (fieldName: string, fieldValue: string) => {
    LOGGING && console.log("onSetField called:", { fieldName, fieldValue });
    setFields({ ...fields, [fieldName]: fieldValue });
  };
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const onSubmit = async (updatedFields) => {
    const result = await dispatch(checkUserEmail(updatedFields.email));
    if (result) {
      setParentError(
        `Email already registered${
          //@ts-ignore
          result === "google" ? " through Google" : ""
        }`
      );
    } else {
      // setStep(step + 1);
      setFields(updatedFields);
      // dispatch(signUp(updatedFields));
      console.log("signUp2 called with:", updatedFields);
      setCreatingAccount(true);
      signUp2(updatedFields)(dispatch, store.getState)
        .then((result) => {
          console.log("signUp2 result", result);
          setCreatingAccount(false);
          console.log("signUp2 next:", next);
          if (result) {
            if (next) {
              history.push({
                pathname: next,
                state: { treatPath },
              });
              // history.push(next);
            } else {
              history.push("/");
            }
          } else {
            setErrorMessage("Error creating account");
          }
        })
        .catch((e) => {
          console.log("signUp2 got error", e);
          setErrorMessage(e.message);
        });
    }
  };
  LOGGING &&
    console.log("GetEmailNamePasswordPhoneAndSignUp rendering with:", {
      defaultEmail,
      next,
      parentError,
      setParentError,
      inviteCode,
      treatPath,
      source,
      fbclid,
    });
  const verifyInputs = () => {
    const { firstName, lastName, email, password } = fields;
    LOGGING && console.log("verifyInputs called with fields:", fields);
    // setError({firstName: "", lastName: "", email: "", password: ""});
    let newError = { firstName: "", lastName: "", email: "", password: "" };
    let allGood = true;
    if (firstName === "") {
      newError.firstName = "First name can not be empty.";
      allGood = false;
    }
    if (lastName === "") {
      newError.lastName = "Last name can not be empty.";
      allGood = false;
    }
    if (email === "") {
      newError.email = "Email can not be empty.";
      allGood = false;
    } else if (!validator.isEmail(email)) {
      newError.email = "Please provide a valid email";
      allGood = false;
    }
    if (password === "") {
      newError.password = "Password can not be empty.";
      allGood = false;
    }
    setError(newError);
    return allGood;
  };
  return (
    <AnonymousActionsWrapper small={false}>
      {errorMessage?.length > 0 ? (
        <ErrorMessage>
          <FontAwesomeIcon icon={faExclamationCircle} />
          <span> {errorMessage}</span>
        </ErrorMessage>
      ) : null}
      {step === 0 ? (
        <FormContainer>
          <SignUpField
            fieldName="first name"
            fieldValue={fields.firstName}
            onChange={onSetField.bind(this, "firstName")}
            errorMessage={error?.firstName}
            onSetParentError={parentError ? setParentError : null}
          />
          <SignUpField
            fieldName="last name"
            fieldValue={fields.lastName}
            errorMessage={error?.lastName}
            onChange={onSetField.bind(this, "lastName")}
            onSetParentError={parentError ? setParentError : null}
          />
          <SignUpField
            fieldName="email"
            fieldValue={fields.email}
            errorMessage={error?.email}
            onChange={onSetField.bind(this, "email")}
            fieldValidator={validator.isEmail.bind(this)}
            onSetParentError={parentError ? setParentError : null}
          />
          <SignUpField
            fieldName="password"
            fieldValue={fields.password}
            errorMessage={error?.password}
            onChange={onSetField.bind(this, "password")}
            onSetParentError={parentError ? setParentError : null}
            isPassword={true}
          />
          <ButtonCreate
            disabled={creatingAccount}
            onClick={(e) => {
              e.preventDefault();
              if (verifyInputs()) {
                // Insert Facebook Pixel tracking here
                // @ts-ignore
                if (window.fbq && fbclid) {
                  LOGGING && console.log("sign up by email is tracked by fbq");
                  // @ts-ignore
                  window.fbq("track", "CompleteRegistration", {
                    content_name: `sign up by email`,
                    content_category: "pageAuth",
                    value: 0,
                    currency: "USD",
                  });

                  // Track Ads Event
                  TrackAdsEvent(fbclid, {
                    source,
                    eventCode: "CompleteRegistration",
                    page: "pageAuth",
                    contentName: "signUpByEmail",
                  });
                }

                setCreatingAccount(true);
                onSubmit(
                  inviteCode
                    ? { ...fields, socialInviteCode: inviteCode }
                    : fields
                );
              }
            }}
          >
            {creatingAccount ? "creating account..." : "create account"}
          </ButtonCreate>
        </FormContainer>
      ) : (
        <GetPhoneAndSignUp
          user={
            inviteCode ? { ...fields, socialInviteCode: inviteCode } : fields
          }
          setParentError={setErrorMessage}
          next={next}
          treatPath={treatPath}
        />
      )}
    </AnonymousActionsWrapper>
  );
};
const GetEmail = ({
  defaultEmail,
  onSendEmail,
  resetModalError,
  checkEmail,
  setCheckEmail,
}) => {
  const [email, setEmail] = useState(defaultEmail || "");
  const [errorMessage, setErrorMessage] = useState("");
  const [invalid, setInvalid] = useState(false);
  const changeEmail = (e) => {
    const value = e.target.value;
    setEmail(value);
    setInvalid(value.length > 0 && !validator.isEmail(value));
    if (errorMessage.length > 0) {
      setErrorMessage("");
    }
    if (resetModalError) {
      resetModalError();
    }
  };
  return (
    <EmailContainer>
      <AuthFieldInput
        placeholder="Enter email"
        defaultValue={email}
        onChange={changeEmail}
        name="email"
        valid={!invalid}
        hasErrorMessage={errorMessage?.length > 0}
      />
      {errorMessage?.length > 0 ? (
        <ErrorMessage>
          <FontAwesomeIcon icon={faExclamationCircle} />
          <span> {errorMessage}</span>
        </ErrorMessage>
      ) : null}
      <ButtonEmail
        disabled={checkEmail || invalid}
        onClick={(e) => {
          e.preventDefault();
          LOGGING && console.log("GetEmail onClick called with:", email);
          if (invalid) {
            setErrorMessage("Enter a valid email.");
          } else {
            setCheckEmail(true);
            onSendEmail(email);
          }
        }}
      >
        {`${checkEmail ? "checking..." : "continue"}`}
      </ButtonEmail>
    </EmailContainer>
  );
};
export const GetEmailOrGoogle = ({
  defaultEmail,
  allowSignUp,
  allowLogin,
  parentError,
  setParentError,
  next,
  inviter,
  setParentTitle,
  inviteCode,
  small,
  treatPath,
  source,
  fbclid,
}) => {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState(defaultEmail);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [getPhone, setGetPhone] = useState(false);
  const [getNamePassword, setGetNamePassword] = useState(false);
  const [getPassword, setGetPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [creatingAccount, setCreatingAccount] = useState(false);
  useEffect(() => {
    console.log("GetEmailOrGoogle rendering with:", {
      getPassword,
      checkEmail,
      creatingAccount,
    });
  }, [getPassword]);
  const onContinueWithEmail = async (updatedEmail) => {
    const valid = validator.isEmail(updatedEmail);
    if (valid) {
      setErrorMessage(null);
    } else {
      setErrorMessage("Enter a valid email");
    }
    setEmail(updatedEmail);
    try {
      const result = await dispatch(checkUserEmail(updatedEmail));
      if (result) {
        if (allowLogin) {
          setGetPassword(true);
          if (setParentTitle) {
            setParentTitle("Log In");
          }
        } else {
          setParentError(`Email already registered by ${result}`);
        }
      } else {
        if (allowSignUp) {
          setGetNamePassword(true);
          if (setParentTitle) {
            setParentTitle("Finish Signing Up");
          }
        } else {
          setParentError("Email is not registered");
        }
      }
    } catch (error) {
      setParentError(error?.message || "server error");
    }
  };
  const onGoogleReady = () => {
    LOGGING && console.log("onGoogleReady called");
  };
  const onGoogleSuccess = async (response) => {
    LOGGING && console.log("handleGoogleSuccess response:", response);
    const {
      email,
      givenName: firstName,
      familyName: lastName,
      googleId,
      imageUrl,
    } = response.profileObj;
    LOGGING &&
      console.log("handleGoogleSuccess got user name:", {
        firstName,
        lastName,
      });

    // Insert Facebook Pixel tracking here
    // @ts-ignore
    if (window.fbq && fbclid) {
      LOGGING && console.log("sign up by google is tracked by fbq");
      // @ts-ignore
      window.fbq("track", "CompleteRegistration", {
        content_name: `sign up by google`,
        content_category: "pageAuth",
        value: 0,
        currency: "USD",
      });

      // Track Ads Event
      TrackAdsEvent(fbclid, {
        source,
        eventCode: "CompleteRegistration",
        page: "pageAuth",
        contentName: "signUpByGoogle",
      });
    }

    setLoading(true);
    const result = await dispatch(checkUserEmail(email));
    LOGGING &&
      console.log("onGoogleSuccess checkUserEmail got result:", result);
    try {
      if (result) {
        if (allowLogin) {
          await dispatch(
            signIn({ email, googleId, profilePictureURL: imageUrl })
          );
          setLoading(false);
          if (next) {
            history.push({
              pathname: next,
              state: { treatPath },
            });
            // history.push(next);
          } else {
            history.push("/");
          }
        } else {
          setLoading(false);
          setParentError("Email already registered through google");
        }
      } else {
        LOGGING &&
          console.log("handleGoogleSuccess got NULL from checkUserEmail", {
            allowSignUp,
          });
        if (allowSignUp) {
          setParentError("");
          setCreatingAccount(true);
          signUp2({
            email,
            googleId,
            profilePictureURL: imageUrl,
            firstName,
            lastName,
            socialInviteCode: inviteCode,
          })(dispatch, store.getState)
            .then((result) => {
              console.log("handleGoogleSuccess signUp2 result", result);
              setCreatingAccount(false);
              if (result) {
                if (next) {
                  history.push({
                    pathname: next,
                    state: { treatPath },
                  });
                  // history.push(next);
                } else {
                  history.push("/");
                }
              } else {
                setErrorMessage("handleGoogleSuccess Error creating account");
                setCreatingAccount(false);
              }
            })
            .catch((e) => {
              console.log("handleGoogleSuccess signUp2 got error", e);
              setErrorMessage(e.message);
              setCreatingAccount(false);
            });
          // setLoading(false);
          // setGetPhone(true);
          // if (setParentTitle) {
          //   setParentTitle("Finish Signing Up");
          // }
        } else {
          setLoading(false);
          setParentError("Email is not registered");
        }
      }
    } catch (error) {
      setParentError(error.message);
    }
  };
  const onGoogleFailure = (error) => {
    LOGGING && console.log("onGoogleFailure got error:", error);
    setParentError("Google auth failed.");
  };

  const onFacebookSuccess = async (user) => {
    LOGGING && console.log("onFacebookSuccess response:", user);
    const {
      email,
      first_name: firstName,
      last_name: lastName,
      id: facebookId,
    } = user;
    const imageUrl = user?.picture?.data?.url;

    LOGGING &&
      console.log("onFacebookSuccess got user name:", {
        firstName,
        lastName,
        imageUrl,
        email,
        facebookId,
      });

    // Insert Facebook Pixel tracking here
    // @ts-ignore
    if (window.fbq && fbclid) {
      LOGGING && console.log("sign up by facebook is tracked by fbq");
      // @ts-ignore
      window.fbq("track", "CompleteRegistration", {
        content_name: `sign up by facebook`,
        content_category: "pageAuth",
        value: 0,
        currency: "USD",
      });

      // Track Ads Event
      TrackAdsEvent(fbclid, {
        source,
        eventCode: "CompleteRegistration",
        page: "pageAuth",
        contentName: "signUpByFacebook",
      });
    }

    setLoading(true);
    const result = await dispatch(checkUserEmail(email));
    LOGGING &&
      console.log("onFacebookSuccess checkUserEmail got result:", result);
    try {
      if (result) {
        if (allowLogin) {
          await dispatch(
            signIn({ email, facebookId, profilePictureURL: imageUrl })
          );
          setLoading(false);
          if (next) {
            history.push({
              pathname: next,
              state: { treatPath },
            });
            // history.push(next);
          } else {
            history.push("/");
          }
        } else {
          setLoading(false);
          setParentError("Email already registered through fb");
        }
      } else {
        LOGGING &&
          console.log("onFacebookSuccess got NULL from checkUserEmail", {
            allowSignUp,
          });
        if (allowSignUp) {
          setParentError("");
          setCreatingAccount(true);
          signUp2({
            email,
            facebookId,
            profilePictureURL: imageUrl,
            firstName,
            lastName,
            socialInviteCode: inviteCode,
          })(dispatch, store.getState)
            .then((result) => {
              console.log("onFacebookSuccess signUp2 result", result);
              setCreatingAccount(false);
              if (result) {
                if (next) {
                  history.push({
                    pathname: next,
                    state: { treatPath },
                  });
                  // history.push(next);
                } else {
                  history.push("/");
                }
              } else {
                setCreatingAccount(false);
                setErrorMessage("facebook has Error creating account");
              }
            })
            .catch((e) => {
              console.log("onFacebookSuccess signUp2 got error", e);
              setErrorMessage(e.message);
              setCreatingAccount(false);
            });
          // setLoading(false);
          // setGetPhone(true);
          // if (setParentTitle) {
          //   setParentTitle("Finish Signing Up");
          // }
        } else {
          setLoading(false);
          setParentError("Email is not registered");
        }
      }
    } catch (error) {
      setParentError(error.message);
    }
  };
  const onFacebookFailure = (error) => {
    LOGGING && console.log("onFacebookFailure got error:", error);
    setParentError("facebook auth failed.");
  };

  if (getPhone)
    return (
      <GetPhoneAndSignUp
        next={next}
        user={user}
        setParentError={setParentError}
      />
    );
  if (getNamePassword)
    return (
      <GetEmailNamePasswordPhoneAndSignUp
        next={next}
        inviteCode={inviteCode}
        defaultEmail={email}
        parentError={parentError}
        setParentError={setParentError}
        treatPath={treatPath}
        creatingAccount={creatingAccount}
        setCreatingAccount={setCreatingAccount}
        source={source}
        fbclid={fbclid}
      />
    );
  if (getPassword)
    return (
      <AnonymousActionsWrapper>
        <Welcome inviter={inviter} getPassword={getPassword} />
        <GetPassword
          email={email}
          parentError={parentError}
          onSetParentError={setParentError}
          next={next}
        />
      </AnonymousActionsWrapper>
    );

  return (
    <AnonymousActionsWrapper small={small}>
      <Welcome inviter={inviter} getPassword={getPassword} loading={loading} />
      {loading ? null : (
        <>
          <GetEmail
            resetModalError={setParentError.bind(this, "")}
            defaultEmail={defaultEmail}
            onSendEmail={onContinueWithEmail}
            checkEmail={checkEmail}
            setCheckEmail={setCheckEmail}
          />
          <Separator />
          <ContinueWithGoogle
            onSuccess={onGoogleSuccess}
            onFailure={onGoogleFailure}
            onReady={onGoogleReady}
            textButton="Continue with google"
          />
          <ContinueWithFacebook
            onSuccess={onFacebookSuccess}
            onFailure={onFacebookFailure}
            textButton="Continue with facebook"
          />
        </>
      )}
    </AnonymousActionsWrapper>
  );
};
