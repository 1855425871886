import styled from "styled-components";
import * as Components from ".";
import { text12300 } from "../Shared";
const Left = styled.span`
  ${text12300}
`;
const HunanHouse = ({ onClick }) => {
  const link = "/meal/01-29-2025/dinner/hunan-house-";
  const linkLunch = "/meal/01-29-2025/lunch/hunan-house-";
  const image =
    "https://i.pinimg.com/1200x/ec/22/c4/ec22c41a7b7421dfd9755e8cebe37a6b.jpg";
  return (
    <Components.MenuContainer>
      <Components.MealImage src={image} />
      <Components.MealText>
        <Components.RestaurantName>
          Hunan House 一屋饭湘
        </Components.RestaurantName>
        <Components.MenuName>
          蛇贺新年春节套餐<br></br>New Year Family Meal
        </Components.MenuName>
        <Components.Notes>
          <Components.DeliveryTime>
            <Components.Bullet></Components.Bullet>
            Order by&nbsp;<b>Monday Jan 27</b>.
          </Components.DeliveryTime>
          <Components.Instructions>
            {/* <FontAwesomeIcon icon={faHeat} /> */}
            <Components.Bullet></Components.Bullet>
            All sales are final.
          </Components.Instructions>
        </Components.Notes>
        <Components.AlaCarteList>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>
                春节套餐 LNY Specials (8-10)
              </Components.DishName>
              <Components.AlaCartePrice>$288</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              开胃前菜：🌶️热拌腐竹，🌶️热拌脆贡菜。
            </Components.DishDescription>
            <Components.DishDescription>
              特色主菜：🌶️大蒜叶炒腊牛肉，🌶️湘味羊肉炉，🌶️招牌香辣烤鱼，全家福，胡椒猪肚鸡汤，白灼生菜，潇湘油卤鸭，梅菜扣肉。
            </Components.DishDescription>
            <Components.DishDescription>
              精致甜品：酒酿汤圆，红糖糍粑
            </Components.DishDescription>
          </Components.AlaCarteItem>
        </Components.AlaCarteList>
        <Components.PreOrderButtonWithNotesContainer>
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(linkLunch);
            }}
          >
            pre-order LNY lunch<br></br>春节午餐
          </Components.PreOrderButton>
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(link);
            }}
          >
            pre-order LNY dinner<br></br>春节晚餐
          </Components.PreOrderButton>
        </Components.PreOrderButtonWithNotesContainer>
      </Components.MealText>
    </Components.MenuContainer>
  );
};

export default HunanHouse;
