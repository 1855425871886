import React from "react";
import styled from "styled-components";
import { text12300 } from "../Shared";

interface RestaurantCountWithProgressProps {
  numberHasTried: number;
  total: number; // Adjust the type based on your actual data structure
  firstName: string;
}

const Container = styled.div`
  padding: 16px;
  font-family: Arial, sans-serif;
`;

const Text = styled.div`
  ${text12300}
  margin-bottom: 8px;
`;

const ProgressBarContainer = styled.div`
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 50px;
  overflow: hidden;
`;

const ProgressBar = styled.div<{ progress: number }>`
  height: 100%;
  background-color: green;
  width: ${(props) => props.progress}%;
  transition: width 0.3s ease-in-out;
  /* border-radius: 4px; */
`;

const RestaurantCountWithProgress: React.FC<RestaurantCountWithProgressProps> =
  ({ numberHasTried, total, firstName }) => {
    const progress = total > 0 ? (numberHasTried / total) * 100 : 0;

    return (
      <Container>
        <Text>{`${numberHasTried} of the ${total} restaurants tried by ${firstName}.`}</Text>
        <ProgressBarContainer>
          <ProgressBar
            progress={progress}
            aria-valuenow={progress}
            aria-valuemin={0}
            aria-valuemax={100}
          />
        </ProgressBarContainer>
      </Container>
    );
  };

export default RestaurantCountWithProgress;
