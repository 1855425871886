import { useHistory } from "react-router-dom";
import { ButtonWrapper, NextAction, ButtonSignUp } from "./Shared";
import AppStoreLink from "../customers/banners/AppStoreLink";
export const AuthEntry = ({ next, title, newUserOnly }) => {
  const history = useHistory();
  const handleButtonClick = () => {
    // You can perform any logic with 'next' before navigating
    const nextState = next ? { next, newUserOnly } : null;

    // Navigate to the "/login" page with 'next' in location.state
    history.push({
      pathname: "/auth",
      state: nextState,
    });
  };

  return (
    <ButtonWrapper>
      {title ? <NextAction action={title} /> : null}
      <ButtonSignUp onClick={handleButtonClick}>
        <span>{newUserOnly ? "Sign Up" : "Log In/Sign Up"}</span>
      </ButtonSignUp>
      <AppStoreLink />
    </ButtonWrapper>
  );
};
