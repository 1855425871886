import React from "react";
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faCcDiscover,
  faCcDinersClub,
  faCcJcb,
} from "@fortawesome/free-brands-svg-icons";
import { faLoveseat } from "@fortawesome/pro-regular-svg-icons";
import moment from "moment-timezone";
import { kCritialHourMinute } from "../util/time";
export const MaxNumFriends = 10;
export const MinNumOrders = 1;
export const DedupClickMiliSeconds = 50;
export const CATimeZone = "America/Los_Angeles";
export const ReferralSlogan = "Invite 10 friends, earn $100.";
export const ReferralCodeSlogan = "Share $5, Earn $5!";
export const ReferralCodeSlogan1 = "Share $5, Earn $5!";
export const ReferralCodeSlogan2 = "Earn as much as you can.";
export const MinSpendForReferralCode = 20;
export const NonMemberServiceFee = 3;
export const NonMemberExpressServiceFee = 5;
export const MemberServiceFee = 1;
export const MemberExpressServiceFee = 3;
export const FreeTrialDays = process.env.REACT_APP_TRIAL_DAYS;
export const MembershipCost = process.env.REACT_APP_MEMBERSHIP_COST;
export const WindowStartsByMealType = {
  lunch: {
    hour: 11,
    minute: 30,
  },
  earlyDinner: {
    hour: 16,
    minute: 0,
  },
  dinner: {
    hour: 18,
    minute: 0,
  },
};

export const CreditCardIcons = {
  visa: faCcVisa,
  mastercard: faCcMastercard,
  amex: faCcAmex,
  "american-express": faCcAmex,
  discover: faCcDiscover,
  "diners-club": faCcDinersClub,
  jcb: faCcJcb,
};

export const GiftCategoryName = {
  lunar: "lunar new year",
  thank: "appreciation",
  getwell: "get well",
};

export const PageSize = 3;
export const PageRefreshMinutes = 120;
export const receiptTimeFormat = "ddd, MMM D, YYYY h:mmA";
export const receiptDateFormat = "ddd, MMM Do, YYYY";
export const MenuDateFormat = "ddd, M/D";
export const MenuDateFormatLong = "dddd, MMM Do";
export const receiptDateFormatShort = "ddd M/D";
export const NavByDayFormat = "ddd M/D";
export const receiptHourFormat = "HH:mmA";
export const receiptHourFormatHourOnly = "hA";
export const receiptHourFormatShort = "h:mmA";
export const receiptHourFormatSuperShort = "h";

export const Discounts = {
  MultiMeal: 0.1,
  FixedWindow: 0.1,
  FixedSelection: 0.1,
};
export const RestaurantLevel = {
  Admitted: 2,
  InTrial: 1,
  Disabled: 0,
};
export const OrderMethods = {
  0: "on restaurant website",
  1: "by email",
  2: "in store",
  3: "by foodie link",
  4: "by phone",
};
export const PaymentMethods = {
  0: "on restaurant website",
  1: "by email",
  2: "in store",
  3: "by venmo",
  4: "by phone",
  5: "by zelle",
  6: "by cash",
  7: "CC on file",
};

export const RestaurantLevels = {
  0: "deprecated",
  1: "in-trial",
  2: "admitted",
};

export const DayOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const BookmarkStatus = {
  CANCELLED: 0,
  BOOKMARKED: 1,
  REVIEWED: 2,
  LIKED: 3,
};
export const AuthMode = {
  BOTH: 0,
  SIGNIN: 1,
  SIGNUP: 2,
};
export const CATEGORY_OFFSET = -150;
export const DishesToCategories = (dishes: any) => {
  let categories = {};
  new Set(dishes.map((d) => d.categoryName || "others")).forEach(
    (c: string) => {
      // categories[c] = false;
      categories[c] = {
        refMobile: React.createRef(),
        refDesktop: React.createRef(),
        refNav: React.createRef(),
        count: dishes.filter((d: any) => (d.categoryName || "others") === c)
          .length,
      };
    }
  );
  // LOGGING && console.log("DishesToCategories got categories:", categories);
  return categories;
};

export const MealRefs = (meals: any) => {
  let result = {};
  meals.forEach((m: any) => {
    result[m._id] = React.createRef();
  });
  return result;
};

export const orderBy = (windowStart) => {
  const hour = moment(windowStart).hour();
  const minutes = hour === 11 ? 60 : hour === 16 ? 30 : 90;
  return moment(windowStart).add(-minutes, "m").valueOf();
};
export const PriceDisclaimer = "Original prices, no add-on fees."; //'All prices listed are restaurant prices.';
export const BookmarkStatusDisplayName = {
  CANCELLED: "cancelled",
  BOOKMARKED: "to try",
  LIKED: "liked",
  REVIEWED: "disliked",
};
export const GetMealCutOff = (meal) => {
  const hour = moment(meal.windowStart).hour();
  const minutes = hour === 11 ? 60 : hour === 16 ? 30 : 90;
  return moment(meal.windowStart).add(-minutes, "m").valueOf();
};
export const GetMealType = (meal) => {
  const hour = moment(meal?.windowStart).hour();
  return hour === 11 ? "lunch" : hour === 16 ? "early dinner" : "dinner";
};
export const GetMealLink = (meal) => {
  const restaurantName = meal?.restaurant?.name
    .trim()
    .replace(/[\W_]+/g, " ")
    .toLowerCase()
    .split(" ")
    .join("-");
  const hour = moment(meal?.windowStart).hour();

  const date = moment(meal?.windowStart)
    .tz("America/Los_Angeles")
    .format("MM-DD-YYYY");
  const mealType =
    hour === 11 ? "lunch" : hour === 16 ? "express-dinner" : "dinner";
  return `meal/${date}/${mealType}/${restaurantName}`;
};
export const CompetitorPlatforms = ["doordash", "ubereats"];
export const CompetitorMarkupTypes = ["percent", "dollar"];
export const NumberWord = ["zero", "one", "two", "three", "four", "five"];
export const TastingMenuName = {
  0: "menu a",
  1: "menu b",
  2: "menu c",
};
export const CityShortName = {
  "Palo Alto": "PA",
  "East Palo Alto": "EPA",
  "Los Altos": "LA",
  "Lost Altos Hills": "LAH",
  "Los Gatos": "LG",
  Milpitas: "ML",
  Stanford: "ST",
  "Mountain View": "MV",
  Sunnyvale: "SV",
  "Santa Clara": "SC",
  Cupertino: "CU",
  "Menlo Park": "MP",
  Saratoga: "SA",
  "San Jose": "SJ",
  Campbell: "CB",
  "Monte Sereno": "MS",
  Atherton: "AT",
};
export const BookmarkStatusActionName = {
  1: "bookmarked",
  2: "liked",
  3: "disliked",
};
export const LOGGING = process.env.NODE_ENV === "development";
export const NewCard = {
  number: "",
  cvc: "",
  exp_month: "",
  exp_year: "",
};

export const TOKEN_KEY = "jwtToken";

export const sortBookmarks = (bookmarks) => {
  const bookmarked = bookmarks.filter(
    (b) => b.status === BookmarkStatus.BOOKMARKED
  );
  const reviewed = bookmarks.filter(
    (b) => b.status === BookmarkStatus.REVIEWED
  );
  const liked = bookmarks.filter((b) => b.status === BookmarkStatus.LIKED);

  return {
    [BookmarkStatus.BOOKMARKED]: bookmarked,
    [BookmarkStatus.REVIEWED]: reviewed,
    [BookmarkStatus.LIKED]: liked,
  };
};
export const getItemNumberInCart = (cart) => {
  const { goods } = cart;
  if (!goods) return 0;
  const numberOfItems = Object.values(goods).reduce(
    (a, b: any) => a + b.quantity,
    0
  );

  return numberOfItems;
};
export const zeroPad = (num, places) => String(num).padStart(places, "0");
export const alphabet = ["a", "b", "c", "d"];
export const DefaultTimeZone = "America/Los_Angeles";
export const numbersText = ["zero", "one", "two", "three", "four"];
export const getWeek = (windowStart) => {
  const weekStart = moment(windowStart)
    .tz(DefaultTimeZone)
    .startOf("w")
    .format("ddd, MMM Do");
  const weekEnd = moment(windowStart)
    .tz(DefaultTimeZone)
    .endOf("w")
    .format("ddd, MMM Do");
  return `${weekStart} - ${weekEnd}`;
};
export const CommissionNames = [
  "commissionBefore",
  "commission",
  "commissionAfter",
];
export const CommissionStyleNames = ["original", "now", "target"];
export const getMonth = (firstDayOfMonth, now) => {
  const lastDayOfMonth = firstDayOfMonth.clone().endOf("month");
  const firstDayOfCalendar = firstDayOfMonth.clone().startOf("week");
  const lastDayOfCalendar = lastDayOfMonth.clone().endOf("week");
  LOGGING && console.log("lastDayOfMonth:", lastDayOfMonth.format("LLLL"));
  LOGGING &&
    console.log("firstDayOfCalendar:", firstDayOfCalendar.format("LLLL"));
  const week = [0, 1, 2, 3, 4, 5, 6];
  let month = [];
  const currentMonth = firstDayOfMonth.format("MMMM YYYY");
  for (
    const firstOfWeek = firstDayOfCalendar.clone();
    firstOfWeek.isSameOrBefore(lastDayOfMonth);
    firstOfWeek.add(1, "w")
  ) {
    const thisWeek = week.map((dayOfWeek) => {
      const date = firstOfWeek.clone().add(dayOfWeek, "d");
      const isToday = date.isSame(now, "d");
      const isPast = date.isBefore(now, "d");
      const isShown = date.diff(now, "d") < 6;
      const lunchWindowStart = date
        .clone()
        .hour(kCritialHourMinute.lunch.deliveryStart[0])
        .minute(kCritialHourMinute.lunch.deliveryStart[1])
        .valueOf();
      const earlyDinnerWindowStart = date
        .clone()
        .hour(kCritialHourMinute.earlyDinner.deliveryStart[0])
        .minute(kCritialHourMinute.earlyDinner.deliveryStart[1])
        .valueOf();
      const dinnerWindowStart = date
        .clone()
        .hour(kCritialHourMinute.dinner.deliveryStart[0])
        .minute(kCritialHourMinute.dinner.deliveryStart[1])
        .valueOf();
      return {
        date,
        isToday,
        isPast,
        lunchWindowStart,
        earlyDinnerWindowStart,
        dinnerWindowStart,
        isShown,
        week: getWeek(lunchWindowStart),
      };
    });
    month.push(thisWeek);
  }
  return { firstDayOfCalendar, lastDayOfCalendar, month, currentMonth };
};

// Deprecated by util/time:getNextWindow
export const getNextDeliveryTime = () => {
  const now = moment().add(-4, "h");
  const hour = now.clone().hour(),
    minute = now.clone().minute();
  // LOGGING && console.log('getNextDeliveryTime get:', { hour, minute });
  let orderTime, start, end;
  if (
    hour < 10 ||
    (hour === 10 && minute < 30) ||
    hour >= 17 ||
    (hour === 16 && minute >= 30)
  ) {
    orderTime = moment()
      .hour(10)
      .minute(30)
      .second(0)
      .millisecond(0)
      .add(hour < 10 || (hour === 10 && minute < 30) ? 0 : 1, "d");
    start = orderTime.clone().add(60, "m"); // 11:45
    end = start.clone().add(90, "m"); // 1:00
  } else {
    orderTime = moment().hour(16).minute(30).second(0).millisecond(0);
    start = orderTime.clone().add(90, "m"); // 18:00
    end = start.clone().add(90, "m"); // 19:30
  }
  const secondsUntilExpire = orderTime.diff(now, "second");
  let windows: any = {};
  windows.default = { start: start.valueOf(), end: end.valueOf(), cost: 0 };
  return {
    orderTime,
    windows,
    secondsUntilExpire,
  };
};

export const getCurrentDayDeliveryWindows = (nowUTC) => {
  // LOGGING && console.log('getCurrentDayDeliveryTime got nowUTC:', nowUTC);
  const now = nowUTC ? moment(Number(nowUTC)) : moment();
  // LOGGING && console.log('getCurrentDayDeliveryTime got now:', now);
  const hour = now.hour(),
    minute = now.minute();
  // LOGGING && console.log('getCurrentDayDeliveryTime got hour:', hour, now.format('LLLL'));
  let orderTime;
  // let orderTime, start, end;
  if (
    hour < 10 ||
    (hour === 10 && minute < 30) ||
    hour >= 17 ||
    (hour === 16 && minute >= 30)
  ) {
    orderTime = now
      .clone()
      .hour(10)
      .minute(30)
      .second(0)
      .millisecond(0)
      .add(hour < 10 || (hour === 10 && minute < 30) ? 0 : 1, "d");
    // start = orderTime.clone().add(60, 'm');
    // end = start.clone().add(90, 'm');
    // LOGGING &&
    //   console.log('case 1: getCurrentDayDeliveryWindows got:', {
    //     order: orderTime.format('LLLL'),
    //     start,
    //     end,
    //   });
  } else {
    orderTime = now.clone().hour(16).minute(30).second(0).millisecond(0);
    // start = orderTime.clone().add(90, 'm');
    // end = start.clone().add(90, 'm');
    // LOGGING &&
    //   console.log('case 2: getCurrentDayDeliveryWindows got:', {
    //     order: orderTime.format('LLLL'),
    //     start,
    //     end,
    //   });
  }
  // LOGGING && console.log('getCurrentDayDeliveryWindows got orderTime:', orderTime.format('LLLL'));

  //kCritialHourMinute
  // orderCutOff: [10, 30],
  //   restaurantReady: [11, 0],
  //   driverPickup: [11, 15],
  //   deliveryStart: [11, 30],
  //   deliveryEnd: [12, 30],
  //   deliveryEndWithDelay: [13, 0],
  const lunchOrderTime = moment(orderTime)
    .hour(kCritialHourMinute.lunch.orderCutOff[0])
    .minute(kCritialHourMinute.lunch.orderCutOff[1])
    .second(0)
    .millisecond(0);
  const lunchStart = moment(orderTime)
    .hour(kCritialHourMinute.lunch.deliveryStart[0])
    .minute(kCritialHourMinute.lunch.deliveryStart[1])
    .second(0)
    .millisecond(0);
  const lunchEnd = moment(orderTime)
    .hour(kCritialHourMinute.lunch.deliveryEnd[0])
    .minute(kCritialHourMinute.lunch.deliveryEnd[1])
    .second(0)
    .millisecond(0);

  const earlyDinnerOrderTime = moment(orderTime)
    .hour(kCritialHourMinute.earlyDinner.orderCutOff[0])
    .minute(kCritialHourMinute.earlyDinner.orderCutOff[1])
    .second(0)
    .millisecond(0);
  const earlyDinnerStart = moment(orderTime)
    .hour(kCritialHourMinute.earlyDinner.deliveryStart[0])
    .minute(kCritialHourMinute.earlyDinner.deliveryStart[1])
    .second(0)
    .millisecond(0);
  const earlyDinnerEnd = moment(orderTime)
    .hour(kCritialHourMinute.earlyDinner.deliveryEnd[0])
    .minute(kCritialHourMinute.earlyDinner.deliveryEnd[1])
    .second(0)
    .millisecond(0);

  const dinnerOrderTime = moment(orderTime)
    .hour(kCritialHourMinute.dinner.orderCutOff[0])
    .minute(kCritialHourMinute.dinner.orderCutOff[1])
    .second(0)
    .millisecond(0);
  const dinnerStart = moment(orderTime)
    .hour(kCritialHourMinute.dinner.deliveryStart[0])
    .minute(kCritialHourMinute.dinner.deliveryStart[1])
    .second(0)
    .millisecond(0);
  const dinnerEnd = moment(orderTime)
    .hour(kCritialHourMinute.dinner.deliveryEnd[0])
    .minute(kCritialHourMinute.dinner.deliveryEnd[1])
    .second(0)
    .millisecond(0);

  const windows = {
    lunch: {
      default: {
        start: lunchStart.valueOf(),
        end: lunchEnd.valueOf(),
        by: lunchOrderTime.valueOf(),
        cost: 0,
      },
    },
    earlyDinner: {
      default: {
        start: earlyDinnerStart.valueOf(),
        end: earlyDinnerEnd.valueOf(),
        by: earlyDinnerOrderTime.valueOf(),
        cost: 0,
      },
    },
    dinner: {
      default: {
        start: dinnerStart.valueOf(),
        end: dinnerEnd.valueOf(),
        by: dinnerOrderTime.valueOf(),
        cost: 0,
      },
    },
  };
  // LOGGING && console.log('getCurrentDayDeliveryWindows got windows:', windows);
  return {
    orderTime,
    windows,
  };
};

export const getWeeklyMealWindows = (viewableDays) => {
  LOGGING &&
    console.log("getWeeklyMealWindows got viewableDays:", viewableDays);
  let result = [];
  viewableDays.forEach((day) => {
    const { windows } = getCurrentDayDeliveryWindows(day.valueOf());
    result.push({
      lunch: windows?.lunch?.default?.start,
      earlyDinner: windows?.earlyDinner?.default?.start,
      dinner: windows?.dinner?.default?.start,
    });
  });
  const currentDay = viewableDays[0].day();
  const startDay = viewableDays[0].clone();
  for (let i = 0; i < currentDay; i++) {
    const { windows } = getCurrentDayDeliveryWindows(
      startDay.subtract(1, "days").valueOf()
    );
    result.unshift({
      lunch: windows?.lunch?.default?.start,
      earlyDinner: windows?.earlyDinner?.default?.start,
      dinner: windows?.dinner?.default?.start,
    });
  }
  LOGGING && console.log("getWeeklyMealWindows got result:", result);
  return result;
};

export const getWeeklyMenuWindows = (viewableDays) => {
  // LOGGING && console.log('getWeeklyMenuWindows got viewableDays:', viewableDays);
  let result = [],
    nextMeal = null;
  viewableDays.forEach((day, index) => {
    const { windows } = getCurrentDayDeliveryWindows(day.valueOf());
    if (index === 0) {
      const now = moment().tz(DefaultTimeZone).valueOf();
      if (windows?.lunch?.default?.start > now) {
        nextMeal = windows?.lunch?.default?.start;
      } else if (windows?.earlyDinner?.default?.start > now) {
        nextMeal = windows?.earlyDinner?.default?.start;
      } else if (windows?.dinner?.default?.start > now) {
        nextMeal = windows?.dinner?.default?.start;
      }
    }
    result.push({
      lunch: windows?.lunch?.default?.start,
      earlyDinner: windows?.earlyDinner?.default?.start,
      dinner: windows?.dinner?.default?.start,
    });
  });

  return { windowsForWeeklyMenu: result, nextMeal };
};

export const getViewableDays = () => {
  const tomorrow = moment().tz(DefaultTimeZone).add(1, "day").startOf("day");
  const cutOff = moment().tz(DefaultTimeZone).set({
    hour: 16,
    minute: 30,
    second: 0,
    millisecond: 0,
  });
  const now = moment().tz(DefaultTimeZone);
  // const endOfTomorrowsWeek = tomorrow.clone().endOf('w');

  // LOGGING &&
  //   console.log('getViewableDays called with: ', {
  //     now: now.format('LLLL'),
  //     cutOff: cutOff.format('LLLL'),
  //     tomorrow: tomorrow.format('LLLL'),
  //     endOfTomorrowsWeek: endOfTomorrowsWeek.format('LLLL'),
  //   });
  let result = now.isBefore(cutOff) ? [now.clone()] : [];
  for (
    var day = tomorrow, count = 0;
    //day.isSameOrBefore(endOfTomorrowsWeek) &&
    count++ < 6;
    day.add(1, "d")
  ) {
    // LOGGING && console.log('day:', day.format('LLLL'));
    result.push(day.clone());
    // LOGGING && console.log('result:', result);
  }
  return result;
};

export const shiftComment = {
  early: "early delivery",
  default: "Normal delivery",
  late: "late delivery",
};

export const ordinal = (i) => {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
};

export const PhoneFormat = (number) =>
  number
    ? number.includes("+1")
      ? `(${number.slice(2, 5)}) ${number.slice(5, 8)}-${number.slice(8)}`
      : `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`
    : "";

export const PhoneVerificationStatus = {
  NotStarted: "NotStarted",
  NotVerified: "NotVerified",
  PendingCheck: "PendingCheck",
  Verified: "Verified",
};

export const LunchReminders = [
  {
    day: -1,
    hour: 17,
  },
  {
    day: -1,
    hour: 18,
  },
  {
    day: -1,
    hour: 19,
  },
  {
    day: -1,
    hour: 20,
  },
  {
    day: -1,
    hour: 21,
  },
  {
    day: -1,
    hour: 22,
  },
  {
    day: -1,
    hour: 23,
  },
  {
    day: 0,
    hour: 0,
  },
  {
    day: 0,
    hour: 6,
  },
  {
    day: 0,
    hour: 7,
  },
  {
    day: 0,
    hour: 8,
  },
  {
    day: 0,
    hour: 9,
    isDefault: true,
  },
  {
    day: 0,
    hour: 10,
  },
];
export const EarlyDinnerReminders = [
  {
    day: -1,
    hour: 17,
  },
  {
    day: -1,
    hour: 18,
  },
  {
    day: -1,
    hour: 19,
  },
  {
    day: -1,
    hour: 20,
  },
  {
    day: -1,
    hour: 21,
  },
  {
    day: -1,
    hour: 22,
  },
  {
    day: -1,
    hour: 23,
  },
  {
    day: 0,
    hour: 0,
  },
  {
    day: 0,
    hour: 6,
  },
  {
    day: 0,
    hour: 7,
  },
  {
    day: 0,
    hour: 8,
  },
  {
    day: 0,
    hour: 9,
  },
  {
    day: 0,
    hour: 10,
  },
  {
    day: 0,
    hour: 11,
  },
  {
    day: 0,
    hour: 12,
  },
  {
    day: 0,
    hour: 13,
  },
  {
    day: 0,
    hour: 14,
    isDefault: true,
  },
  {
    day: 0,
    hour: 15,
  },
  {
    day: 0,
    hour: 16,
  },
];
export const DinnerReminders = [
  {
    day: -1,
    hour: 17,
  },
  {
    day: -1,
    hour: 18,
  },
  {
    day: -1,
    hour: 19,
  },
  {
    day: -1,
    hour: 20,
  },
  {
    day: -1,
    hour: 21,
  },
  {
    day: -1,
    hour: 22,
  },
  {
    day: -1,
    hour: 23,
  },
  {
    day: 0,
    hour: 0,
  },
  {
    day: 0,
    hour: 6,
  },
  {
    day: 0,
    hour: 7,
  },
  {
    day: 0,
    hour: 8,
  },
  {
    day: 0,
    hour: 9,
  },
  {
    day: 0,
    hour: 10,
  },
  {
    day: 0,
    hour: 11,
  },
  {
    day: 0,
    hour: 12,
  },
  {
    day: 0,
    hour: 13,
  },
  {
    day: 0,
    hour: 14,
  },
  {
    day: 0,
    hour: 15,
    isDefault: true,
  },
  {
    day: 0,
    hour: 16,
  },
];

export const SupportedDeliveryCities = [
  "Menlo Park",
  "Mountain View",
  "Sunnyvale",
  "Los Altos",
  "Los Altos Hills",
  "Palo Alto",
  "East Palo Alto",
  "Stanford",
  "Santa Clara",
  "Cupertino",
  "Campbell",
  "Saratoga",
  "San Jose",
  "Milpitas",
  "Los Gatos",
  "Monte Sereno",
  "Atherton",
];
export const CityCoordinatesDesktop = {
  "Menlo Park": [37.4764661, -122.1963602],
  "Mountain View": [37.4033961, -122.1225522],
  "Palo Alto": [37.4485964, -122.1490358],
  "East Palo Alto": [37.4679862, -122.1163426],
  "Los Altos": [37.3850009, -122.1370983],
  "Los Altos Hills": [37.3676715, -122.1752304],
  Stanford: [37.4249701, -122.1910259],
  Sunnyvale: [37.3917952, -122.0251533],
  "Santa Clara": [37.3607349, -122.0088378],
  Cupertino: [37.3092823, -122.0642869],
  Campbell: [37.2807964, -121.9756235],
  Saratoga: [37.2665018, -122.0470628],
  "San Jose": [37.3157825, -121.9359261],
  Milpitas: [37.4320235, -121.9055948],
  "Los Gatos": [37.2333712, -121.9905462],
  "Monte Sereno": [37.2364759, -122.083602],
  Atherton: [37.4498292, -122.2209756],
};

export const CityCoordinatesMobile = {
  "Menlo Park": [37.5104661, -122.3083602],
  "Mountain View": [37.3973961, -122.2025522],
  "Palo Alto": [37.4725964, -122.1690358],
  "East Palo Alto": [37.5109862, -122.1463426],
  "Los Altos": [37.3580009, -122.1920983],
  "Los Altos Hills": [37.3196715, -122.2802304],
  Stanford: [37.4349701, -122.2110259],
  Sunnyvale: [37.3967952, -122.0101533],
  "Santa Clara": [37.3557349, -122.0028378],
  Cupertino: [37.3122823, -122.0842869],
  Campbell: [37.2787964, -121.9326235],
  Saratoga: [37.2735018, -122.0700628],
  "San Jose": [37.3157825, -121.9359261],
  Milpitas: [37.4420235, -121.9555948],
  "Los Gatos": [37.2333712, -121.9905462],
  "Monte Sereno": [37.2294759, -122.1783602],
  Atherton: [37.4728292, -122.3109756],
};

export const CityStyles = {
  "Menlo Park": { wrap: false },
  "Mountain View": { wrap: false },
  "Palo Alto": { wrap: false },
  "East Palo Alto": { wrap: false },
  "Los Altos": { wrap: false },
  "Los Altos Hills": { wrap: false },
  Stanford: { wrap: true },
  Sunnyvale: { wrap: true },
  "Santa Clara": { wrap: false },
  Cupertino: { wrap: true },
  Campbell: { wrap: true },
  Saratoga: { wrap: true },
  "San Jose": { wrap: false },
  Milpitas: { wrap: true },
  "Los Gatos": { wrap: false },
  "Monte Sereno": { wrap: false },
  Atherton: { wrap: false },
};
export const SupportedSanJoseZipCodes = [
  "95129",
  "95117",
  "95116",
  "95130",
  "95128",
  "95127",
  "95126",
  "95110",
  "95113",
  "95112",
  "95124",
  "95134",
  "95133",
  "95118",
  "95131",
  "95125",
];

export const HalloweenWhitelist = [
  "605ad999a5fc1c001700e45a",
  "6497763f25611b0014b102a4",
  "65373a0019fe280014e976aa",
];

// map each city to a display name for concatenation (used in error message during address validation)
export const SupportedDeliveryCityDisplayNames = {
  "Mountain View": "Mountain View",
  Sunnyvale: "Sunnyvale",
  Campbell: "Campbell",
  "Los Altos": "Los Altos",
  "Los Altos Hills": "Los Altos Hills",
  "Palo Alto": "Palo Alto",
  "East Palo Alto": "East Palo Alto",
  Stanford: "Stanford",
  "Santa Clara": "Santa Clara",
  Milpitas: "Milpitas",
  Cupertino: "Cupertino",
  "Menlo Park": "Menlo Park",
  Saratoga: "Saratoga",
  "Los Gatos": "Los Gatos",
  "Monte Sereno": "Monte Sereno",
  Atherton: "Atherton",
  "San Jose": `San Jose (${SupportedSanJoseZipCodes.join(", ")} only)`,
};

export const SupportedDeliveryCitiesStr = SupportedDeliveryCities.map(
  (city, i) =>
    i === SupportedDeliveryCities.length - 1
      ? `and ${SupportedDeliveryCityDisplayNames[city]}`
      : SupportedDeliveryCityDisplayNames[city]
).join(", ");

export const IsAddressSupportedForDelivery = (address_components: any) => {
  console.log("addressComponents are: ", address_components);
  const city = address_components.find((c) =>
    c.types.includes("locality")
  ).short_name;
  const zipCode = address_components.find((c) =>
    c.types.includes("postal_code")
  ).short_name;
  console.log("zipCode is: ", zipCode, "typeOf: ", typeof zipCode);
  const number = address_components.find((c) =>
    c.types.includes("street_number")
  );
  const number_str = typeof number === "undefined" ? "" : number.short_name;
  const street = address_components.find(
    (c) => c.types.includes("route") || c.types.includes("premise")
  ).short_name;
  if (SupportedDeliveryCities.indexOf(city) !== -1) {
    console.log("zipCode is: ", zipCode, "typeOf: ", typeof zipCode);
    if (city === "San Jose" && !SupportedSanJoseZipCodes.includes(zipCode)) {
      return false;
    }
    return true;
  }
  if (city === "Santa Clara") {
    if (number_str === "2901" && street === "Tasman Dr") {
      return true;
    }
    if (number_str === "3335" && street === "St Ignatius Pl") {
      return true;
    }
  }
  return false;
};

// checks that an address (inputted by user at checkout) for a given meal is in a supported city for that meal
export const addressIncludedInMealDeliveryZones = (
  supportedDeliveryZonesArray,
  address_components: any
) => {
  const supportedCityNamesForMeal = [];
  supportedDeliveryZonesArray.forEach((zone) =>
    zone.cities.length === 1
      ? supportedCityNamesForMeal.push(zone.cities[0])
      : zone.cities.forEach((cityName) =>
          supportedCityNamesForMeal.push(cityName)
        )
  );
  const userCheckoutCityName = address_components.find((c) =>
    c.types.includes("locality")
  ).short_name;
  if (supportedCityNamesForMeal.includes(userCheckoutCityName)) {
    return true;
  }
  return false;
};

export const findRestaurant = (
  windowStart,
  weeklyMenu,
  selectedDeliveryZone
) => {
  if (weeklyMenu === undefined) {
    return undefined;
  }
  LOGGING && console.log("findRestaurant GOT:", { weeklyMenu });
  for (const dailyMenu of weeklyMenu) {
    if (dailyMenu.lunchTime === windowStart) {
      return dailyMenu.lunch?.filter((info) =>
        info.supportedDeliveryZone.includes(selectedDeliveryZone)
      )[0]?.restaurant;
    }
    if (dailyMenu.dinnerTime === windowStart) {
      return dailyMenu.dinner?.filter((info) =>
        info.supportedDeliveryZone.includes(selectedDeliveryZone)
      )[0]?.restaurant;
    }
  }
};

export const referralText = (code) =>
  `Foodie Earth is a great new delivery service where you can support local Bay Area businesses with NO delivery fee! You can get $5 off your first order of $20+ when you order with this link https://foodie.earth/g/${code}. Hope you enjoy!`;

export const socialText = (code) =>
  `Try my favorite food delivery website https://foodie.earth. They charge no delivery fee! Use code ${code} to get $5 off your first order!`;

export const getNameFromOrder = (order) => {
  if (order?.name?.trim().length > 0) {
    return order?.name;
  } else {
    return `${order?.user?.firstName} ${order?.user?.lastName}`;
  }
};

export const customerServicePhoneNumber = "(408) 520-0627";

// constants for driverPay
export const driverHourlyWage = 40; // for drivers
export const maxRouteTime = 80; // in minutes
export const minRouteTime = 40; // in minutes
export const CollageRestaurants = [
  {
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/jiMoflXz_6XOopIgP4W2QA/o.jpg",
    name: "Chef Chu",
  },
  {
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/T8t1gVqtXdkDsWqnLj8isA/o.jpg",
    name: "Kura Revolving Sushi",
  },
  {
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/hq1E-SNEs8MydQMQgZzwSg/o.jpg",
    name: "Galpao Gaucho",
  },
  {
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/hKYdonEl3xjnXH2caqGejQ/o.jpg",
    name: "Satkar India",
  },
  {
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/jNttWJP074j5vnmGDIvUfw/o.jpg",
    name: "Kunjip",
  },
  {
    image:
      "https://s3-media0.fl.yelpcdn.com/bphoto/X1jJ9iUZOImDnlBzefIiAw/o.jpg",
    name: "La Fiesta",
  },
];
export const SampleRestaurantsMobile = [
  // {
  //   image:
  //     "https://d1xqwss0spk56d.cloudfront.net/2d402bb5-3f0c-428d-b6bb-419cbcc26498.jpg",
  //   name: "Zareen's",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/z8oiDAADeAbETusuNg8LcQ/o.jpg",
  //   name: "Rantei Japanese Cuisine",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/1xyWXaDA2xcRnEAeHvD08g/o.jpg",
  //   name: "Zero Doppio",
  // },
  // {
  //   image:
  //     "https://d1xqwss0spk56d.cloudfront.net/a8c5abec-c0d7-4c27-8a23-5dc319524fed.jpg",
  //   name: "Chicken Meets Rice",
  // },
  {
    image:
      "https://i.pinimg.com/originals/ef/b5/9f/efb59f57b2108365ef6436685c53c26b.jpg",
    name: "HiroNori Craft Ramen",
  },
  {
    image:
      "https://i.pinimg.com/originals/7b/02/87/7b0287aca5bddff021c57d69fb6e4234.jpg",
    name: "The Cheesecake Factory",
  },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/UG_6BRlApVGW_ZJ6V-FgGw/o.jpg",
  //   name: "UlavacharU Indian Restaurant",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/jiMoflXz_6XOopIgP4W2QA/o.jpg",
  //   name: "Chef Chu",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/hq1E-SNEs8MydQMQgZzwSg/o.jpg",
  //   name: "Galpao Gaucho",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/p1vdCNlYY0KtQML7a4aliw/o.jpg",
  //   name: "Sunright Tea Studio",
  // },

  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/iwCgksecYN9ID1oTlst2Eg/o.jpg",
  //   name: "The good salad",
  // },
  // {
  //   name: "10 Butchers Korean BBQ",
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/_K5i3bEoV61oQPen-tmWZA/o.jpg",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/BZoYxWzbecxD8hnzxv0MMg/o.jpg",
  //   name: "Falafel Stop",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/Sda505fUAcP0yMb7FjWvew/o.jpg",
  //   name: "Pho Ha Noi",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/U1UerFYOqwOvvje_wr855g/o.jpg",
  //   name: "Amber India",
  // },
  {
    image:
      // "https://s3-media0.fl.yelpcdn.com/bphoto/QzJM3TwY2Hy40S-OnYttpQ/o.jpg",
      "https://i.pinimg.com/originals/56/09/46/56094647fbc397615e467838b8132cdd.jpg",
    name: "Din Tai Fung",
  },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/A01bUYpPSzQIio3NnXlW1A/o.jpg",
  //   name: "Starbird Chicken",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/CvOMKbsZMBQ0ZC-YPc4r_g/o.jpg",
  //   name: "Ike's Love & Sandwiches",
  // },
  {
    image:
      "https://i.pinimg.com/originals/11/9c/38/119c38a599c9515560f279cefdbc3ce1.jpg",
    name: "Must Be Thai",
  },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/7XvSKuIN-uC9G6Pl0_NXjQ/o.jpg",
  //   name: "Gott's Roadside",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/TCaIkulKTWtGZkgQQ7-LrQ/o.jpg",
  //   name: "O2 Valley",
  // },
  // {
  //   image:
  //     "https://dessertfirstgirl.com/images/2019/09/Manresa-Bread-Pastries.jpg",
  //   name: "Manresa Bread",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/r2GXyUlICiNrfkINd3creA/o.jpg",
  //   name: "Amami Shima Sushi",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/s5f3TJjuckLTRG1wqUPiCQ/o.jpg",
  //   name: "The Sundance Steak House",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/UpCQe3rYwxQJoAHJND6R-w/o.jpg",
  //   name: "Ramen Haijime",
  // },
  {
    image:
      "https://i.pinimg.com/originals/88/88/37/8888377f9bc66fb375e5e0d2fbd2a1a8.jpg",
    name: "Sweetgreen",
  },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/ZvIAUxNhxcmc_xuTXeRJMg/o.jpg",
  //   name: "Daeho Kalbi Jjim",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/12lnJgCkOVWQwDcTi1Bt4Q/o.jpg",
  //   name: "terún",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/ij-HT3BMOlZAAyUq1A5vUA/o.jpg",
  //   name: "Wagon Wheel BBQ",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/Ul_5ZNwtrQ5YdrJ8IvcJ9Q/o.jpg",
  //   name: "Chef Zhao Kitchen",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/yq64H_X6mALIskB1bpSDVA/o.jpg",
  //   name: "Paris Baguette",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/QD5FQGvoltGAo4yNTGujOw/o.jpg",
  //   name: "Southland Flavor Cafe",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/0ZyjH5DOoAnjFZECMt-rFg/o.jpg",
  //   name: "Eat On Monday",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/zGrOgOrFJe4SxW_-ALStnw/o.jpg",
  //   name: "Kunjip",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/oiMODbzuDylcHMi_HIuh7A/o.jpg",
  //   name: "Kura Revolving Sushi",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/z45CZj4NhrNhc_VA5dBlZQ/o.jpg",
  //   name: "Little India Cafe",
  // },
  // {
  //   image:
  //     "https://images.squarespace-cdn.com/content/v1/577dbc32414fb5812b97f65e/1475648223882-25C8BKPIWEGTNOB95RFJ/_AES5943.jpg?format=2500w",
  //   name: "Izzy's Brooklyn Bagels",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/B8C-F53VyRHWnhv5TU5H7A/o.jpg",
  //   name: "Blue Line Pizza",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/x4dbPtnH4c7o5AlnVj6I-w/o.jpg",
  //   name: "Leichi",
  // },
  // {
  //   image:
  //     "https://www.figat7th.com/wp-content/uploads/2020/09/1-Mendocino-Farms-Club-Sandwich-Hero-1.jpg",
  //   name: "Mendocino Farms",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/3ec8C9I9t81pB7icKTKoHg/o.jpg",
  //   name: "Pacific Catch",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/pPjT93WaKZN8ZUb-Gb7MgQ/o.jpg",
  //   name: "La Fiesta Restaurant",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/2OWOEe-5xh3_FVZl2b8q8g/o.jpg",
  //   name: "Hapap Musubi",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/fgQ0DULyu2xsD98n851FwA/o.jpg",
  //   name: "L&L Hawaiian BBQ",
  // },
  {
    image:
      // "https://s3-media0.fl.yelpcdn.com/bphoto/zMfYsLbY-GKTLOY2PQDSpA/o.jpg",
      "https://i.pinimg.com/originals/11/2f/52/112f5215efb02666951d97cdd16d22d2.jpg",
    name: "Dishdash",
  },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/VO0K6O6zMWZ2T6G7kx4bTg/o.jpg",
  //   name: "Fambrini's Cafe",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/B8ZSFzmsjySo-tjCTtw80A/o.jpg",
  //   name: "Bibimbowl",
  // },
];
export const SampleRestaurantsDesktop = [
  {
    image:
      "https://i.pinimg.com/originals/1c/c8/04/1cc80465bddc5fdd699fad5691c0cce9.png",
    name: "The Cheesecake Factory",
  },
  {
    image:
      // "https://d1xqwss0spk56d.cloudfront.net/2d402bb5-3f0c-428d-b6bb-419cbcc26498.jpg",
      "https://i.pinimg.com/originals/eb/f3/24/ebf324dbcee34fdc9dab1cfe2126b749.png",
    name: "dishdash",
  },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/z8oiDAADeAbETusuNg8LcQ/o.jpg",
  //   name: "Rantei Japanese Cuisine",
  // },
  // {
  //   image:
  //     "https://s3.amazonaws.com/toasttab/restaurants/restaurant-60799000000000000/menu/items/8/item-400000007078476908_1605924972.jpg",
  //   name: "Zero Doppio",
  // },
  // {
  //   image:
  //     "https://d1xqwss0spk56d.cloudfront.net/a8c5abec-c0d7-4c27-8a23-5dc319524fed.jpg",
  //   name: "Chicken Meets Rice",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/nwCXkocW4ENKpFihCNMvVg/o.jpg",
  //   name: "Hui Lau Shan",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/wSiF4RoIJiELdjQoKNE74Q/o.jpg",
  //   name: "UlavacharU Indian Restaurant",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/jiMoflXz_6XOopIgP4W2QA/o.jpg",
  //   name: "Chef Chu",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/hq1E-SNEs8MydQMQgZzwSg/o.jpg",
  //   name: "Galpao Gaucho",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/iwCgksecYN9ID1oTlst2Eg/o.jpg",
  //   name: "The good salad",
  // },
  // {
  //   name: "10 Butchers Korean BBQ",
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/_K5i3bEoV61oQPen-tmWZA/o.jpg",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/EZWSpLhm0YNoJ6BovSqoVQ/o.jpg",
  //   name: "Falafel Stop",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/PHYv6Z4zO4ZFCDGy5Mbj1Q/o.jpg",
  //   name: "Pho Ha Noi",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/BXgnkYb4eEqxVgaZCLu4zg/o.jpg",
  //   name: "Amber India",
  // },
  {
    image:
      // "https://s3-media0.fl.yelpcdn.com/bphoto/83YlvjZXTtxDY-U5iJY2tw/o.jpg",
      "https://i.pinimg.com/originals/8c/59/4e/8c594e2939bb8f03b0a2014036b51abf.png",
    name: "Din Tai Fung",
  },
  {
    image:
      "https://i.pinimg.com/originals/17/db/01/17db011b9246381229418bc8bca4d4fe.png",
    name: "HiroNori Craft Ramen",
  },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/A01bUYpPSzQIio3NnXlW1A/o.jpg",
  //   name: "Starbird Chicken",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/CvOMKbsZMBQ0ZC-YPc4r_g/o.jpg",
  //   name: "Ike's Love & Sandwiches",
  // },
  {
    image:
      "https://i.pinimg.com/originals/c7/39/db/c739dbeed882401dcf4768a2572c9241.png",
    name: "Must Be Thai",
  },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/7XvSKuIN-uC9G6Pl0_NXjQ/o.jpg",
  //   name: "Gott's Roadside",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/TCaIkulKTWtGZkgQQ7-LrQ/o.jpg",
  //   name: "O2 Valley",
  // },
  // {
  //   image:
  //     "https://dessertfirstgirl.com/images/2019/09/Manresa-Bread-Pastries.jpg",
  //   name: "Manresa Bread",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/r2GXyUlICiNrfkINd3creA/o.jpg",
  //   name: "Amami Shima Sushi",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/UpCQe3rYwxQJoAHJND6R-w/o.jpg",
  //   name: "Ramen Haijime",
  // },
  {
    image:
      "https://i.pinimg.com/originals/65/93/b2/6593b2044ac1f3b20f3152e3b0e9a2d0.png",
    name: "Sweetgreen",
  },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/VjC4zwMqWgcLWiZRdWzeJQ/o.jpg",
  //   name: "Daeho Kalbi Jjim",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/12lnJgCkOVWQwDcTi1Bt4Q/o.jpg",
  //   name: "terún",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/ij-HT3BMOlZAAyUq1A5vUA/o.jpg",
  //   name: "Wagon Wheel BBQ",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/Ul_5ZNwtrQ5YdrJ8IvcJ9Q/o.jpg",
  //   name: "Chef Zhao Kitchen",
  // },
  // {
  //   image:
  //     "https://dfv6pkw99pxmo.cloudfront.net/wp-content/uploads/2021/08/Paris_Baguette.jpg",
  //   name: "Paris Baguette",
  // },
  // {
  //   image: "https://sandilog.files.wordpress.com/2013/09/red-hot-wok-3.jpg",
  //   name: "Red Hot Wok",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/zGrOgOrFJe4SxW_-ALStnw/o.jpg",
  //   name: "Kunjip",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/T8t1gVqtXdkDsWqnLj8isA/o.jpg",
  //   name: "Kura Revolving Sushi",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/z45CZj4NhrNhc_VA5dBlZQ/o.jpg",
  //   name: "Little India Cafe",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/Ki3oYSFUbOZqMumAzT_ayA/o.jpg",
  //   name: "Roger's Deli & Donuts",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/HLnSRi7AIsXFHh_iylKfGA/o.jpg",
  //   name: "Blue Line Pizza",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/x4dbPtnH4c7o5AlnVj6I-w/o.jpg",
  //   name: "Leichi",
  // },
  // {
  //   image:
  //     "https://www.figat7th.com/wp-content/uploads/2020/09/1-Mendocino-Farms-Club-Sandwich-Hero-1.jpg",
  //   name: "Mendocino Farms",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/kGIu0S4BCx7kaPjuExhpGQ/o.jpg",
  //   name: "Pacific Catch",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/e89l83birXPbdu0UXFbl5A/o.jpg",
  //   name: "Speedy's Tacos",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/2OWOEe-5xh3_FVZl2b8q8g/o.jpg",
  //   name: "Hapap Musubi",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/fgQ0DULyu2xsD98n851FwA/o.jpg",
  //   name: "L&L Hawaiian BBQ",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/G5gjGw4Nv4OZ3dvKRfSeGQ/o.jpg",
  //   name: "Dishdash",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/abfnO-iBWdeI5idWM6IwmQ/o.jpg",
  //   name: "Fambrini's Cafe",
  // },
  // {
  //   image:
  //     "https://s3-media0.fl.yelpcdn.com/bphoto/B8ZSFzmsjySo-tjCTtw80A/o.jpg",
  //   name: "Bibimbowl",
  // },
];
export const CoverImages = [
  "https://i.pinimg.com/originals/25/c7/f7/25c7f71e2ac3f7b711d6f39972b36497.png",
  "https://i.pinimg.com/originals/a0/30/65/a030653c3e377fff39d9046ed38e16b8.png",
  "https://i.pinimg.com/originals/7b/38/7f/7b387f2a3f9bbb7970c8dd9e6c03dbd2.png",
];

export const IconImages = {
  hero: {
    landscape:
      // "https://i.pinimg.com/originals/e0/e4/db/e0e4db7abbda75672ab215e0dbd7c826.png",
      "https://i.pinimg.com/736x/76/8e/b7/768eb70aff4ec998200531a9958a873d.jpg",
    portrait:
      // "https://d2s742iet3d3t1.cloudfront.net/restaurants/restaurant-67848000000000000/menu/items/8/item-5740030862189108_1695395516.jpg",
      "https://i.pinimg.com/736x/85/75/16/85751666ff2424e499f4f4469b4d1c4a.jpg",
  },
  milktea: {
    landscape:
      "https://i.pinimg.com/originals/a9/e3/f9/a9e3f9a40d20d73d24d5391e300ba4c1.png",
    portrait:
      "https://i.pinimg.com/originals/7c/eb/ae/7cebaee9276cfcc6e366cca3a0bd7699.png",
  },
  money: {
    landscape:
      "https://i.pinimg.com/originals/2e/ec/1f/2eec1f5b7fff217cb6856ab5670e65f0.png",
    portrait:
      "https://i.pinimg.com/originals/3c/f6/6e/3cf66ed19cc6a9004ab50b8cf6f3b73e.png",
  },
  gift: {
    landscape:
      "https://i.pinimg.com/originals/eb/3b/a0/eb3ba031dd0aeb8ccbec8feb686bb0f6.png",
    portrait:
      "https://i.pinimg.com/originals/a1/a6/d6/a1a6d6cbbd17cb664d72691ee51498c7.png",
  },
};

export const capitalizeWords = (text: string): string => {
  return text
    .split(" ") // Split the string into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
    .join(" "); // Join the words back into a string
};
