import * as Components from ".";

const HongKongRestaurant = ({ onClick }) => {
  const link = "/meal/01-29-2025/express-dinner/hong-kong-restaurant-";
  const image =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTX784pDGYAJgfemLzBLBiMVl1uxd9SgSS2cg&s";
  return (
    <Components.MenuContainer>
      <Components.MealImage src={image} />
      <Components.MealText>
        <Components.RestaurantName>
          Hong Kong Restaurant 香港小馆
        </Components.RestaurantName>
        <Components.MenuName>
          新春特色菜<br></br>Spring Festival Specials
        </Components.MenuName>
        <Components.Notes>
          <Components.DeliveryTime>
            <Components.Bullet></Components.Bullet>
            Order by&nbsp;<b>Tuesday Jan 28</b>.
          </Components.DeliveryTime>
          <Components.Instructions>
            <Components.Bullet></Components.Bullet>
            All sales are final.
          </Components.Instructions>
        </Components.Notes>
        <Components.AlaCarteList>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>至尊烧味大拼盘</Components.DishName>
              <Components.AlaCartePrice>$58</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              Supereme BBQ Combination Platter
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>发财莲藕猪手</Components.DishName>
              <Components.AlaCartePrice>$26</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              Lotus Root Pig Feet
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>鸳鸯鸡</Components.DishName>
              <Components.AlaCartePrice>$36</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              House Special Concubine Chicken and Soy Marinated Chicken
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>XO酱爆红花蚌虾球</Components.DishName>
              <Components.AlaCartePrice>$31</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              Stir-Fried Prawn and Red Clams in XO Sauce
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>花胶鲍鱼扒翡翠</Components.DishName>
              <Components.AlaCartePrice>$48</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              Braised Abalone and Fish Maw with Pea Sprouts
            </Components.DishDescription>
          </Components.AlaCarteItem>
          <Components.AlaCarteItem>
            <Components.AlaCarteName>
              <Components.DishName>发财好市瑶柱脯</Components.DishName>
              <Components.AlaCartePrice>$68</Components.AlaCartePrice>
            </Components.AlaCarteName>
            <Components.DishDescription>
              Preserved Scallop
            </Components.DishDescription>
          </Components.AlaCarteItem>
        </Components.AlaCarteList>
        <Components.PreOrderButtonWithNotesContainer>
          <Components.PreOrderButton
            onClick={(e) => {
              e.preventDefault();
              onClick(link);
            }}
          >
            pre-order LNY day early dinner<br></br>春節晚餐 (4:00-5:30pm送达)
          </Components.PreOrderButton>
        </Components.PreOrderButtonWithNotesContainer>
      </Components.MealText>
    </Components.MenuContainer>
  );
};

export default HongKongRestaurant;
