import { LOAD_PIXEL } from "../actionTypes";

const pixel = (state = {}, action) => {
  switch (action.type) {
    case LOAD_PIXEL:
      return {
        ...state,
        ...action.pixel,
      };
    default:
      return state;
  }
};

export default pixel;
