import React, { Component } from "react";
import creditCardType from "credit-card-type";
// import queryString from 'query-string';
import "url-search-params-polyfill";
import toast, { Toaster } from "react-hot-toast";
import { connect } from "react-redux";
import { TrackAdsEvent } from "src/api/tracker";
import { Membership } from "src/pages/Users/Membership";
import { LOGGING, PopUp } from ".";
import { CompetitorComparisonFullScreen } from "./components/customers/banners/CompetitorComparison";
import {
  startMembership,
  resumeMembership,
  cancelMembership,
} from "../../store/actions";

class PageMembership extends Component {
  constructor(props) {
    LOGGING &&
      console.log("PageMembership constructor called with props:", props);
    super(props);
    // this.params = queryString.parse(this.props.location.search);
    this.params = Object.fromEntries(
      new URLSearchParams(this.props.location.search)
    );
    this.state = {
      loading: false,
      showComparison: false,
      showPayment: false,
      newCard: {
        number: "",
        cvc: "",
        exp_month: "",
        exp_year: "",
      },
      error: {},
      cardType: null,
      saveCard: true,
    };
    this.handleStartMembership = this.handleStartMembership.bind(this);
    this.handleCancelMembership = this.handleCancelMembership.bind(this);
    this.handleResumeMembership = this.handleResumeMembership.bind(this);
    this.handleChangeNumber = this.handleChangeNumber.bind(this);
    this.handleToggleSaveCard = this.handleToggleSaveCard.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleToggleShowComparison =
      this.handleToggleShowComparison.bind(this);
    this.handleShowPayement = this.handleShowPayement.bind(this);
  }
  handleShowPayement(showPayment) {
    this.setState({ showPayment });
  }
  handleToggleShowComparison(e) {
    e.preventDefault();
    const { showComparison } = this.state;
    this.setState({ showComparison: !showComparison });
  }
  handleGoBack() {
    //if history is not /auth, then go back, if it is "/auth" then go to "/"
    // console.log("handleGoBack called with:", this.props.history.location.pathname);
    if (this.props.history.location.pathname !== "/auth") {
      this.props.history.goBack();
    } else {
      this.props.history.push("/");
    }
  }

  handleToggleSaveCard(e) {
    LOGGING && console.log("handleToggleSaveCard called");
    e.preventDefault();

    const { saveCard } = this.state;
    LOGGING &&
      console.log("handleToggleSaveCard called with saveCard:", saveCard);
    this.setState({ saveCard: !saveCard });
  }

  handleChangeNumber(sectionName, field, values) {
    const { error } = this.state;
    LOGGING &&
      console.log("handleChangeNumber called with", {
        sectionName,
        field,
        values,
      });

    const updatedField = { [field]: field === "phone" ? values : values.value };
    const oldFields = this.state[sectionName];
    LOGGING &&
      console.log("handleChangeNumber with:", {
        sectionName,
        error,
        field,
        values,
        updatedField,
        oldFields,
      });
    const updatedError = error && field === error.param ? {} : error;
    LOGGING && console.log("handleChangeNumber updatedError:", updatedError);
    if (field === "number") {
      const cardTypes = creditCardType(values.value);
      if (cardTypes.length === 0) {
        return;
      }
      const { lengths, gaps, code, type, niceType } = cardTypes[0];

      let cardFormat = lengths.length === 1 ? Array(lengths[0]).fill("#") : [];
      gaps.reverse().forEach((position) => cardFormat.splice(position, 0, "-"));
      LOGGING &&
        console.log("handleChangeNumber creditCardType:", {
          lengths,
          gaps,
          code,
          type,
          niceType,
        });
      this.setState({
        [sectionName]: { ...oldFields, ...updatedField },
        error: updatedError,
        cardType: {
          format: null, //cardFormat.join(""),
          length: lengths.length === 1 ? lengths[0] : null,
          code: { ...code, format: Array(code.size).fill("#").join("") },
          type: values.value.length === 0 ? null : type,
          niceType: values.value.length === 0 ? null : niceType,
        },
      });
    } else {
      this.setState({
        [sectionName]: { ...oldFields, ...updatedField },
        error: updatedError,
      });
    }
  }
  handleResumeMembership() {
    this.setState({ loading: true }, () => {
      this.props.resumeMembership().then(() => {
        if (this.props?.history?.location?.state?.next) {
          this.props.history.push(this.props.history.location.state.next);
        }
        this.setState({ loading: false, showPayment: false }, () => {
          // put toast z-index 100
          toast.success("Resumed", {
            duration: 1000,
            position: "top-center",
            style: {
              zIndex: 1000,
            },
          });
        });
      });
    });
  }
  handleCancelMembership() {
    // this.setState({ loading: true }, () => {
    //   this.props.cancelMembership().then(() => {
    //     this.setState({ loading: false });
    //   });
    // });
    this.props.history.push({
      pathname: "/cancelMembership",
      // state: {
      //   title: "Cancel Membership",
      //   next: "/membership",
      // },
    });
  }

  handleShowAuth(e) {
    e?.preventDefault();
    this.props.history.push({
      pathname: "/auth",
      state: {
        title: "Sign In To Continue",
        next: "/membership",
        secondary: { title: "Create an Account", next: "/signup" },
      },
    });
  }

  handleStartMembership(paypalSubscribeId) {
    const { newCard, saveCard } = this.state;
    this.setState({ loading: true }, () => {
      this.props
        .startMembership("PageMembership", paypalSubscribeId, newCard, saveCard)
        .then(() => {
          toast.success("Joined", {
            duration: 1000,
            position: "top-center",
            style: {
              zIndex: 1000,
            },
          });
          const { pixel } = this.props;
          if (window.fbq && pixel?.fbclid) {
            LOGGING && console.log("start membership is tracked by fbq");
            // @ts-ignore
            window.fbq("track", "Subscribe", {
              content_name: paypalSubscribeId
                ? "start membership by paypal"
                : "start membership by card",
              content_category: "pageMembership",
              value: 0,
              currency: "USD",
            });

            // Track Ads Event
            TrackAdsEvent(pixel?.fbclid, {
              source: pixel?.source,
              eventCode: "Subscribe",
              page: "pageMembership",
              contentName: paypalSubscribeId
                ? "start membership by paypal"
                : "start membership by card",
            });
          }

          if (this.props?.history?.location?.state?.next) {
            this.props.history.push(this.props.history.location.state.next);
          }
          this.setState({ loading: false, error: {}, showPayment: false });
        })
        .catch((e) => {
          LOGGING && console.log("start membership got error:", e);
          this.setState({ error: e, loading: false });
        });
    });
  }

  componentDidMount() {
    // this.setState({ loading: true }, () => {
    //   this.props.getMembershipStatus().then(() => {
    //     this.setState({ loading: false });
    //   });
    // });

    window.scrollTo(0, 0);
  }

  render() {
    LOGGING &&
      console.log("PageMembership rendering with", {
        props: this.props,
        state: this.state,
      });

    const { currentUser } = this.props;

    if (!currentUser.isAuthenticated) {
      this.props.history.push({
        pathname: "/auth",
        state: {
          title: "Sign In To Manage Membership",
          next: "/membership",
          email: this?.params?.email,
        },
      });
    }

    const { membership } = currentUser.user;
    const {
      loading,
      newCard,
      error,
      cardType,
      saveCard,
      showComparison,
      showPayment,
    } = this.state;
    const { stripeInfo } = currentUser.user;
    return (
      <div className="page">
        <Toaster />
        <PopUp
          isPoppedUp={showComparison}
          componentToDisplay={
            <CompetitorComparisonFullScreen
              onHide={this.handleToggleShowComparison}
            />
          }
          backgroundColor={"#333"}
          hidePopUp={this.handleToggleShowComparison}
        />
        <Membership
          loading={loading}
          stripeInfo={stripeInfo}
          membership={membership}
          onStart={this.handleStartMembership}
          onCancel={this.handleCancelMembership}
          onResume={this.handleResumeMembership}
          onChangeNumber={this.handleChangeNumber}
          onToggleSaveCard={this.handleToggleSaveCard}
          onSaveCard={this.handleToggleSaveCard}
          onShowAuth={this.handleShowAuth}
          saveCard={saveCard}
          newCard={newCard}
          error={error}
          cardType={cardType}
          onBack={this.handleGoBack}
          onToggleShowComparison={this.handleToggleShowComparison}
          showComparison={showComparison}
          showPayment={showPayment}
          onSetShowPayment={this.handleShowPayement}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  LOGGING && console.log("PageMembership got redux state:", state);
  return {
    currentUser: state.currentUser,
    pixel: state.pixel,
  };
}

export default connect(mapStateToProps, {
  startMembership,
  resumeMembership,
  cancelMembership,
})(PageMembership);
