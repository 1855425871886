import styled from "styled-components";
import { ShimmerEffect } from "../../restaurants/RestaurantThumbnail";
import {
  CenteredByColumn,
  CenteredByColumnLeft,
  Centered,
  BackgroundGrey,
  Beige,
} from "../../Shared";
const LoadingDishContainer = styled(Centered)`
  align-self: stretch;
  border: 1px solid lightgray;
  margin-bottom: 20px;
  justify-content: flex-start;
  height: 190px;
  max-width: 400px;
  @media only screen and (min-width: 500px) {
    margin-right: 20px;
    align-self: auto;
    width: 400px;
  }
`;
const LoadingDishImage = styled.div`
  width: 50%;
  height: 100%;
  background-color: ${Beige};
`;

const LoadingDishName = styled(Centered)`
  height: 40px;
  background-color: ${BackgroundGrey};
  align-self: stretch;
`;

export const LoadingDishDescription = styled.div`
  align-self: stretch;
  height: 14px;
  background-color: ${BackgroundGrey};
  margin-top: 10px;
`;

const LoadingDishPrice = styled.div`
  width: 50px;
  height: 30px;
  background-color: ${BackgroundGrey};
  margin-top: 20px;
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

const LoadingText = styled(CenteredByColumnLeft)`
  align-self: stretch;
  align-items: flex-end;
  padding: 10px;
  justify-content: flex-start;
  flex-grow: 1;
  align-content: stretch;
  position: relative;
`;

export const LoadingDish = () => {
  return (
    <LoadingDishContainer>
      <LoadingDishImage></LoadingDishImage>
      <LoadingText>
        <LoadingDishName>
          <ShimmerEffect />
        </LoadingDishName>
        <LoadingDishDescription />
        <LoadingDishDescription />
        <LoadingDishDescription />
        <LoadingDishPrice />
      </LoadingText>
    </LoadingDishContainer>
  );
};

const LoadingMealsContainer = styled(CenteredByColumn)`
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  @media only screen and (min-width: 500px) {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100vw;
  }
`;
export const LoadingMeal = () => {
  return (
    <LoadingMealsContainer>
      <LoadingDish />
      <LoadingDish />
      <LoadingDish />
      <LoadingDish />
      <LoadingDish />
      <LoadingDish />
    </LoadingMealsContainer>
  );
};

const LoadingMealRestaurantNameContainer = styled(CenteredByColumn)`
  height: 20px;
  background-color: ${BackgroundGrey};
  width: 100%;
  max-width: 300px;
  position: fixed;
  top: 15px;
`;

export const LoadingMealRestaurantName = () => {
  return (
    <LoadingMealRestaurantNameContainer>
      <ShimmerEffect />
    </LoadingMealRestaurantNameContainer>
  );
};
