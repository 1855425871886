import React, { ReactElement, useState } from "react";
import {
  faPlus,
  faPencil,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SerializedCategory } from "src/api/categories";
import styled from "styled-components";
import { LOGGING, PopUp } from "..";
import { CategorySelector } from "../admin/CategorySelector";
import { EditSelectionForm } from "../dish/EditSelectionForm";
import {
  ActionBlack,
  ActionGrey,
  ActionTransparent,
  Centered,
  Yellow,
  Beige,
  Grey,
  Card,
  card,
  ALLCAPTEXT,
  textOverflow,
  text12300,
  textTitle,
  TextTitle,
  centered,
  NavHeight,
  Text12300,
} from "../Shared";
const DishContainer = styled(Centered)`
  width: 100%;
  flex-direction: column;
  position: relative;
  top: -${NavHeight}px;
  align-items: flex-start;
  padding: 20px 60px;
  > button {
    margin-top: 30px;
  }
`;
const DishForm = styled.form`
  /* ${card} */
  ${centered}
  border: 1px solid #e7e7e7;
  flex-direction: column;
  /* width: calc(100% - 40px); */
  box-sizing: border-box;
  padding: 20px 40px;
  margin-top: 10px;
  background-color: ${Beige};
  /* align-items: center; */
  align-items: flex-start;
  align-self: stretch;
  flex-grow: 1;
  > button {
    margin-top: 20px;
  }
`;
const SelectionDisplaySection = styled(Centered)`
  border: 1px solid #e7e7e7;
  flex-direction: column;
  width: calc(100% - 40px);
  box-sizing: border-box;
  padding: 40px 20px;
  margin-top: 10px;
  background-color: ${Beige};
  align-items: flex-start;
  > button {
    margin-top: 20px;
  }
`;
const DishSelectionDisplay = styled(Centered)`
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
`;
const SelectionField = styled(Centered)`
  ${text12300}
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  align-content: stretch;
  position: relative;
  /* > input {
    position: absolute;
    left: 20px;
    top: 20px;
  } */

  > span {
    /* border: 1px solid #e7e7e7; */
    border: none;
    padding: 5px 10px;
    font-size: 9px;
    /* width: calc(100%); */
    margin: 0px;
    flex-grow: 1;
    align-self: stretch;
    font-weight: 300;
    /* background-color: white; */
    margin-bottom: 6px;
  }
`;
const SelectionTitle = styled(Centered)`
  margin-bottom: 10px;
  > input {
    margin: 0px;
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  > label {
    /* background-color: ${Beige}; */
    font-weight: 500;
    /* padding: 2px 10px; */
    /* border-radius: 5px; */
    margin: 0px;
    text-transform: capitalize;
  }
  > button {
    margin: 0px 0px 0px 12px;
    padding: 10px 5px;
    svg {
      margin-left: 10px;
    }
    svg:first-child {
      margin: 0px;
    }
  }
`;
const DishField = styled(Centered)`
  ${text12300}
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  align-content: stretch;
  label {
    /* background-color: ${Beige}; */
    font-weight: 500;
    /* padding: 2px 10px; */
    /* border-radius: 5px; */
    margin-bottom: 5px;
    text-transform: capitalize;
  }
  > div {
    align-self: stretch;
    flex-grow: 1;
    background-color: white;
    > div {
      border: none;
      padding: 5px;
    }
  }
  > span,
  > input {
    /* border: 1px solid #e7e7e7; */
    border: none;
    padding: 10px;
    /* width: calc(100%); */
    margin: 0px;
    flex-grow: 1;
    align-self: stretch;
    font-weight: 300;
  }
  > span {
    border: none;
    background-color: ${Grey};
  }
`;
const DishSelectionSection = styled(Centered)`
  flex-direction: column;
  width: 100%;
  /* justify-content: flex-start; */
  align-items: flex-start;
  margin-top: 10px;
  padding-top: 30px;
  > label {
    font-weight: 500;
    ${textTitle}
    margin-bottom: 30px;
  }
  > button {
    margin-bottom: 20px;
  }
`;
const DishSelectionItem = styled(Centered)`
  /* flex-direction: column; */
  width: 100%;
  justify-content: flex-start;
  margin: 0px 0px 20px 0px;
  > label {
    text-transform: uppercase;
    margin-right: 15px;
    font-weight: 500;
  }
  > input {
    margin: 0px;
    margin-right: 10px;
    /* padding: 0px 5px; */
    width: 150px;
    /* flex-grow: 1; */
    /* width: auto; */
  }
  > input[type="checkbox"] {
    width: 30px;
    flex-grow: 0;
    margin-right: 50px;
  }
  > button {
    margin-right: 10px;
  }
  /* justify-content: flex-start; */
`;
const DishSelectionEntry = styled(Centered)`
  ${card}
  ${text12300}
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
  position: relative;
  > label {
    font-weight: 500;
    margin-right: 10px;
    ${textTitle}
  }
  > button {
    position: absolute;
    right: 20px;
    top: 20px;
  }
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px;
  width: 400px;
  height: 120px;
  ${card}
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  overflow: hidden;
  margin: 10px 0px;
  /* box-shadow: none; */
`;
const IdContainer = styled(Centered)`
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0px 20px 0px;
  width: 400px;
`;
const Id = styled(Text12300)`
  background-color: ${Beige};
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  /* border-radius: 8px; */
  border: 1px solid #e7e7e7;
  margin-top: 8px;
`;
const DishSelectionItemTitle = styled(TextTitle)`
  margin-bottom: 20px;
`;

const DishSelectionTitle = styled(TextTitle)`
  padding: 20px 0px 0px 20px;
`;
const ToggleShowSelections = styled.button``;
const TEXT_CONTAINER_MIN_WIDTH = 150;
const ItemDescriptionContainer = styled.div`
  // Positioning
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;

  // Size
  min-width: ${TEXT_CONTAINER_MIN_WIDTH}px;
  height: 100%;

  text-align: left;
`;
const Title = styled.div`
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 4px;
  color: black;

  @supports (-webkit-line-clamp: 2) {
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
const Description = styled.div`
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #767676;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: black;

  @supports (-webkit-line-clamp: 2) {
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
const Price = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: black;
`;
const IMAGE_SIZE = 120;
type ImageProps = { backgroundImageUrl: string };
const Image = styled.div<ImageProps>`
  background-image: url(${(props) => props.backgroundImageUrl});
  background-size: cover;
  min-height: ${IMAGE_SIZE}px;
  min-width: ${IMAGE_SIZE}px;
  background-position: center;
`;
const DishSelectionFields = styled(Centered)`
  > button {
    margin-right: 10px;
  }
  padding: 20px 0px 0px 20px;
  margin-bottom: 20px;
  > label {
    text-transform: uppercase;
    margin-right: 15px;
    /* background-color: yellow; */
    font-weight: 500;
  }
  > input {
    border: 1px solid black;
    padding-left: 10px;
    margin: 0px;
    margin-right: 20px;
  }
`;
const DishSelectionItems = styled(Centered)`
  flex-direction: column;
  padding: 20px 20px 0px 20px;
  background-color: ${Beige};
  align-items: flex-start;
  align-self: stretch;
  /* flex-grow: 1; */
  input {
    padding-left: 10px;
    border: 1px solid black;
  }
`;
type NormalDishEditFormProps = {
  dish: any;
  isEdittingSelections: Boolean;
  savingSelection: Boolean;
  onEditSelections: () => void;
  onSave: () => void;
  onEdit: () => void;
  onToggleShowNewSelectionForm: () => void;
  onToggleShowExistingSelectionList: () => void;
  onAddSelectionItem: () => void;
  onEditSelectionItem: () => void;
  onDeleteSelectionItem: () => void;
  onEditSelection: () => void;
  onSaveSelection: () => void;
  selectedCategories: SerializedCategory[];
  onSelectCategories: (categories: SerializedCategory[]) => void;
  selectionsForRestaurant: any[];
  onToggleUseSelection: () => void;
  onToggleEditSelection: () => void;
};

const SelectionDisplay = ({ s, isSelected, onToggleUsed, onEdit }) => (
  <DishSelectionDisplay>
    <SelectionField>
      <SelectionTitle>
        <input
          checked={isSelected}
          type="checkbox"
          onChange={onToggleUsed.bind(this, s)}
        />
        <label>{`${s.name} [max ${s.maxSelection} min ${s.minSelection}]`}</label>
        <button onClick={onEdit}>
          <FontAwesomeIcon icon={faPencil} />
        </button>
      </SelectionTitle>
      <span>
        {s.items
          .map(
            (item) => `${item.name} ($${item.price})`
            // {item.isDefaultChoice ? <label>is default choice</label> : null}
            // {item.outOfStock ? <label>out of stock</label> : null}
          )
          .join(", ")}
      </span>
    </SelectionField>
  </DishSelectionDisplay>
);
export const NormalDishEditForm: React.FC<NormalDishEditFormProps> = ({
  dish,
  onSave,
  onEdit,
  selectedCategories,
  onSelectCategories,
  onAddSelectionItem,
  onEditSelectionItem,
  onDeleteSelectionItem,
  onEditSelection,
  onSaveSelection,
  onToggleShowNewSelectionForm,
  selectionsForRestaurant,
  onToggleUseSelection,
  onToggleEditSelection,
}): ReactElement => {
  LOGGING && console.log("NormalDishEditForm rendering with:", { dish });
  const [showSelections, setShowSelections] = useState<boolean>(false);
  const { selections, editSelection } = dish;

  return (
    <DishContainer>
      <PopUp
        isPoppedUp={editSelection}
        componentToDisplay={
          <EditSelectionForm
            selection={editSelection}
            onAddSelectionItem={onAddSelectionItem}
            onEditSelectionItem={onEditSelectionItem}
            onDeleteSelectionItem={onDeleteSelectionItem}
            onEdit={onEditSelection}
            onSave={onSaveSelection}
          />
        }
        hidePopUp={onToggleEditSelection.bind(this, editSelection)}
        backgroundColor={"#333"}
      />
      <TextTitle>Dish Info</TextTitle>
      <DishForm onSubmit={onSave}>
        <DishField>
          <label>Preview</label>
          <PreviewContainer>
            <ItemDescriptionContainer>
              <Title>{dish.name}</Title>
              <Description>{dish.description}</Description>
              <Price>
                {dish.priceFoodieListed
                  ? `$${dish.priceFoodieListed.toFixed(2)}`
                  : "Price Variable"}
              </Price>
            </ItemDescriptionContainer>
            <Image backgroundImageUrl={dish.imageURL} />
          </PreviewContainer>
        </DishField>
        <DishField>
          <label>name</label>
          <input defaultValue={dish.name} onChange={onEdit} name="name" />
        </DishField>

        <DishField>
          <label>description</label>
          <input
            defaultValue={dish.description}
            onChange={onEdit}
            name="description"
          />
        </DishField>
        <DishField>
          <label>Doordash Price</label>
          <input
            value={dish.priceDoordashListed || ""}
            onChange={onEdit}
            type="number"
            name="priceDoordashListed"
            step={0.01}
          />
        </DishField>
        <DishField>
          <label>Foodie Price</label>
          <input
            value={dish.priceFoodieListed || ""}
            onChange={onEdit}
            type="number"
            name="priceFoodieListed"
            step={0.01}
          />
        </DishField>
        <DishField>
          <label>Restaurant Price</label>
          <input
            value={dish.priceFoodieRestaurant || ""}
            onChange={onEdit}
            type="number"
            name="priceFoodieRestaurant"
            step={0.01}
          />
        </DishField>
        <DishField>
          <label>imageURL</label>
          <input
            defaultValue={dish.imageURL}
            onChange={onEdit}
            name="imageURL"
          />
        </DishField>
        <DishField>
          <label>categories</label>
          <CategorySelector
            restaurantId={dish.restaurant._id}
            selectedCategories={selectedCategories}
            onSelectCategories={onSelectCategories}
          />
        </DishField>
        {/* <ActionBlack type="submit">save changes</ActionBlack> */}
      </DishForm>
      <DishSelectionSection>
        <SelectionTitle>
          <ToggleShowSelections
            onClick={setShowSelections.bind(this, !showSelections)}
          >
            <TextTitle>selections</TextTitle>
            <FontAwesomeIcon
              icon={showSelections ? faChevronUp : faChevronDown}
            />
          </ToggleShowSelections>
          <ActionTransparent onClick={onToggleShowNewSelectionForm}>
            <span>Create Selection</span>
            <FontAwesomeIcon icon={faPlus} />
          </ActionTransparent>
        </SelectionTitle>
        {showSelections ? (
          <SelectionDisplaySection>
            {selectionsForRestaurant.map((s, selectionIndex) => {
              LOGGING &&
                console.log(
                  "DishSelectionSection rendering with selection from selectionsForRestaurant:",
                  s
                );
              const isSelected = selections
                .map((selection) => selection._id)
                .includes(s._id);
              return (
                <SelectionDisplay
                  s={s}
                  key={selectionIndex}
                  isSelected={isSelected}
                  onToggleUsed={onToggleUseSelection}
                  onEdit={onToggleEditSelection.bind(this, s)}
                />
              );
            })}
          </SelectionDisplaySection>
        ) : null}
      </DishSelectionSection>
      <ActionBlack onClick={onSave}>save changes</ActionBlack>
    </DishContainer>
  );
};
