import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  actionBlack,
  CenteredByColumn,
  CenteredByColumnLeft,
  NavHeight,
  text12300,
  text13500,
  text14300,
  textTitle,
  textUpper,
} from "./components/Shared";
import { Back } from "../shared";

const List = styled(CenteredByColumnLeft)`
  margin-top: ${NavHeight}px;
  padding: 0 20px 20px 20px;
  h2 {
    ${text13500}
    margin-bottom: 0px;
  }
  p {
    ${text12300}
  }
  a {
    ${actionBlack}
    margin-top: 20px;
    background-color: red;
  }
`;
const PageAccount = () => {
  const onBack = () => {
    window.history.back();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="list">
      <div className="list-title">
        <Back onClick={onBack} />
        <span>manage account</span>
      </div>
      <List>
        <h2 className="list-item-title">delete account</h2>
        <p>
          You can request to have your account deleted and personal information
          removed from our system.
        </p>

        <Link to="/delete-account" className="list-item">
          delete account
        </Link>
      </List>
    </div>
  );
};
export default PageAccount;
