import React, { useState } from "react";
import { faImages } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSwipeable } from "react-swipeable";
import { PopUp } from "src/screens/shared";
import styled from "styled-components";
import FullScreenCarousel from "./FullScreenCarousel";
import FullScreenCollage from "./FullScreenCollage";
import { centered, text12300, text14300 } from "../Shared";

const CarouselContainer = styled.div<{ isMobile: boolean }>`
  width: 100%;
  height: 300px;
  position: relative;
  background-color: white;
  overflow: hidden;
  display: ${(props) => (props.isMobile ? "flex" : "none")};

  @media screen and (min-width: 600px) {
    display: ${(props) => (props.isMobile ? "none" : "flex")};
    justify-content: flex-start;
    border-radius: 15px;
    max-width: 600px;
    margin: 20px 0px;
    :hover {
      cursor: pointer;
    }
  }
`;

export const ImageList = styled.div<{ translateX: number }>`
  display: flex;
  transform: ${({ translateX }) => `translateX(${translateX}%)`};
  transition: transform 0.3s ease-in-out;
  width: 100%;
  @media screen and (min-width: 600px) {
    /* padding: 10px; */
    justify-content: flex-start;
  }
`;

const ImageWrapper = styled.div`
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  @media screen and (min-width: 600px) {
    width: calc(50% - 2px);
    margin-left: 5px;
    &:first-child {
      margin-left: 0px;
    }
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const IndexWidget = styled.div<{
  isMobile: boolean;
  isLarger?: boolean;
}>`
  position: absolute;
  bottom: ${(props) => (props.isLarger ? "10px" : "5px")};
  right: ${(props) => (props.isLarger ? "10px" : "5px")};
  background-color: rgba(0, 0, 0, 0.5);
  padding: ${(props) => (props.isLarger ? "5px 10px" : "2px 6px")};
  border-radius: ${(props) => (props.isLarger ? "10px" : "5px")};
  z-index: 1;
  ${centered}
  display: ${(props) => (props.isMobile ? "flex" : "none")};
  span {
    color: white;
    ${text12300}
    font-size: ${(props) => (props.isLarger ? "12px" : "10px")};
    letter-spacing: ${(props) => (props.isLarger ? "2px" : "0.6px")};
    font-weight: 400;
  }
  svg {
    font-size: 13px;
    color: white;
    margin-right: ${(props) => (props.isLarger ? "5px" : "3px")};
  }
  @media screen and (min-width: 600px) {
    display: ${(props) => (props.isMobile ? "none" : "flex")};
  }
`;

const ImageCarousel = ({ images, onOpenFullScreenCollage }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [translateX, setTranslateX] = useState(0); // Tracks swipe translation for smooth transitions

  const handleNext = () => {
    if (activeIndex < images.length - 1) {
      setActiveIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (activeIndex > 0) {
      setActiveIndex((prevIndex) => prevIndex - 1);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwiping: (eventData) => {
      // Calculate translation based on swipe delta
      const deltaX = eventData.deltaX;
      const totalWidth = window.innerWidth;
      const percentage = (deltaX / totalWidth) * 100;

      // Allow swiping only if within bounds
      if (
        (deltaX > 0 && activeIndex > 0) || // Swiping right but not on the first image
        (deltaX < 0 && activeIndex < images.length - 1) // Swiping left but not on the last image
      ) {
        setTranslateX(-activeIndex * 100 + percentage);
      }
    },
    onSwipedLeft: () => {
      if (activeIndex < images.length - 1) {
        setTranslateX(0); // Reset translation
        handleNext();
      }
    },
    onSwipedRight: () => {
      if (activeIndex > 0) {
        setTranslateX(0); // Reset translation
        handlePrev();
      }
    },
    onSwiped: () => setTranslateX(-activeIndex * 100), // Ensure translation snaps into place
    preventScrollOnSwipe: true,
  });

  return (
    <>
      <CarouselContainer
        isMobile={true}
        {...swipeHandlers}
        onClick={onOpenFullScreenCollage}
      >
        {/* Image List */}
        <ImageList
          translateX={translateX === 0 ? -activeIndex * 100 : translateX}
        >
          {images.map((image, index) => (
            <ImageWrapper key={index}>
              <Image src={image} />
            </ImageWrapper>
          ))}
        </ImageList>

        {/* Index Widget */}
        <IndexWidget isMobile={true} isLarger={true}>
          <span>
            {activeIndex + 1}/{images.length}
          </span>
        </IndexWidget>
      </CarouselContainer>
      <CarouselContainer isMobile={false} onClick={onOpenFullScreenCollage}>
        {/* Image List */}
        <ImageList
          translateX={translateX === 0 ? -activeIndex * 100 : translateX}
        >
          {images.map((image, index) => (
            <ImageWrapper key={index}>
              <Image src={image} />
            </ImageWrapper>
          ))}
        </ImageList>
        <IndexWidget isMobile={false} isLarger={true}>
          <FontAwesomeIcon icon={faImages} />
          <span>{images.length}</span>
        </IndexWidget>
      </CarouselContainer>
    </>
  );
};
export default ImageCarousel;
