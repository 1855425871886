import React, { ReactElement, useCallback } from "react";
import { useAsync } from "react-async";
import { GetDishById } from "src/api/dish";
import { Modal, ModalProps } from "src/components/Modal";
import { ObjectId } from "src/constants/types";
import {
  DishChooserFactory,
  DishChooserFactoryProps,
} from "./DishChooserFactory";
import { LOGGING } from "..";

type Props = {
  dishId: ObjectId;
} & DishChooserFactoryProps &
  ModalProps;

export const DishChooserContainer: React.FC<Props> = (
  props: Props
): ReactElement => {
  const {
    dishId,
    isMember,
    isAuthenticated,
    bottom,
    quantityCap,
    isNotOrganizer,
    goodKey,
    good,
    menuLink,
    source,
    fbclid,
  } = props;
  const { modalify, onClose } = props;
  const {
    countInCart,
    onAddGoodsToCart,
    onUpdateGoodInCart,
    onClickCountInCart,
    onStartMembership,
  } = props;

  // LOGGING &&
  //   console.log("DishChooserContainer rendering with:", {
  //     good,
  //     goodKey,
  //   });
  const addGoods = useCallback(
    (dishSelectionData) => {
      onAddGoodsToCart(dishSelectionData);
      onClose?.();
    },
    [onClose, onAddGoodsToCart]
  );

  const updateGoodInCart = useCallback(
    (goodKey, updatedGood) => {
      onUpdateGoodInCart(goodKey, updatedGood);
      onClose?.();
    },
    [onClose, onUpdateGoodInCart]
  );
  const fetch = useCallback(async () => {
    return await GetDishById(dishId);
  }, [dishId]);
  const { data } = useAsync({ promiseFn: fetch });
  if (data == null) return null;

  const dish = data.dish;
  if (dish == null) return null;

  if (modalify) {
    return (
      <Modal isOpen={true} showClose noPadding={true} onClose={onClose}>
        <DishChooserFactory
          dish={dish}
          countInCart={countInCart}
          onAddGoodsToCart={addGoods}
          onUpdateGoodInCart={updateGoodInCart}
          onClickCountInCart={onClickCountInCart}
          modalify={modalify}
          isMember={isMember}
          isAuthenticated={isAuthenticated}
          onStartMembership={onStartMembership}
          bottom={bottom}
          quantityCap={quantityCap}
          isNotOrganizer={isNotOrganizer}
          goodKey={goodKey}
          good={good}
          menuLink={menuLink}
          source={source}
          fbclid={fbclid}
        />
      </Modal>
    );
  }
  return (
    <DishChooserFactory
      dish={dish}
      countInCart={countInCart}
      onAddGoodsToCart={addGoods}
      onUpdateGoodInCart={updateGoodInCart}
      onClickCountInCart={onClickCountInCart}
      modalify={modalify}
      isMember={isMember}
      isAuthenticated={isAuthenticated}
      quantityCap={quantityCap}
      isNotOrganizer={isNotOrganizer}
      goodKey={goodKey}
      good={good}
      menuLink={menuLink}
      source={source}
      fbclid={fbclid}
    />
  );
};
