import { useSelector } from "react-redux";
import { getItemNumberInCart } from "src/constants";
import { RootState } from "src/store";
import styled from "styled-components";
import {
  ActionBlack,
  BackgroundGrey,
  BorderGrey,
  Centered,
  mobileNextAction,
  NavHeight,
  Overlay,
  TextGrey,
} from "../../Shared";
import { ButtonRight, NextActionContainer } from "../carts/CheckoutThumbnail";
import { Counter } from "../carts/Counter";
import { NextIcon } from "../carts/NextIcon";
import { BreakPoint } from "../mealMenu/MealHeader";
import { LoadingSpinner } from "../survey/Taste";

const ButtonTop = 20;
const ButtonHeight = 40;
const Button = styled(ActionBlack)`
  text-align: center;
  opacity: 1;
  justify-content: space-between;
  margin-bottom: 20px;
  position: fixed;
  top: ${ButtonTop}px;
  right: 70px;
  border-radius: 20px;
  line-height: ${ButtonHeight}px;
  align-self: stretch;
  width: calc(50vw - 80px);
  padding: 0px 20px;
  font-size: 12px;
  z-index: 2;
  &:disabled {
    background-color: ${TextGrey};
  }
  @media screen and (max-width: ${BreakPoint}px) {
    ${mobileNextAction}
    span:nth-child(2) {
      display: none;
    }
  }
`;
const PlaceOrder = styled(Centered)`
  justify-content: space-between;
  span:nth-child(2) {
    font-size: 13px;
    letter-spacing: 1px;
  }
  align-self: stretch;
  flex-grow: 1;
  margin-right: 10px;
  @media screen and (max-width: ${BreakPoint}px) {
    font-size: 16px;
    justify-content: center;
    margin-right: 0px;
    /* border: 1px solid orange; */
    span:nth-child(2) {
      font-size: 18px;
      letter-spacing: 1px;
      font-weight: 500;
      display: none;
    }
  }
`;
const PayingOverlay = styled(Overlay)`
  height: 100vh;
  width: 100vw;
`;
export const PayButton = ({ onSubmit, paying, initializing, isCartValid }) => {
  const cart = useSelector((state: RootState) => state?.cart?.payLoad);
  const user = useSelector((state: RootState) => state?.currentUser?.user);
  const { due, doordashComparables } = cart;
  const isMember = user?.membership?.isActive;

  return (
    <NextActionContainer opacity={1}>
      {paying && (
        <PayingOverlay>
          <LoadingSpinner />
        </PayingOverlay>
      )}
      <Button
        id="addToCartButton"
        onClick={onSubmit}
        disabled={
          (!isMember && !doordashComparables?.isAvailable) ||
          paying ||
          initializing ||
          !isCartValid
        }
      >
        {/* <PlaceOrder> */}
        <span>{`${
          initializing ? "connecting..." : paying ? "placing..." : "place order"
        }`}</span>
        {/* <Counter number={getItemNumberInCart(cart)} /> */}
        {initializing ? null : <span>${due?.toFixed(2)}</span>}
        {/* </PlaceOrder> */}
        {/* <NextIcon /> */}
      </Button>
    </NextActionContainer>
  );
};
