import React, { ReactElement, useState, useCallback } from "react";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import {
  faLoveseat,
  faCrown,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory } from "react-router-dom";
import { TrackAdsEvent } from "src/api/tracker";
import { ObjectId } from "src/constants/types";
import styled, { keyframes } from "styled-components";
import { LOGGING, MemberServiceFee } from "../..";
import { FreeTrialDays, MembershipCost } from "../../";
import {
  Centered,
  CenteredByColumn,
  CenteredByColumnLeft,
  NavHeight,
  Text12300,
  card,
  Card,
  ActionBlack,
  TextTitle,
  ActionTransparent,
  text12300,
  text14300,
  Beige,
  Yellow,
  Brown,
  actionBlack,
  textTitle,
  Purple,
  centered,
  Gold,
} from "../../Shared";

export interface MemberSavingProps {
  cart: {
    memberSaving: number;
    subtotalMember: number;
    subtotalNonMember: number;
    totalTaxMember: number;
    totalTaxNonMember: number;
    serviceFeeMember: number;
    serviceFeeNonMember: number;
    deliveryFeeMember: number;
    deliveryFeeNonMember: number;
    tips: number;
    doordashComparables?: any;
    meal?: any;
    total: number;
    due?: number;
    sum?: number;
  };
  isMember: Boolean;
}

export interface ToSaveByMembershipProps {
  cart?: {
    _id: ObjectId;
    memberSaving: number;
    subtotalMember: number;
    subtotalNonMember: number;
    totalTaxMember: number;
    totalTaxNonMember: number;
    serviceFeeMember: number;
    serviceFeeNonMember: number;
    doordashComparables?: any;
  };
  card?: { last4: string; brand: string };
  onStartMembership: () => void;
  onResumeMembership?: () => void;
  bottom?: number;
  position?: string;
  maxWidth?: number;
  withQuantity?: boolean;
  isAuthenticated?: boolean;
  wasMember?: boolean;
  restaurantIsMemberOnly?: boolean;
  higher?: boolean;
  menuLink?: string;
  source?: string;
  fbclid?: string;
}

const HEIGHT = 40;

const animation = keyframes`
  0% { top: ${-1 * HEIGHT}px; opacity: 1; }
  100% { top: 0; opacity: 1; }
`;

const ContentContainer = styled(CenteredByColumn)`
  position: fixed;
  top: ${NavHeight}px;
  justify-content: flex-start;
  width: 100%;
  max-width: 450px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: ${Gold};
  ${text12300}
  font-size: 13px;
  letter-spacing: 0.7px;
  z-index: 201;
  padding: 15px 20px;
  align-items: stretch;
  height: ${NavHeight}px;
  span {
    line-height: 1.6;
    text-align: center;
  }
  b {
    font-weight: 600;
  }
  button {
    position: absolute;
    right: 20px;
    top: 22px;
  }
  @media only screen and (max-width: 350px) {
    padding-right: 30px;
    button {
      right: 10px;
    }
  }
`;
const TooFarContentContainer = styled(ContentContainer)`
  background-color: ${Gold};
`;
const ExclusiveContentContainer = styled(ContentContainer)`
  background-color: ${Brown};
  padding: 15px 40px;
  span {
    position: relative;
  }
`;
const ButtonSection = styled(CenteredByColumn)`
  width: 100%;
`;
const OverlayDetails = styled.button`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: calc(100vh - ${NavHeight}px);
  position: fixed;
  left: 0px;
  top: ${NavHeight}px;
  z-index: 200;
`;

const SavingShort = styled(Centered)`
  /* width: 120px; */
  ${text14300}
  font-size: 12px;
  background-color: ${Yellow};
  padding: 8px;
  line-height: 24px;
  text-align: center;
  /* text-transform: capitalize; */
  b {
    font-weight: 500;
    margin-left: 5px;
    font-size: 14px;
  }
  span {
    white-space: nowrap;
  }
`;
const MembershipDetails = styled(Centered)`
  position: fixed;
  ${card};
  z-index: 100;
  background-color: white;
  width: calc(100vw - 20px);

  /* height: calc(100vh - ${NavHeight}px - 40px); */
  padding: 10px;
  z-index: 201;
  max-width: 500px;
  top: ${NavHeight * 2 + 10}px;
  left: calc(max(10px, calc(50vw - 250px)));
  /* left: calc(50vw - ); */
  background: ${Beige};
  justify-content: space-between;
  @media only screen and (max-width: 320px) {
    width: calc(100vw - 20px);
  }
  @media only screen and (max-height: 600px) {
    top: ${NavHeight * 2 + 5}px;
  }
`;
const MembershipContent = styled(CenteredByColumn)`
  align-self: stretch;
  border: 2px solid ${Purple};
  padding: 20px 30px;
  justify-content: flex-start;
  flex-grow: 1;
  @media only screen and (max-height: 600px) {
    padding: 15px 10px;
  }
  /* justify-content: flex-start; */
  /* background-color: red; */
`;

const SavingRow = styled(Centered)`
  width: 100%;
  justify-content: space-between;
  padding: 0px 20px;
`;
const SavingColumn = styled(CenteredByColumnLeft)`
  padding: 5px 10px;
  min-width: 10px;
  background-color: ${Beige};
  flex-grow: 1;
  /* span:last-child {
    margin-top: 10px;
    border-top: 1px solid #ccc;
  } */
`;
const SavingItem = styled(Text12300)`
  text-transform: capitalize;
  flex-grow: 1;
  width: 100%;
  text-align: right;
  /* padding: 10px; */
`;
const SavingItemBold = styled(SavingItem)`
  font-weight: 500;
  /* text-align: left; */
`;
const SavingItemText = styled(SavingItemBold)`
  text-align: left;
`;
const SavingItemEmpty = styled(SavingItem)`
  color: transparent;
`;
const Emoji = styled(Centered)`
  position: absolute;

  left: -15px;
  display: inline-block;
  font-size: 12px;
  /* border: 1px solid red; */
  /* top: 17px; */
  /* flex-grow: 1; */
  /* align-self: stretch; */
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 22px;
  background-color: black;
  color: ${Brown};
  border-radius: 50%;

  display: none;
`;
// Only Members are shown MemberSaving
export const MemberSaving: React.FC<MemberSavingProps> = ({
  cart,
}: MemberSavingProps): ReactElement => {
  const {
    memberSaving: saving,
    subtotalMember,
    subtotalNonMember,
    totalTaxMember,
    totalTaxNonMember,
    serviceFeeMember,
    serviceFeeNonMember,
    deliveryFeeMember,
    doordashComparables,
    tips,
    total,
    due,
    sum,
  } = cart;
  const [showDetails, setShowDetails] = useState(false);
  const { isOutOfRange, distance, memberSaving } = doordashComparables || {};
  const {
    foodSaving,
    deliveryFeeSaving,
    expandedRangeFeeSaving,
    serviceFeeSaving,
    tipSaving,
    taxSaving,
    totalSaving,
  } = memberSaving || {};

  // LOGGING &&
  //   console.log("MemberSaving rendering with:", {
  //     isOutOfRange,
  //     distance,
  //     foodSaving,
  //     deliveryFeeSaving,
  //     expandedRangeFeeSaving,
  //     serviceFeeSaving,
  //     taxSaving,
  //     totalSaving,
  //   });

  return (
    <>
      {showDetails ? (
        <OverlayDetails
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowDetails(!showDetails);
          }}
        ></OverlayDetails>
      ) : null}
      {saving > 0 ? (
        <ContentContainer>
          <Emoji>💵</Emoji>
          <span>
            You saved <b>${`${totalSaving.toFixed(2)}`}</b> on this order
            <br></br>
            compared to Doordash!
          </span>
          {/* <span>
            You'd have paid <b>${`${(total + totalSaving).toFixed(2)}`}</b> for this order
            <br></br>
            on Doordash!
          </span> */}
          <button
            onClick={(e) => {
              e.preventDefault();
              setShowDetails(!showDetails);
            }}
          >
            <FontAwesomeIcon icon={showDetails ? faChevronUp : faChevronDown} />
          </button>
        </ContentContainer>
      ) : null}
      {showDetails ? (
        <SavingDetails>
          <SaveDetailsContent>
            <SavingColumn>
              <SavingItemEmpty>{"-"}</SavingItemEmpty>
              <SavingItemText>food price</SavingItemText>
              <SavingItemText>tax</SavingItemText>
              <SavingItemText>service fee</SavingItemText>
              <SavingItemText>delivery fee</SavingItemText>
              {expandedRangeFeeSaving ? (
                <SavingItemText>ex. range</SavingItemText>
              ) : null}
              <SavingItemText>tip</SavingItemText>
              <SavingItemText>total</SavingItemText>
            </SavingColumn>
            <SavingColumn>
              <SavingItemBold>doordash</SavingItemBold>
              <SavingItem>${subtotalNonMember.toFixed(2)}</SavingItem>
              <SavingItem>${totalTaxNonMember.toFixed(2)}</SavingItem>
              <SavingItem>
                ${(serviceFeeSaving + serviceFeeMember).toFixed(2)}
              </SavingItem>
              <SavingItem>
                ${(deliveryFeeMember + deliveryFeeSaving).toFixed(2)}
              </SavingItem>
              {expandedRangeFeeSaving > 0 ? (
                <SavingItem>${expandedRangeFeeSaving.toFixed(2)}</SavingItem>
              ) : null}
              <SavingItem>${(tipSaving + tips).toFixed(2)}</SavingItem>
              <SavingItemEmpty>{"-"}</SavingItemEmpty>
            </SavingColumn>
            <SavingColumn>
              <SavingItemBold>member</SavingItemBold>
              <SavingItem>${subtotalMember.toFixed(2)}</SavingItem>
              <SavingItem>${totalTaxMember.toFixed(2)}</SavingItem>
              <SavingItem>${serviceFeeMember.toFixed(2)}</SavingItem>
              <SavingItem>${deliveryFeeMember.toFixed(2)}</SavingItem>
              {expandedRangeFeeSaving > 0 ? (
                <SavingItem>$0.00</SavingItem>
              ) : null}
              <SavingItem>${tips.toFixed(2)}</SavingItem>
              <SavingItemEmpty>{"-"}</SavingItemEmpty>
            </SavingColumn>
            <SavingColumn>
              <SavingItemBold>saving</SavingItemBold>
              <SavingItem>
                ${(subtotalNonMember - subtotalMember).toFixed(2)}
              </SavingItem>
              <SavingItem>
                ${(totalTaxNonMember - totalTaxMember).toFixed(2)}
              </SavingItem>
              <SavingItem>${serviceFeeSaving.toFixed(2)}</SavingItem>
              <SavingItem>${deliveryFeeSaving.toFixed(2)}</SavingItem>
              {expandedRangeFeeSaving ? (
                <SavingItem>${expandedRangeFeeSaving.toFixed(2)}</SavingItem>
              ) : null}
              <SavingItem>${tipSaving.toFixed(2)}</SavingItem>
              <SavingItemBold>${totalSaving.toFixed(2)}</SavingItemBold>
            </SavingColumn>
          </SaveDetailsContent>
        </SavingDetails>
      ) : null}
    </>
  );
};

export const NonMemberSaving: React.FC<MemberSavingProps> = ({
  cart,
}: MemberSavingProps): ReactElement => {
  const {
    subtotalNonMember,
    totalTaxNonMember,
    serviceFeeNonMember,
    deliveryFeeNonMember,
    doordashComparables,
    tips,
    total,
    due,
    sum,
  } = cart;
  const [showDetails, setShowDetails] = useState(false);
  const { isOutOfRange, distance, nonMemberSaving } = doordashComparables || {};
  const {
    foodSaving,
    deliveryFeeSaving,
    expandedRangeFeeSaving,
    serviceFeeSaving,
    taxSaving,
    tipSaving,
    totalSaving,
  } = nonMemberSaving || {};

  LOGGING &&
    console.log("MemberSaving rendering with:", {
      isOutOfRange,
      distance,
      foodSaving,
      deliveryFeeSaving,
      expandedRangeFeeSaving,
      serviceFeeSaving,
      taxSaving,
      totalSaving,
    });

  return (
    <>
      {showDetails ? (
        <OverlayDetails
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowDetails(!showDetails);
          }}
        ></OverlayDetails>
      ) : null}
      {totalSaving > 0 && due > 0 && sum > 0 ? (
        //sum = 0 drink event
        // due = 0 influencer order
        <ContentContainer>
          <Emoji>💵</Emoji>
          <span>
            You saved about <b>${`${totalSaving.toFixed(2)}`}</b> on this order
            <br></br>
            compared to Doordash!
          </span>
          {/* <span>
            You'd have paid <b>${`${(total + totalSaving).toFixed(2)}`}</b> for this order
            <br></br>
            on Doordash!
          </span> */}
          <button
            onClick={(e) => {
              e.preventDefault();
              setShowDetails(!showDetails);
            }}
          >
            <FontAwesomeIcon icon={showDetails ? faChevronUp : faChevronDown} />
          </button>
        </ContentContainer>
      ) : null}
      {showDetails ? (
        <SavingDetails>
          <SaveDetailsContent>
            <SavingColumn>
              <SavingItemEmpty>{"-"}</SavingItemEmpty>
              <SavingItemText>food price</SavingItemText>
              <SavingItemText>tax</SavingItemText>
              <SavingItemText>service fee</SavingItemText>
              <SavingItemText>delivery fee</SavingItemText>
              {expandedRangeFeeSaving ? (
                <SavingItemText>ex. range</SavingItemText>
              ) : null}
              <SavingItemText>tip</SavingItemText>
              <SavingItemText>total</SavingItemText>
            </SavingColumn>
            <SavingColumn>
              <SavingItemBold>doordash</SavingItemBold>
              <SavingItem>${subtotalNonMember.toFixed(2)}</SavingItem>
              <SavingItem>${totalTaxNonMember.toFixed(2)}</SavingItem>
              <SavingItem>
                ${(serviceFeeSaving + serviceFeeNonMember).toFixed(2)}
              </SavingItem>
              <SavingItem>
                ${(deliveryFeeNonMember + deliveryFeeSaving).toFixed(2)}
              </SavingItem>
              {expandedRangeFeeSaving > 0 ? (
                <SavingItem>${expandedRangeFeeSaving.toFixed(2)}</SavingItem>
              ) : null}
              <SavingItem>${(tips + tipSaving).toFixed(2)}</SavingItem>
              <SavingItemEmpty>{"-"}</SavingItemEmpty>
            </SavingColumn>
            <SavingColumn>
              <SavingItemBold>foodie</SavingItemBold>
              <SavingItem>${subtotalNonMember.toFixed(2)}</SavingItem>
              <SavingItem>${totalTaxNonMember.toFixed(2)}</SavingItem>
              <SavingItem>${serviceFeeNonMember.toFixed(2)}</SavingItem>
              <SavingItem>${deliveryFeeNonMember.toFixed(2)}</SavingItem>
              <SavingItem>$0.00</SavingItem>
              <SavingItem>${tips.toFixed(2)}</SavingItem>
              <SavingItemEmpty>{"-"}</SavingItemEmpty>
            </SavingColumn>
            <SavingColumn>
              <SavingItemBold>saving</SavingItemBold>
              <SavingItem>
                ${(subtotalNonMember - subtotalNonMember).toFixed(2)}
              </SavingItem>
              <SavingItem>
                ${(totalTaxNonMember - totalTaxNonMember).toFixed(2)}
              </SavingItem>
              <SavingItem>${serviceFeeSaving.toFixed(2)}</SavingItem>
              <SavingItem>${deliveryFeeSaving.toFixed(2)}</SavingItem>
              <SavingItem>${expandedRangeFeeSaving.toFixed(2)}</SavingItem>
              <SavingItem>${tipSaving.toFixed(2)}</SavingItem>
              <SavingItemBold>${totalSaving.toFixed(2)}</SavingItemBold>
            </SavingColumn>
          </SaveDetailsContent>
        </SavingDetails>
      ) : null}
    </>
  );
};

export const Exclusive = () => (
  <ExclusiveContentContainer>
    <Emoji>
      <FontAwesomeIcon icon={faCrown} />
    </Emoji>
    <span>
      This restaurant is <b>only</b> offered<br></br> to Foodie Members!
    </span>
    {/* <span>This restaurant is <b>NOT</b> offered on Doordash!</span> */}
  </ExclusiveContentContainer>
);

export const TooFar: React.FC<MemberSavingProps> = ({
  cart,
}: MemberSavingProps): ReactElement => {
  const {
    subtotalMember,
    subtotalNonMember,
    totalTaxMember,
    totalTaxNonMember,
    serviceFeeMember,
    deliveryFeeMember,
    serviceFeeNonMember,
    doordashComparables,
  } = cart;
  const [showDetails, setShowDetails] = useState(false);
  const { deliveryFee, expandedRangeFee, serviceFee, isOutOfRange, distance } =
    doordashComparables;
  const savingFromFoodCost = subtotalNonMember - subtotalMember;
  const savingFromFoodTax = totalTaxNonMember - totalTaxMember;
  const savingFromServiceFee = serviceFee - serviceFeeMember;
  const savingFromDeliveryFee = deliveryFee - deliveryFeeMember;
  const savingFromOutOfRangeFee = expandedRangeFee || 0;
  const totalMemberSaving =
    savingFromFoodCost +
    savingFromFoodTax +
    savingFromServiceFee +
    savingFromDeliveryFee;

  LOGGING &&
    console.log("TooFar rendering with:", {
      isOutOfRange,
      doordashComparables,
      savingFromOutOfRangeFee,
      savingFromFoodCost,
      savingFromFoodTax,
      savingFromServiceFee,
      savingFromDeliveryFee,
      totalMemberSaving,
    });

  return (
    <>
      {showDetails ? (
        <OverlayDetails
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowDetails(!showDetails);
          }}
        ></OverlayDetails>
      ) : null}

      <TooFarContentContainer>
        <Emoji>🛣️</Emoji>
        <span>
          This restaurant is <b>{`${distance.toFixed(1)} mi`}</b> away,<br></br>
          out of range on Doordash!
        </span>
      </TooFarContentContainer>

      {showDetails ? (
        <SavingDetails>
          <SaveDetailsContent>
            <SavingColumn>
              <SavingItemEmpty>{"-"}</SavingItemEmpty>
              <SavingItemText>food price</SavingItemText>
              <SavingItemText>tax</SavingItemText>
              <SavingItemText>service fee</SavingItemText>
              <SavingItemText>delivery fee</SavingItemText>
              {savingFromOutOfRangeFee ? (
                <SavingItemText>expanded range</SavingItemText>
              ) : null}
              <SavingItemText>total</SavingItemText>
            </SavingColumn>
            <SavingColumn>
              <SavingItemBold>doordash</SavingItemBold>
              <SavingItem>${subtotalNonMember?.toFixed(2)}</SavingItem>
              <SavingItem>${totalTaxNonMember?.toFixed(2)}</SavingItem>
              <SavingItem>${serviceFee?.toFixed(2)}</SavingItem>
              <SavingItem>${savingFromDeliveryFee?.toFixed(2)}</SavingItem>
              {savingFromOutOfRangeFee ? (
                <SavingItem>${savingFromOutOfRangeFee.toFixed(2)}</SavingItem>
              ) : null}
              <SavingItemEmpty>{"-"}</SavingItemEmpty>
            </SavingColumn>
            <SavingColumn>
              <SavingItemBold>member</SavingItemBold>
              <SavingItem>${subtotalMember?.toFixed(2)}</SavingItem>
              <SavingItem>${totalTaxMember?.toFixed(2)}</SavingItem>
              <SavingItem>${serviceFeeMember?.toFixed(2)}</SavingItem>
              <SavingItem>$0.00</SavingItem>
              <SavingItem>$0.00</SavingItem>
              <SavingItemEmpty>{"-"}</SavingItemEmpty>
            </SavingColumn>
            <SavingColumn>
              <SavingItemBold>saving</SavingItemBold>
              <SavingItem>
                ${(subtotalNonMember - subtotalMember).toFixed(2)}
              </SavingItem>
              <SavingItem>
                ${(totalTaxNonMember - totalTaxMember).toFixed(2)}
              </SavingItem>
              <SavingItem>
                ${(serviceFeeNonMember - serviceFeeMember).toFixed(2)}
              </SavingItem>
              <SavingItem>${savingFromDeliveryFee.toFixed(2)}</SavingItem>
              <SavingItem>${savingFromOutOfRangeFee.toFixed(2)}</SavingItem>
              <SavingItemBold>${totalMemberSaving.toFixed(2)}</SavingItemBold>
            </SavingColumn>
          </SaveDetailsContent>
        </SavingDetails>
      ) : null}
    </>
  );
};

const ToSaveContainer = styled(Link)`
  ${centered}
  z-index: 100;
  /* width: 100vw; */
  height: auto;
  padding: 12px 20px;
  /* box-shadow: 0 -8px 4px 4px rgba(0, 0, 0, 0.2); */
  align-items: center;
  justify-content: space-between;
  background-color: ${Gold};
  /* margin-bottom: 10px; */
  svg {
    margin-left: 20px;
  }
  @media only screen and (min-width: 500px) {
    position: absolute;
    top: 0px;
    left: 0px;
    /* bottom: ${(props) =>
      props.theme.bottom ? props.theme.bottom + 60 : 115}px; */
    font-size: 10px;
    justify-content: center;
    align-self: stretch;
    flex-grow: 1;
    width: 100%;
    /* max-width: 500px; */
    height: 50px;
    box-sizing: border-box;
    /* margin-bottom: 20px; */
    svg {
      position: absolute;
      right: 20px;
      font-size: 20px;
      height: 50px;

      top: 0px;
    }
    /* width: 400px; */
  }
`;
const Button = styled.button`
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 2px solid black;
  margin-right: 10px;
  margin-top: 8px;
  padding: 0px;
  /* background-color: red; */
  flex: 0 0 auto;
  /* position: fixed;
  left: 20px;
  bottom: 175px; */
  @media only screen and (min-width: 500px) {
    bottom: ${(props) => (props.theme.bottom ? props.theme.bottom + 20 : 95)}px;
    width: 12px;
    height: 12px;
    margin-right: 8px;
    margin-top: 8px;
  }
`;
const Text = styled(CenteredByColumn)`
  padding: 5px 0px;
  align-items: flex-start;
  @media only screen and (min-width: 500px) {
    width: 376px;
  }
`;
const SaveLine1 = styled(Text12300)`
  /* font-weight: 500; */
  /* font-size: 12px; */
  /* border-bottom: 1px solid #ccc; */
  /* padding: 10px 0px; */
  line-height: 1.5;
  font-weight: 400;
  /* margin-bottom: 10px; */
  /* text-align: center; */
  /* font-weight: 600; */
  /* background-color: pink; */
  /* text-align: left;
  flex-grow: 1; */
  b.program {
    background-color: ${Brown};
    padding: 2px 5px;
    margin: 0px 5px;
    font-weight: 500;
    /* border-radius: 5px; */
    ${textTitle}
    font-size: 14px;
    svg {
      margin-right: 5px;
    }
  }
  b.saving-amount {
    /* background-color: ${Yellow}; */
    /* line-height: 20px; */
    /* padding: 5px 10px; */
    display: inline;
    /* margin: 5px; */
    font-weight: 600;
    font-size: 14px;
    border-radius: 5px;
  }
  @media only screen and (min-width: 500px) {
    /* font-size: 12px; */
    /* font-weight: 600; */
  }
`;
const MemberShipIcon = styled(Centered)`
  position: fixed;
  left: 5px;
  top: ${2 * NavHeight + 50}px;
  > svg {
    font-size: 28px;
    /* margin-bottom: 10px; */
  }
  background-color: ${Brown};
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-bottom: 10px;
  flex-grow: 0;
  flex-shrink: 0;
  transform: rotate(-20deg);
  @media only screen and (min-width: 500px) {
    position: absolute;
    left: -20px;
    top: -15px;
    /* z-index: 100; */
  }
`;
const SaveTitle = styled(TextTitle)`
  font-weight: 400;
  font-size: 24px;
  padding: 5px 0px;
  /* text-transform: capitalize; */
  width: 100%;
  text-align: center;
  /* margin: 10px 0px; */
  margin-bottom: 10px;
  letter-spacing: 0.6px;
  /* text-transform: uppercase; */
  line-height: 32px;
  @media only screen and (max-height: 600px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 5px;
    margin-top: 0px;
    padding-top: 0px;
  }
`;
const SaveLine2 = styled(Text12300)`
  /* margin-top: 5px; */
  font-size: 11px;
  /* font-weight: 400; */
  position: relative;
  /* left: -20px; */
  /* width: calc(100% + 20px); */
  letter-spacing: 0.5px;
  line-break: 1.5;
`;
const SaveDetails = styled(CenteredByColumn)`
  /* font-size: 10px;
  line-height: 1.5;
  margin-bottom: 20px; */
  width: 100%;
  /* padding: 0px 20px; */
  margin-bottom: 10px;
  @media only screen and (max-height: 600px) {
    margin-bottom: 5px;
  }
`;

const SavingDetails = styled(Centered)`
  width: 100%;
  position: fixed;
  top: calc(${NavHeight}px + 66px);
  left: 0px;

  z-index: 201;
  /* max-width: 500px; */
`;
const SaveDetailsContent = styled(Centered)`
  width: 100%;
  max-width: 450px;
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 15px;
`;

const Perk = styled.span`
  ${text12300}
  font-size: 13px;
  /* font-weight: 300; */
  line-height: 26px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  @media only screen and (max-height: 600px) {
    font-size: 13px;
    line-height: 20px;
    /* margin-bottom: 5px; */
  }
`;

const CreditCard = styled(Text12300)`
  /* padding: 10px 0px; */
  border-top: 1px solid #ccc;
  width: 100%;
  text-align: left;
  font-weight: 500;
  padding: 10px 0px;
  text-transform: capitalize;
`;
const ButtonJoin = styled(ActionBlack)`
  margin-bottom: 10px;
  width: 100%;
  letter-spacing: 1px;
  height: 50px;
  line-height: 1.4;
  @media only screen and (max-height: 600px) {
    /* height: 35px; */
    margin-bottom: 5px;
  }
`;
const ButtonLearnMore = styled.button`
  ${actionBlack}
  background-color: transparent;
  border: 1px solid black;
  color: black;
  width: 100%;
  margin-bottom: 10px;
  @media only screen and (max-height: 600px) {
    height: 35px;
    margin-bottom: 5px;
  }
`;
const ButtonNotNow = styled(ActionBlack)`
  width: 100%;
  background-color: transparent;
  /* border: 1px solid black; */
  color: black;
  /* margin-bottom: 10px; */
  @media only screen and (max-height: 600px) {
    height: 35px;
  }
`;
const Terms = styled(Text12300)`
  font-size: 10px;
  line-height: 1.7;
  padding-bottom: 20px;
  margin-top: 10px;
  font-weight: 200;
  text-align: center;
  b {
    font-weight: 500;
  }
`;
const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 200;
`;

export const MembershipDetailsCard = ({
  onStartMembership,
  onResumeMembership,
  saving,
  card,
  onHideCard,
  isAuthenticated,
  wasMember,
}) => {
  const history = useHistory();
  console.log(
    "MembershipDetailsCard rendering with isAuthenticated:",
    isAuthenticated
  );
  const next = useCallback(() => {
    if (isAuthenticated) {
      history.push("/membership");
      return;
    }
    history.push({
      pathname: "/signin",
      state: {
        title: "Sign In To Continue",
        next: "/membership",
        secondary: { title: "Create an Account", next: "/signup" },
      },
    });
  }, [history, isAuthenticated]);

  LOGGING &&
    console.log("MembershipDetailsCard called with: ", {
      isAuthenticated,
      wasMember,
      card,
    });

  return (
    <>
      <Overlay
        onClick={(e) => {
          e.preventDefault();
          onHideCard();
        }}
      ></Overlay>

      <MembershipDetails>
        <MembershipContent>
          <MemberShipIcon>
            <FontAwesomeIcon icon={faCrown} />
          </MemberShipIcon>
          {saving ? (
            <SaveTitle>{`Save $${saving.toFixed(2)} on this order!`}</SaveTitle>
          ) : (
            <SaveTitle>
              <span>Foodie Membership Program</span>
            </SaveTitle>
          )}
          <SaveDetails>
            {/* Lower food price, lower service fee, more restaurants, more dishes per
          restaurant. */}
            <Perk>Lower food price,</Perk>
            <Perk>More restaurants,</Perk>
            <Perk>Lower Fees.</Perk>
          </SaveDetails>
          {/* {card?.brand && card?.last4 ? (
          <CreditCard>
            {card.brand} card ...{card.last4}
          </CreditCard>
        ) : null} */}
          {card?.brand && card?.last4 ? (
            wasMember ? (
              <Terms>
                By tapping the button, you agree to resume the Foodie Membership
                Program and transition to a <b>$10 monthly</b> recurring charge
                till you cancel.
              </Terms>
            ) : (
              <Terms>
                By tapping the button, you agree to join the Foodie Membership
                Program. After the{" "}
                <b>free trial period of {FreeTrialDays} days</b>, it
                automatically transition to a <b>$${MembershipCost} monthly</b>{" "}
                recurring charge till you cancel.
              </Terms>
            )
          ) : null}

          <ButtonSection>
            {card?.brand && card?.last4 ? (
              wasMember ? (
                <ButtonJoin
                  onClick={(e) => {
                    e.preventDefault();
                    onResumeMembership();
                  }}
                >
                  Resume membership with {card.brand} ...{card.last4}
                </ButtonJoin>
              ) : (
                <ButtonJoin
                  onClick={(e) => {
                    e.preventDefault();
                    onStartMembership();
                  }}
                >
                  Start free trial with {card.brand} ...{card.last4}
                </ButtonJoin>
              )
            ) : null}
            <ButtonLearnMore onClick={next}>
              {card?.brand && card?.last4 ? "learn more" : "see details"}
            </ButtonLearnMore>
            <ButtonNotNow
              onClick={(e) => {
                e.preventDefault();
                onHideCard();
              }}
            >
              Not Now
            </ButtonNotNow>
          </ButtonSection>
        </MembershipContent>
      </MembershipDetails>
    </>
  );
};
export const ExclusiveNonMember = () => (
  <ExclusiveContentContainer>
    <span>
      This restaurant is <b>only</b> offered<br></br> to Foodie Members!
    </span>
  </ExclusiveContentContainer>
);
export const ToSaveByMembership: React.FC<ToSaveByMembershipProps> = ({
  cart,
  card,
  onStartMembership,
  onResumeMembership,
  bottom,
  position,
  maxWidth,
  withQuantity,
  isAuthenticated,
  wasMember,
  restaurantIsMemberOnly,
  higher,
  menuLink,
  source,
  fbclid,
}: ToSaveByMembershipProps): ReactElement => {
  const { memberSaving } = cart || {};
  const [showDetails, setShowDetails] = useState(false);
  LOGGING &&
    console.log("ToSaveByMembership rendering with:", {
      cart,
      showDetails,
      position,
      bottom,
      withQuantity,
      card,
      isAuthenticated,
      higher,
    });
  let saving =
    memberSaving + cart?.doordashComparables?.nonMemberSaving?.totalSaving;
  return showDetails ? (
    <MembershipDetailsCard
      saving={saving}
      card={card}
      onStartMembership={onStartMembership.bind(this, "MembershipDetailsCard")}
      onResumeMembership={onResumeMembership}
      onHideCard={setShowDetails.bind(this, false)}
      isAuthenticated={isAuthenticated}
      wasMember={wasMember}
    />
  ) : (
    <ToSaveContainer
      to="/membership"
      theme={{ bottom, position, maxWidth, withQuantity, higher }}
      onClick={() => {
        // Insert Facebook Pixel tracking here
        // @ts-ignore
        if (window.fbq && fbclid) {
          LOGGING && console.log("membership click is tracked by fbq");
          // @ts-ignore
          window.fbq("track", "ViewContent", {
            content_name: `${menuLink} to membership`,
            content_category: "pageSingleMeal",
            value: 0,
            currency: "USD",
          });

          // Track Ads Event
          TrackAdsEvent(fbclid, {
            source: source,
            eventCode: "ViewContent",
            page: "pageSingleMeal",
            contentName: `${menuLink} to membership`,
            orderId: cart?._id,
          });
        }
      }}
    >
      <SaveLine1>
        {`${wasMember ? "Resume membership" : "Start 30-day FREE trial"}
          ${
            restaurantIsMemberOnly ? " to unlock this restaurant!" : " to save "
          }`}
        {!restaurantIsMemberOnly && saving ? (
          <b className="saving-amount">{`$${saving.toFixed(2)}`}</b>
        ) : // ` $${saving.toFixed(2)}`
        null}

        {restaurantIsMemberOnly ? `` : ` on this order!`}
      </SaveLine1>
      <FontAwesomeIcon icon={faChevronRight} />
    </ToSaveContainer>
  );
};

export const MemberSavingShort: React.FC<MemberSavingProps> = ({
  cart,
  isMember,
}: MemberSavingProps): ReactElement => {
  const { doordashComparables, meal } = cart;
  const { markups, memberOnly } = meal?.restaurant || {};
  const isExclusive = memberOnly || markups?.doordash?.isAvailable === false;
  if (isExclusive || doordashComparables.isOutOfRange) {
    return null;
  }
  const saving = isMember
    ? cart?.doordashComparables?.memberSaving?.totalSaving
    : cart?.doordashComparables?.nonMemberSaving?.totalSaving;
  if (!saving) return null;
  return (
    <SavingShort>
      <span>
        Saved
        <b>${`${saving.toFixed(2)} `}</b>
        from Doordash
      </span>
    </SavingShort>
  );
};
