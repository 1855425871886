import { faPlus, faMinus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce } from "lodash";
import { BREAK_POINTS } from "src/constants";
import { LOGGING, DedupClickMiliSeconds } from "src/constants";
import { ToSaveByMembership } from "src/screens/tasting_menu/components/customers/banners/MemberSaving";
import {
  Centered,
  CenteredByColumn,
} from "src/screens/tasting_menu/components/Shared";
import styled from "styled-components";
import { Button } from "../Button";
import { Flex } from "../Layout";
type Props = DishSelectionActionProps & ContainerProps;
const Content = styled(Centered)``;
const QuantityControl = styled(Centered)`
  margin: 10px;
  /* @media only screen and (min-width: ${BREAK_POINTS.xs}) {
    margin-bottom: 20px;
  } */
`;

export type DishSelectionActionProps = {
  price: number;
  priceNonMember: number;
  quantity: number;
  quantityCap: number;
  onChangeDishQuantity: (quantity: number) => void;
  onAddDishToCart: () => void;
  onUpdateGoodInCart: () => void;
  isValid: boolean;
  isMember: boolean;
  isAuthenticated?: boolean;
  bottom?: number;
  isNotOrganizer?: boolean;
  onStartMembership?: () => void;
  goodKey?: string;
  menuLink?: string;
  source?: string;
  fbclid?: string;
};

export const DishSelectionAction = (props: Props) => {
  const {
    price,
    priceNonMember,
    isMember,
    isAuthenticated,
    quantity,
    quantityCap,
    onChangeDishQuantity,
    onAddDishToCart,
    isValid,
    fixToBottom,
    bottom,
    isNotOrganizer,
    onStartMembership,
    goodKey,
    onUpdateGoodInCart,
    menuLink,
    source,
    fbclid,
  } = props;

  const debouncedChangeDishQuantity = debounce(
    onChangeDishQuantity,
    DedupClickMiliSeconds
  );

  const isPriceValid = price != null;
  return (
    <Container
      fixToBottom={fixToBottom}
      hasSavingBanner={!isMember && !isNotOrganizer}
    >
      {!isMember && !isNotOrganizer ? (
        <ToSaveByMembership
          onStartMembership={onStartMembership}
          // bottom={130}
          // maxWidth={500}
          withQuantity={true}
          isAuthenticated={isAuthenticated}
          menuLink={menuLink}
          source={source}
          fbclid={fbclid}
        />
      ) : null}
      <Content>
        <QuantityControl>
          <QuantityOpt
            $disabled={quantity <= 1}
            onClick={() => {
              if (quantity > 1) {
                debouncedChangeDishQuantity(quantity - 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faMinus} />
          </QuantityOpt>
          <Quantity>{quantity}</Quantity>
          <QuantityOpt
            $disabled={
              quantity === (quantityCap && quantityCap >= 0 ? quantityCap : 99)
            }
            onClick={() => {
              if (
                quantity < (quantityCap && quantityCap >= 0 ? quantityCap : 99)
              ) {
                debouncedChangeDishQuantity(quantity + 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </QuantityOpt>
        </QuantityControl>

        <StyledButton
          disabled={!isValid || !isPriceValid}
          onClick={goodKey ? onUpdateGoodInCart : onAddDishToCart}
          size="medium"
        >
          <Flex justify="space-between" align="center" widthFull>
            <span className="action">{`${
              goodKey ? "Update" : "Add to"
            } Cart`}</span>
            <span className="total">
              {" "}
              ${((isMember ? price : priceNonMember) * quantity).toFixed(2)}
            </span>
          </Flex>
        </StyledButton>
      </Content>
    </Container>
  );
};

type ContainerProps = { fixToBottom: boolean; hasSavingBanner?: boolean };
const Container = styled.div<ContainerProps>`
  position: ${(props) => (props.fixToBottom ? "fixed" : "relative")};
  bottom: ${(props) => (props.fixToBottom ? "0px" : null)};
  /* padding: ${(props) => (props.hasSavingBanner ? "0px" : "10px")}; */
  padding: 0px 0px 10px 0px;
  background: white;
  box-shadow: 0 4px 8px 8px rgba(28, 29, 33, 0.2);
  width: 100%;
  max-width: 500px;
  button {
    /* padding: 0px 20px; */
    span.action {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
    }
    span.total {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 2px;
    }
  }
  @media only screen and (max-height: 700px) {
    span.action {
      font-size: 12px;
    }
    span.total {
      font-size: 13px;
    }
  }
  @media only screen and (min-width: 500px) {
    /* padding-top: 20px; */
    bottom: 10px;
    padding-bottom: 0px;
  }
`;

const QuantityOpt = styled.div<{ $disabled: boolean }>`
  font-size: 13px;
  background: white;
  color: black;
  width: 25px;
  height: 25px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 20px;
  }
  ${({ $disabled }) =>
    $disabled
      ? `
        opacity: 0.5;
      `
      : `
        cursor: pointer;
      `}
`;

const Quantity = styled.div`
  font-size: 15px;
  font-weight: 400;
  width: 40px;
  height: 40px;
  text-align: center;
  background: green;
  color: white;
  font-family: Montserrat;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
  @media only screen and (max-height: 700px) {
    height: 35px;
    width: 35px;
  }
`;

const StyledButton = styled(Button)`
  /* width: 100%; */
  height: 50px;
  flex-grow: 1;
  align-self: stretch;
  margin: 10px;
  padding: 0px 15px;
  @media only screen and (max-height: 700px) {
    height: 40px;
  }
`;
