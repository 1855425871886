import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import WebFont from "webfontloader";
// eslint-disable-next-line no-unused-vars
import _ from "./globalTypes";
import reportWebVitals from "./reportWebVitals";
import App from "./screens/App";
import posthog from "posthog-js";

WebFont.load({
  google: {
    families: [
      "Montserrat:200,300,400,500,600,700",
      "Cardo:200,300,400,500,600,700",
      "Jacques Francois:200,300,400,500,600,700",
      "sans-serif",
      "Font Awesome",
      "Satisfy:200,300,400,500,600,700",
      "Noto Sans:200,300,400,500,600,700",
      "Quando: 200,300,400,500,600,700",
      "Great Vibes:200,300,400,500,600,700",
      "Crimson Text:200,300,400,500,600,700",
      "Rock Salt:200,300,400,500,600,700",
      "Theano Didot: 200,300,400,500,600,700",
    ],
  },
});
// Initialize PostHog
posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
  api_host: "https://us.i.posthog.com",
  capture_pageview: false,
});

// Optional: Enable PostHog debug mode during development
if (process.env.NODE_ENV === "development") {
  posthog.debug();
}

const allowedPathsWithSources = [
  { path: "/home", source: "saving_image" },
  { path: "/home", source: "environment_image" },
  { path: "/home", source: "variety_image" },
  { path: "/home", source: "busy_image" },
  { path: "/home", source: "dimsum_image" },
  { path: "/meal/01-12-2025/lunch/ramen-hajime", source: "ramen_carousel" },
  { path: "/meal/01-12-2025/lunch/ramen-hajime", source: "ramen_image" },
];

const url = new URL(window.location.href); // Parse the current URL
const pathname = url.pathname; // Extract path (e.g., '/home')
const source = url.searchParams.get("source"); // Extract 'source' query parameter

// console.log("pathname", pathname);
// console.log("source", source);
// Check if the current path and source match any of the allowed combinations
const isAllowed = allowedPathsWithSources.some(
  (entry) => entry.path === pathname && entry.source === source
);

// console.log("isAllowed", isAllowed);

if (
  url.pathname === "/home" ||
  url.pathname === "/meal/01-12-2025/lunch/ramen-hajime"
) {
  posthog.capture("$pageview");
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
