import { useState, useEffect } from "react";
import {
  faTimes,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSwipeable } from "react-swipeable";
import styled from "styled-components";
import { Centered, CenteredByColumn, NavHeight } from "../Shared";

const FullScreenContainer = styled(CenteredByColumn)<{ viewHeight: number }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${({ viewHeight }) => viewHeight}px;
  padding: ${NavHeight}px 0px;
  background: black;
  z-index: 1;
  overflow: hidden;
  justify-content: flex-start;
  @media screen and (min-width: 600px) {
    background-color: rgba(0, 0, 0, 0.7);
    padding: ${NavHeight}px 0px 0px 0px;
  }
`;

const ImageWrapper = styled(CenteredByColumn)`
  width: 100vw;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const ImageList = styled.div<{
  translateX: number;
  transitionEnabled: boolean;
}>`
  display: flex;
  transform: ${({ translateX }) => `translateX(${translateX}%)`};
  transition: ${({ transitionEnabled }) =>
    transitionEnabled ? "transform 0.5s ease-in-out" : "none"};
  width: 100%;
  height: 100%;
  position: relative;
`;

const Image = styled.img`
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const NavButton = styled.button<{ isLeft: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.6);
  border: none;
  border-radius: 50%;
  color: white;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  ${(props) => (props.isLeft ? "left: 10px" : "right: 10px")};
  /* &:hover {
    background: rgba(255, 255, 255, 0.7);
    color: black;
  } */
  svg {
    font-size: 24px;
  }
  @media screen and (min-width: 600px) {
    ${(props) => (props.isLeft ? "left: 40px" : "right: 40px")};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  color: white;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;

  &:hover {
    background: rgba(255, 255, 255, 0.7);
    color: black;
  }
  svg {
    font-size: 20px;
    color: black;
  }
  @media screen and (min-width: 600px) {
    background-color: transparent;
    svg {
      font-size: 30px;
      color: white;
    }
  }
`;

const Content = styled(Centered)`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: black;
  @media screen and (min-width: 600px) {
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }
`;

const FullScreenCarousel = ({
  images,
  initialIndex = 0,
  onClose,
}: {
  images: string[];
  initialIndex?: number;
  onClose: () => void;
}) => {
  // Include duplicates for smooth circular effect
  const enhancedImages = [images[images.length - 1], ...images, images[0]];
  const [activeIndex, setActiveIndex] = useState(initialIndex + 1); // Offset by 1 for the duplicated first image
  const [viewHeight, setViewHeight] = useState(window.innerHeight);
  const [translateX, setTranslateX] = useState(-(initialIndex + 1) * 100);
  const [transitionEnabled, setTransitionEnabled] = useState(true);

  const handleNext = () => {
    setTransitionEnabled(true);
    setTranslateX(-(activeIndex + 1) * 100);
    setActiveIndex((prevIndex) => prevIndex + 1);
  };

  const handlePrev = () => {
    setTransitionEnabled(true);
    setTranslateX(-(activeIndex - 1) * 100);
    setActiveIndex((prevIndex) => prevIndex - 1);
  };

  useEffect(() => {
    const handleResize = () => {
      setViewHeight(window.innerHeight); // Update viewable height on resize
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Handle reset when swiping to the duplicated first/last image
  useEffect(() => {
    if (activeIndex === 0) {
      setTimeout(() => {
        setTransitionEnabled(false);
        setTranslateX(-images.length * 100); // Jump to the last real image
        setActiveIndex(images.length);
      }, 500); // Match the transition duration
    } else if (activeIndex === enhancedImages.length - 1) {
      setTimeout(() => {
        setTransitionEnabled(false);
        setTranslateX(-100); // Jump to the first real image
        setActiveIndex(1);
      }, 500); // Match the transition duration
    }
  }, [activeIndex, images.length, enhancedImages.length]);

  const swipeHandlers = useSwipeable({
    onSwiping: ({ deltaX }) => {
      setTransitionEnabled(false);
      const offset = (deltaX / window.innerWidth) * 100;
      setTranslateX(-activeIndex * 100 + offset);
    },
    onSwipedLeft: () => {
      setTransitionEnabled(true);
      handleNext();
    },
    onSwipedRight: () => {
      setTransitionEnabled(true);
      handlePrev();
    },
    onSwiped: () => {
      setTransitionEnabled(true);
    },
    preventScrollOnSwipe: true,
  });

  return (
    <FullScreenContainer viewHeight={viewHeight} {...swipeHandlers}>
      <CloseButton onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </CloseButton>
      <Content>
        <NavButton isLeft={true} onClick={handlePrev}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </NavButton>
        <ImageWrapper>
          <ImageList
            translateX={translateX}
            transitionEnabled={transitionEnabled}
          >
            {enhancedImages.map((image, index) => (
              <Image key={index} src={image} alt={`Slide ${index + 1}`} />
            ))}
          </ImageList>
        </ImageWrapper>
        <NavButton isLeft={false} onClick={handleNext}>
          <FontAwesomeIcon icon={faChevronRight} />
        </NavButton>
      </Content>
    </FullScreenContainer>
  );
};

export default FullScreenCarousel;
