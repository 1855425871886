import React, { useEffect, useState } from "react";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector, useStore, connect } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { MealVisited } from "src/api/meals";
import { RootState } from "src/store";
import { readRestaurantDetails2 } from "src/store/actions";
import {
  setRestaurantWantsToTry,
  setRestaurantHasTried,
  setRestaurantStarRating,
  setRestaurantReview,
} from "src/store/actions";
import styled from "styled-components";
import { RestaurantName } from "./components/lny2025";
import FullScreenCollage from "./components/restaurants/FullScreenCollage";
import RestaurantGuide, {
  EmtpyGuide,
} from "./components/restaurants/RestaurantGuide";
import { CenteredByColumn, centered } from "./components/Shared";
import { Back, Loading } from "../shared";
import { LOGGING } from "../shared";

interface RouteParams {
  restaurantId: string;
}
const Container = styled(CenteredByColumn)`
  width: 100vw;
  min-height: 100vh;
  @media screen and (min-width: 600px) {
    padding: 10px calc(50vw - 300px);
  }
`;

const BackButton = styled.button`
  ${centered}
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: rgba(0, 0, 0, 0.5);
  svg {
    font-size: 14px;
  }
  @media screen and (min-width: 600px) {
    color: black;
    background-color: rgba(255, 255, 255, 0.8);
    left: max(0px, calc(50vw - 400px));
    svg {
      font-size: 20px;
    }
  }
`;
const PageRestaurantGuide: React.FC = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const { restaurantId } = useParams<RouteParams>();
  const history = useHistory();
  const location = useLocation();
  const [fullScreenCollage, setFullScreenCollage] = useState(false);

  const name = useSelector(
    (state: RootState) =>
      state?.currentUser?.user?.firstName +
      " " +
      state?.currentUser?.user?.lastName
  );

  // State to manage loading and error
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const isAuthenticated = useSelector(
    (state: RootState) => state?.currentUser?.isAuthenticated
  );
  const allRestaurantDetails = useSelector(
    (state: RootState) => state.restaurantDetails
  );
  const restaurantDetails =
    allRestaurantDetails.find(
      (restaurant) => restaurant._id === restaurantId
    ) || {};

  useEffect(() => {
    if (!restaurantDetails?._id) {
      MealVisited(`Restaurant Guide ${restaurantId}`, name);
      setLoading(true);
      readRestaurantDetails2(restaurantId)(dispatch, store.getState)
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          setError(err?.message);
          setLoading(false); // Ensure loading is stopped on error
        });
    }
  }, [restaurantId]);

  useEffect(() => {
    if (isAuthenticated) {
      MealVisited("Restaurant Guide 2025", name);
      readRestaurantDetails2(restaurantId)(dispatch, store.getState)
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          setError(err?.message);
          setLoading(false); // Ensure loading is stopped on error
        });
    }
  }, [isAuthenticated]);

  const handleWantToTry = async (restaurantId: string, wantsToTry: boolean) => {
    const userId = store.getState().currentUser?.user?._id;
    if (!userId) {
      history.push({
        pathname: "/auth",
        state: {
          title: `To add a note on Foodie guide`,
          next: location?.pathname,
        },
      });
      return;
    }
    setRestaurantWantsToTry(restaurantId, wantsToTry)(
      dispatch,
      store.getState
    ).then((res) => {
      LOGGING &&
        console.log("setRestaurantWantsToTry result: ", {
          restaurantId,
          wantsToTry: res,
        });
    });
  };

  const handleHasTried = async (restaurantId: string, hasTried: boolean) => {
    const userId = store.getState().currentUser?.user?._id;
    if (!userId) {
      history.push({
        pathname: "/auth",
        state: {
          title: `To add a note on Foodie guide`,
          next: location?.pathname,
        },
      });
      return;
    }
    setRestaurantHasTried(restaurantId, hasTried)(
      dispatch,
      store.getState
    ).then((res) => {
      LOGGING &&
        console.log("setRestaurantHasTried result: ", {
          restaurantId,
          hasTried: res,
        });
    });
  };

  const handleStarRating = async (restaurantId: string, starRating: number) => {
    const userId = store.getState().currentUser?.user?._id;
    if (!userId) {
      history.push({
        pathname: "/auth",
        state: {
          title: `To add a note on Foodie guide`,
          next: location?.pathname,
        },
      });
      return;
    }
    setRestaurantStarRating(restaurantId, starRating)(
      dispatch,
      store.getState
    ).then((res) => {
      LOGGING &&
        console.log("setRestaurantStarRating result: ", {
          restaurantId,
          starRating: res,
        });
    });
  };

  const handleReview = async (restaurantId: string, review: string) => {
    const userId = store.getState().currentUser?.user?._id;
    if (!userId) {
      history.push({
        pathname: "/auth",
        state: {
          title: `To add a note on Foodie guide`,
          next: location?.pathname,
        },
      });
      return;
    }
    setRestaurantReview(restaurantId, review)(dispatch, store.getState).then(
      (res) => {
        LOGGING &&
          console.log("setRestaurantReview result: ", {
            restaurantId,
            review: res,
          });
      }
    );
  };

  LOGGING &&
    console.log("PageRestaurantGuide render with: ", {
      loading,
      restaurantDetails,
    });
  return !restaurantDetails?._id ? (
    <EmtpyGuide />
  ) : fullScreenCollage ? (
    <FullScreenCollage
      images={
        restaurantDetails.heroImages ||
        restaurantDetails.bestSellers.map((d: any) => d.imageURL)
      }
      onClose={() => setFullScreenCollage(false)}
    />
  ) : (
    <Container>
      {!loading && (
        <BackButton
          onClick={(e) => {
            e.preventDefault();
            console.log("go back");
            if (history.length > 1) {
              history.goBack(); // Go back to the previous page
            } else {
              history.push("/"); // Redirect to a fallback route (e.g., home)
            }
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </BackButton>
      )}
      {loading ? (
        <EmtpyGuide />
      ) : (
        <RestaurantGuide
          restaurant={restaurantDetails}
          onWantsToTry={handleWantToTry}
          onHasTried={handleHasTried}
          onStarRating={handleStarRating}
          onReview={handleReview}
          onOpenFullScreenCollage={() => setFullScreenCollage(true)}
        />
      )}
    </Container>
  );
};
function mapStateToProps(state) {
  LOGGING && console.log("PageRestaurantGuide got redux state:", state);
  return {
    currentUser: state.currentUser,
    restaurantDetails: state.restaurantDetails,
  };
}

export default connect(mapStateToProps, {
  readRestaurantDetails2,
})(PageRestaurantGuide);
