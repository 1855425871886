import React, { ReactElement, RefObject, useEffect, useRef } from "react";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { Dish } from "src/models";
import styled from "styled-components";
import { CATEGORY_CAROUSEL_SCREEN_TOP_OFFSET } from "./CategoryCarouselV2";
import { Category } from "./DishListV2";
import { DishV2 } from "./DishV2";
import { CenteredByColumnLeft, TextUPPER } from "../../Shared";

interface CategoryDishListProps {
  cart?: any; // See MealMenuScreen for comments
  category: Category;
  isMobile: boolean; // Used for styling divs differently
  restaurantIsMemberOnly?: boolean;
  onOpenDishDetails: (
    dish: Dish,
    change: number,
    e: React.MouseEvent<HTMLInputElement>
  ) => void;
  onAddDishToCart: (dish: Dish) => void;
  onRemoveDishFromCart: (dish: Dish) => void;
  onSetCategoryRef: (categoryName: string, ref: RefObject<HTMLElement>) => void; // For scrolling
  isMember: boolean;
  isAuthenticated: boolean;
  onStartMembership?: () => void;
  onShowAuth?: () => void;
  onShowMembershipDetails?: () => void;
  addingDish?: string;
  menuLink?: string;
  source?: string;
  fbclid?: string;
}
type ContentContainerProps = { width: number };
const ContentContainer = styled(CenteredByColumnLeft)<ContentContainerProps>`
  padding-left: ${(props) => (props.width > 500 ? 60 : 0)}px;
  max-width: 1400px;
`;
const DishListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 16px;
  width: 100%;
  align-items: stretch;
  align-content: stretch;
`;

const CategoryTitle = styled(TextUPPER)`
  /* font-size: 22px;
  font-weight: 700; */
  margin: 16px 0px 10px 24px;
  color: black;
  font-size: 16px;
  font-weight: 600;
  /* text-transform: capitalize; */
  scroll-margin-top: ${CATEGORY_CAROUSEL_SCREEN_TOP_OFFSET}px; // To scroll past the fixed header
`;

export const CategoryDishList: React.FC<CategoryDishListProps> = ({
  cart,
  category,
  isMobile,
  onOpenDishDetails,
  onAddDishToCart,
  onSetCategoryRef,
  onRemoveDishFromCart,
  isMember,
  isAuthenticated,
  onStartMembership,
  onShowAuth,
  restaurantIsMemberOnly,
  onShowMembershipDetails,
  addingDish,
  menuLink,
  source,
  fbclid,
}: CategoryDishListProps): ReactElement => {
  const ref = useRef(null);
  const { width } = useWindowDimensions();
  useEffect(() => {
    onSetCategoryRef(category.categoryName, ref);
  }, [category.categoryName, onSetCategoryRef]);

  return (
    <ContentContainer width={width}>
      <CategoryTitle ref={ref}>{category.categoryName}</CategoryTitle>
      <DishListContainer>
        {category.dishes
          .sort((a, b) => ((a?.ordered || 0) > (b?.ordered || 0) ? -1 : 1))
          .map((dish) => {
            return (
              <DishV2
                key={dish._id}
                cart={cart}
                dish={dish}
                isMobile={isMobile}
                onOpenDishDetails={onOpenDishDetails.bind(this, dish, 1)}
                onAddDishToCart={onAddDishToCart.bind(this, dish)}
                onRemoveDishFromCart={onRemoveDishFromCart.bind(this, dish)}
                isMember={isMember}
                isAuthenticated={isAuthenticated}
                onStartMembership={onStartMembership}
                onShowAuth={onShowAuth}
                restaurantIsMemberOnly={restaurantIsMemberOnly}
                onShowMembershipDetails={onShowMembershipDetails}
                isSaving={String(addingDish) === String(dish._id)}
                menuLink={menuLink}
                source={source}
                fbclid={fbclid}
              />
            );
          })}
      </DishListContainer>
    </ContentContainer>
  );
};
